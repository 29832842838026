import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LiabilityDocumentUpdateModel } from './liability-files.model';

@Injectable({
  providedIn: 'root'
})
export class LiabilityFilesService {

  private endpoint = environment.endpointURL + '/api/PublicLiabilityDocument';

  constructor(private http: HttpClient) { }

  public createPublicLiabilityById(data: LiabilityDocumentUpdateModel): Observable<any> {
    const url = `${this.endpoint}/CreatePublicLiabilityDocument`;
    return this.http.post<any>(url, data);
  }

  public updatePublicLiability(data: LiabilityDocumentUpdateModel): Observable<any> {
    const url = `${this.endpoint}/UpdatePublicLiabilityDocument`;
    return this.http.put<any>(url, data);
  }

  public getPublicLiabilityListById(businessInfoId: string): Observable<Document> {
    const url = `${this.endpoint}/GetDocumentByBusinessInfoId?businessInfoId=${businessInfoId}`;
    return this.http.get<any>(url);
  }

  public deletePublicLiability(documentId: string, isArchived: boolean): Observable<any> {
    const url = `${this.endpoint}/DeletePublicLiabilityDocument?id=${documentId}&isArchived=${isArchived}`;
    return this.http.delete<any>(url);
  }

  public updatePublicLiabilityStatus(id: string, status: number): Observable<any> {
    const url = `${this.endpoint}/ChangePublicLiabilityDocumentStatus?id=${id}&status=${status}`;
    return this.http.patch(url, id);
  }
}
