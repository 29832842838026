import { StateType } from "src/app/core/enums/main.enum";


export const StateTypeLabelMapping ={
    [StateType.NewSouthWales]: "New South Wales",
    [StateType.Victoria]: "Victoria",
    [StateType.Queensland]: "Queensland",
    [StateType.WesternAustralia]: "Western Australia",
    [StateType.SouthAustralia]: "South Australia",
    [StateType.Tasmania]: "Tasmania",
    [StateType.NorthernTerritory]: "Northern Territory",
    [StateType.AustralianCapitalTerritory]: "Australian Capital Territory"

  }