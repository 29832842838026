import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DocumentCategory, DocumentCategoryType, DocumentGroup } from 'src/app/core/enums/document.enum';
import { AlertService } from 'src/app/modules/shared/services/alert/alert.service';
import { environment } from 'src/environments/environment';
import { BusinessFilesService } from '../business-files.service';
import * as AWS from 'aws-sdk';
import { BusinessFilesCreateModel, BusinessFilesCustomDocumentModel, BusinessFilesModel, BusinessFilesUpdateModel } from '../business-files.model';

@Component({
  selector: 'app-business-file-upload-popup',
  templateUrl: './business-file-upload-popup.component.html',
  styleUrls: ['./business-file-upload-popup.component.scss'],
  providers: [DatePipe]
})
export class BusinessFileUploadPopupComponent implements OnInit {

  formNewdoc!: FormGroup;
  submitted = false;
  isLoading = false;
  hasExpiry = false;
  isOtherDoc = false;

  formData!: FormData;
  reader!: FileReader;
  fileData!: File;
  fileName!: string;
  currentURL!: string;
  imgURL: any;
  public imagePath!: string;
  minExpireData = new Date();

  businessDocumentUpdateData!: BusinessFilesUpdateModel;
  businessDocumentCreateData!: BusinessFilesCreateModel;

  currentDocumentName = ''
  documentGroup = DocumentGroup;
  documentType = DocumentCategoryType;
  documentCategory = DocumentCategory;

  constructor(
    private businessDocumentServiceNew: BusinessFilesService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: {isUpdate: boolean, documentData: BusinessFilesCustomDocumentModel},
    public dialogRef: MatDialogRef<BusinessFileUploadPopupComponent>
  ) { }

  ngOnInit(): void {
    this.formNewdoc = this.formBuilder.group({
      businessInfoId: [ this.data.documentData.businessInfoId , Validators.required],
      documentType: ['', Validators.required],
      group: [ this.documentGroup.Mandatory, Validators.required],
      documentCategory: ['', Validators.required],
      name: [ '', Validators.required],

      link: ['', Validators.required],
      // expireDate: ['', Validators.required],
      expireDate: [''],
    });

    this.hasExpiry = this.data.documentData.hasExpiry

    this.currentDocumentName = this.getNameByCategory(this.data.documentData.documentCategory)
    if (this.data.isUpdate) this.setDocumentUpdateFormData(this.data.documentData.document);
    else this.setDocumentCreateFormData(this.data.documentData)
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.formNewdoc.controls;
  }

  // Upload selected image
  onSubmit() {
    this.isLoading = true;
    this.submitted = true;
    // If invalid
    if (this.formNewdoc.invalid) {
      this.isLoading = false;
      return;
    }

    if (this.data.documentData.document?.link === this.formNewdoc.value.link) { 
      this.saveForm(); // Updating & not updating the file
    } else {
      this.uploadDocToS3(); // updating the file
    }
  }

  setDocumentCreateFormData(document: BusinessFilesCustomDocumentModel) {
    this.formNewdoc.controls['name'].setValue(this.getNameByCategory(document.documentCategory));
    this.formNewdoc.controls['group'].setValue(document.documentGroup);
    this.formNewdoc.controls['documentCategory'].setValue(document.documentCategory);
  }

  setDocumentUpdateFormData(document: BusinessFilesModel) {
    this.formNewdoc.addControl('id', new FormControl(document.id) );
    this.formNewdoc.controls['name'].setValue(document?.name);

    const expDate = document?.expireDate ? new Date(document?.expireDate) : null
    this.formNewdoc.controls['expireDate'].setValue(expDate);

    this.formNewdoc.controls['group'].setValue(document.group);
    this.formNewdoc.controls['documentCategory'].setValue(document.documentCategory);
    this.formNewdoc.controls['link'].setValue(this.data.documentData?.document.link);
    const currrentDocType  = this.getDocumentType(this.data.documentData.document.link)
    this.formNewdoc.controls['documentType'].setValue(currrentDocType);
  }
  
  uploadDocToS3() {
    this.formData = new FormData();
    this.formData.append('file', this.fileData);

    const fileName = this.getFileNameByCategory(
      this.formNewdoc.value.name
    );
    const contentType = this.fileData.type;
    const fileExtension = this.fileData.name.split('.').pop();

    const bucket = new AWS.S3({
      accessKeyId: environment.config.keyId,
      secretAccessKey: environment.config.key,
      region: environment.config.region,
    });

    const folderName = this.currentDocumentName.replace(/ /g,"_")
    const fileLocation = `Trade/${this.data.documentData.businessInfoId}/Documents/${folderName}/`+ fileName +'.'+ fileExtension;
    
    const params = {
      Bucket: environment.config.location,
      Key: fileLocation,
      Body: this.fileData,
      ACL: 'private',
      ContentType: contentType,
    };

    bucket.upload(params, (error: any, response: any) => {
      if (error) {
        this.alertService.error('Operation failed!');
        return false;
      }

      this.formNewdoc.patchValue({
        link: response.Key,
      });

      this.saveForm();
      return true;
    });
  }

  saveForm() {
    if (this.data.isUpdate) {
      this.updateBusinessDocument(this.formNewdoc.getRawValue());
    } else {
      this.createBusinessDocument(this.formNewdoc.getRawValue());
    }
  }

  // Browse file/document and preview.
  fileProgress(event: any) {
    this.reader = new FileReader();

    this.fileName = '';
    this.fileData = event.target.files[0];
    this.fileName = event.target.files[0].name;

    this.formNewdoc.patchValue({
      link: this.fileName,
    });

    // set file extention / doc type
    const currrentDocType  = this.getDocumentType(this.fileData.name)
    this.formNewdoc.controls['documentType'].setValue(currrentDocType);

    const mimeType = event.target.files[0].type;
    const maxFileSize = 1024 * 1024;

    // Check for images only
    // if (mimeType.match(/image\/*/) == null) {
    //   this.alertService.error('File type not supported!');
    //   return;
    // }

    // check for max size
    // if (this.fileData.size > maxFileSize) {
    //   this.alertService.error('Max file size is 1MB');
    //   return;
    // }

    // For iamge preview
    this.imagePath = event.target.files;
    this.reader.readAsDataURL(event.target.files[0]);
    this.reader.onload = (_event) => {
      this.imgURL = this.reader.result;
    };
  }

  // Create business document
  private createBusinessDocument(data: BusinessFilesCreateModel) {
    this.businessDocumentCreateData = data;
    this.businessDocumentCreateData.expireDate = this.convertDate(data.expireDate)
    this.isLoading = true;
    this.businessDocumentServiceNew
      .createBusinessDocumentById(this.businessDocumentCreateData)
      .subscribe({
        next: (response: any) => {
          if (response.success) {
            this.alertService.success('Document created successfully');
            this.dialogRef.close(response.data);
          } else {
            this.alertService.error(response.error.message);
          }
          this.isLoading = false;
        },
        error: (error: any) => {
          this.alertService.error('Operation failed!');
        },
      });
  }

  // Edit business document
  private updateBusinessDocument(data: BusinessFilesUpdateModel) {
    this.businessDocumentUpdateData = data;
    this.businessDocumentUpdateData.expireDate = this.convertDate(data.expireDate)
    this.isLoading = true;
    this.businessDocumentServiceNew
      .updateBusinessDocument(this.businessDocumentUpdateData).subscribe({
        next: (response: any) => {
          if (response.success) {
            this.alertService.success('Document updated successfully');
            this.dialogRef.close(response.data);
          } else {
            this.alertService.error(response.error.message);
          }
          this.isLoading = false;
        },
        error: (error: any) => {
          this.alertService.error('Operation failed!');
        },
      });
  }

  // Generate File Name
  getFileNameByCategory(name: string) {
    const timePrefix = this.datePipe.transform(new Date(), 'yyyyMMddhhmmss');
    return name ? name.split(' ').join('_').toLocaleLowerCase() + '_' + timePrefix : '';
  }

  // Convert Date
  convertDate(date: string) {
    return date ? this.datePipe.transform(date, 'yyyy-MM-dd') : null
  }

  // Get documentType form name
  getDocumentType(link: string) {
    const currentLink = link.toLowerCase()
    const extention = currentLink.split('.').pop()
    let currentDocumentType
    switch (extention) {
      case 'pdf':
        currentDocumentType = this.documentType.PDF
        break;
      case 'doc':
        currentDocumentType = this.documentType.DOC
        break;
      case 'docx':
        currentDocumentType = this.documentType.DOC
        break;
      case 'png':
        currentDocumentType = this.documentType.PNG
        break;
      case 'jpeg':
        currentDocumentType = this.documentType.JPEG
        break;
      case 'jpg':
        currentDocumentType = this.documentType.JPEG
        break;
    }
    return currentDocumentType
  }

  getNameByCategory(documentCategory: number) {
    let name = ''
    switch (documentCategory) {
      case this.documentCategory.WorkCoverCertificate:
        name = 'Workcover Certificate Of Currency'
        break;
        case this.documentCategory.Insurance:
          name = 'Professional Indemnity Certificate Of Currency'
          break;
      case this.documentCategory.PublicLiabilityCertificate:
        name = 'Public Liability Certificate Of Currency'
        break;
      case this.documentCategory.WhiteCard:
        name = 'Construction Industry White Card'
        break;
      case this.documentCategory.CovidVaccinationCertificate:
        name = 'Covid Vaccination Certificate'
        break;
    }
    return name
  }

  clearDate() {
    this.formNewdoc.controls['expireDate'].setValue(null);
  }
}
