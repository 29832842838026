import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { UserType } from '../enums/main.enum';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  
  userType = UserType;

  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    const currentUser = this.authService.currentUserValue;
    const userRole = currentUser.userType;

    if (currentUser && userRole === this.userType.Tradie) {
      return true;
    } else { // Redirect to login page if not logged in
      this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url }});
      return false;
    }
  }

}
