export enum Custom {
}

export enum IndemnityTypes {
  ProfessionalIdemnityInsurance = 1,
  ProfessionalIdemnityInsuranceCurrency = 2,
  RenewProfessionalIdemnityInsurance = 3
}

export enum HealthSaftyTypes {
  manageHealthSafety = 1,
}

export enum UploadWidgetStyleType {
  browseType = 1,
  inputSelectionType = 2,
  generalType = 3,
}

