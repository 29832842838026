<mat-card class="rate-wr p-3">
  <div class="d-flex align-items-center mb-2">
    <p class="title mb-0 text-bold">{{businessService.serviceCategoryId | getCategoryName : serviceCategoryList }}</p>
    <button *ngIf="!isDisplayOnly" mat-icon-button [matMenuTriggerFor]="menu" class="d-flex justify-content-center align-items-center ms-auto">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
        <button *ngIf="!deleteOnly" mat-menu-item (click)="editCategoryRates()"><span>Edit</span></button>
        <mat-divider *ngIf="!deleteOnly"></mat-divider>
        <button mat-menu-item (click)="removeCategoryConfirmation()"><span>Remove</span></button>
    </mat-menu>
  </div>

  <div *ngIf="specialtyList.length>0" class="d-flex align-items-center mb-1 rate flex-wrap">
    <div *ngFor="let specialty of specialtyList; index as i" class="specialty-lbl">
        <span class="px-1">{{ specialty.specialtyName}}</span>
        <span *ngIf="specialtyList.length-1 > i" class=" px-1">|</span>
    </div>
  </div>

  <!-- If Not Quotation Only -->
  <div *ngIf="!businessService.quotationOnly" class="d-flex">
    <div class="rate-block call-out-block p-2 flex-fill mr-2">
        <span class="sub-title text-uppercase">Call out Rate</span>
        <div class="d-flex">
          <div class="rate border-right pr-3 mr-3">
              <span class="d-block amount text-bold">{{ businessService.businessHourCallOutFee ? "$"+((businessService.businessHourCallOutFee/100) | number: '1.2-2') : '--'}}</span>
              <span class="d-block amount-lbl">Business hours</span>
          </div>
          <div class="rate">
            <span class="d-block amount text-bold">{{ businessService.afterHourCallOutFee ? "$"+((businessService.afterHourCallOutFee/100) | number: '1.2-2') : '--'}}</span>
              <span class="d-block amount-lbl">After hours</span>
          </div>
        </div>
    </div>

    <div class="rate-block hourly-block p-2 flex-fill">
        <span class="sub-title text-uppercase">Hourly Rate</span>
        <div class="d-flex">
          <div class="rate border-right pr-3 mr-3">
            <span class="d-block amount text-bold">{{ businessService.businessHourCallOutFee ? "$"+((businessService.businessHourHourlyRate/100) | number: '1.2-2') : '--'}}</span>
              <span class="d-block amount-lbl">Business hours</span>
          </div>
          <div class="rate">
            <span class="d-block amount text-bold">{{ businessService.businessHourCallOutFee ? "$"+((businessService.afterHourHourlyRate/100) | number: '1.2-2') : '--'}}</span>
              <span class="d-block amount-lbl">After hours</span>
          </div>
        </div>
    </div>
  </div>

  <!-- If Not Quotation Only -->
  <div *ngIf="businessService.quotationOnly" class="d-flex">
    <div class="rate-block quote-only w-100 p-3">
      <h3 class="quote-only-title mb-1 text-uppercase">Quote Requests Only</h3>
      <p class="m-0 quote-only-txt">No Emergency/Scheduled jobs</p>
    </div>
  </div>

  <div *ngIf="isLoading" class="isLoading d-flex justify-content-center align-items-center">
    <mat-spinner strokeWidth="2" diameter="30"></mat-spinner>
  </div>
</mat-card>


