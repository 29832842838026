import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddressType } from 'src/app/core/enums/main.enum';
import { BusinessInfo } from '../../../models/tradie/tradie.model';
import { AlertService } from '../../../services/alert/alert.service';
import { TradieService } from '../../../services/tradie/tradie.service';

@Component({
  selector: 'app-shared-update-business-address-popup',
  templateUrl: './shared-update-business-address-popup.component.html',
  styleUrls: ['./shared-update-business-address-popup.component.scss']
})
export class SharedUpdateBusinessAddressPopupComponent implements OnInit {

  formUpdateAddress!: FormGroup;
  submitted = false;
  isLoading = false;
  addressType = AddressType;

  states = [
    { name: 'New South Wales'},
    { name: 'Victoria' },
    { name: 'Queensland' },
    { name: 'Western Australia' },
    { name: 'South Australia' },
    { name: 'Tasmania' },
    { name: 'Northern Territory' },
    { name: 'Australian Capital Territory' },
  ]
  
  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<SharedUpdateBusinessAddressPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public businessInfo: BusinessInfo,
    private tradieService: TradieService,
    private alertService: AlertService,
  ) { }

  ngOnInit(): void {
    this.formUpdateAddress = this.formBuilder.group({
      userId: this.businessInfo.id,
      number: ['-'],
      streetName: [this.businessInfo.streetName, Validators.required],
      city: [this.businessInfo.city, Validators.required],
      state: [this.businessInfo.state, Validators.required],
      zipCode: [this.businessInfo.zipCode, Validators.required],
      // zipCode: ['-'],
      geoLocation: {latitude: '0', longitude: '0'},
    });

    if (this.businessInfo.id) {
      this.formUpdateAddress.addControl('id', new FormControl(this.businessInfo.id));
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.formUpdateAddress.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.formUpdateAddress.invalid) {
      return;
    }
    
    if (this.businessInfo.id) {
      this.updateBusinessAddress();
    }
  }
  
  // Update user address
  updateBusinessAddress() {
    this.isLoading = true;
    this.tradieService.updateBusinessAddress(this.formUpdateAddress.value).subscribe({
      next: (response: any) => {
        if (response.success) {
          this.alertService.success('Address successfully updated');
          const address = response.data;
          this.dialogRef.close(address);
        } else {
          this.alertService.error('Operation failed');
        }
      }, error : err => {
        this.isLoading = false;
        this.alertService.error('Operation failed');
      }
    });
  }
}
