
<form [formGroup]="formServiceDocument" (ngSubmit)="onSubmit(formServiceDocument)">
  <div class="cost-wr d-flex align-items-start border mb-2 text-sm">

    <div class="w-40 pr-2">
        <input (click)="fileInput.click()" class="cost-input pointer px-2" [class.disabled]="enableDelete" placeholder="[ BROWSE ]" formControlName="link" readonly>
        <mat-error *ngIf="submitted && f['link'].errors?.['required']">
          Please upload document.
        </mat-error>
        <input
          hidden
          id="file"
          type="file"
          accept="image/png, image/jpeg, .pdf, .doc, .docx"
          name="image"
          #fileInput
          (change)="fileProgress($event)"
        />
    </div>

    <div class="w-30 pr-2">
        <input autocomplete="off" class="cost-input" type="text" formControlName="name"  (keydown)="preventDefault($event)">
        <mat-error *ngIf="submitted && f['name'].errors?.['required']">
          Please enter document name.
        </mat-error>
    </div>

    <div class="w-30 pr-2 pointer" (click)="dateInput.click()">
        <input #dateInput class="cost-input ex-date" min="{{currentDate | date:'yyyy-MM-dd'}}" type="date" formControlName="expireDate">
        <mat-error *ngIf="submitted && f['expireDate'].errors?.['required']">
          Please select document expire date.
        </mat-error>
    </div>
    
    <div class="pr-2">
      <div class="action-btns text-right d-flex align-items-center justify-content-end">
        <button 
          *ngIf="!enableDelete && !isEmptyValues && !isLoading" 
          class="btn decline shadow-none text-sm text-capitalize" 
          mat-raised-button type="button" 
          (click)="resetForm()">Discard
        </button>

        <button 
          *ngIf="!enableDelete && !isLoading && isValid" 
          class="btn primary shadow-none ms-1 text-capitalize" 
          mat-raised-button 
          [disabled]="!isValid">Save
        </button>

        <button 
          *ngIf="enableDelete && !isLoading" 
          class="btn-done-offer remove"  
          mat-raised-button type="button" 
          (click)="deleteDocument(curuntDocumentId)">
          <mat-icon>delete</mat-icon>
        </button>
        <mat-spinner class="spinner ms-1" diameter="25" *ngIf="isLoading"></mat-spinner>
      </div>
    </div>
  </div>
</form>
