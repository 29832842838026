import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ServiceArea } from '../../models/location/location.model';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  private endpoint = environment.endpointURL + '/api/Location';

  constructor( private http: HttpClient ) { }

  public createServiceArea(data: ServiceArea): Observable<any> {
    const url = `${this.endpoint}/ServiceArea`;
    return this.http.post<any>(url, data);
  }

  public updateServiceArea(data: ServiceArea): Observable<any> {
    const url = `${this.endpoint}/ServiceArea`;
    return this.http.put<any>(url, data);
  }

  public getServiceAreaList(businessInfoId: string, isArchived: boolean): Observable<any> {
    const url = `${this.endpoint}/ServiceArea?businessInfoId=${businessInfoId}&isArchived=${isArchived}`;
    return this.http.get<any>(url);
  }

  public deleteServiceArea(saID: string, isArchived: boolean): Observable<any> {
    const url = `${this.endpoint}/ServiceArea?id=${saID}&isArchived=${isArchived}`;
    return this.http.delete<any>(url);
  }
}