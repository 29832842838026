<section  class="p-1 d-flex align-items-center">
    <img class="picture object-fit me-2 rounded-circle" [src]="user?.profileImageUrl ? user?.profileImageUrl : '/assets/icons/trade-response-img.png'">

    <div *ngIf="isManager" class="info d-flex flex-column justify-content-center">
        <span class="label lh-1 mb-1 font-bold text-uppercase">
            <span [ngSwitch]="user?.userType">
                <span *ngSwitchCase="userType?.StrataManager">Requested By</span>
                <span *ngSwitchCase="userType?.RAndM">Handled By</span>
                <span *ngSwitchDefault></span>
            </span>
        </span>
        <span class="label lh-1 mb-1 main">{{user?.firstName}} {{user?.lastName}}</span>
        <span class="label lh-1 sub">
            <span [ngSwitch]="user?.userType">
                <span *ngSwitchCase="userType?.StrataManager">Strata Manager</span>
                <span *ngSwitchCase="userType?.RAndM">R&M Member</span>
                <span *ngSwitchDefault></span>
            </span>, 
            {{address?.strataSiteName}}
        </span>
    </div>

    <div *ngIf="!isManager" class="info d-flex flex-column justify-content-center">
        <span *ngIf="!hideTitle" class="label lh-1 mb-1 font-bold text-uppercase">Trades Details</span>
        <!-- <a class="label lh-1 mb-1 main custom-link underlined" [routerLink]="['/rm-member/trades/profile']" [queryParams]="{id: user?.id}">{{user?.businessName}}</a> -->
        <span class="label lh-1 sub">{{user?.firstName}} {{user?.lastName}}</span>
    </div> 
</section>