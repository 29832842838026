<div class="popup-wr p-3">
  <button class="close-icon" mat-icon-button><mat-icon matDialogClose>cancel</mat-icon></button>
  <p class="popup-header mb-0">Required Document List</p>
  <p class="intro text-secondary">These are the documents which required to approve this service</p>

  <div *ngIf="!hasData && !isLoading" class="p-5">
      <p class="text-center text-bold m-0">Required documents not available</p>
  </div>

  <div *ngIf="hasData" class="documents-list">
      <div *ngFor="let doc of requiredDocs; index as i;">
          <span class="doc-name text-bold me-2">{{i+1}}.</span>
          <span>{{doc.name}}</span>
      </div>
  </div>

  <div *ngIf="isLoading" class="inner-preloader d-flex align-items-center justify-content-center">
      <mat-spinner diameter="35"></mat-spinner>
  </div>
</div>