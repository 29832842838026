import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DocumentCategory, DocumentGroup, DocumentStatus } from 'src/app/core/enums/document.enum';
import { AlertService } from 'src/app/modules/shared/services/alert/alert.service';
import { UploadService } from 'src/app/modules/shared/services/upload/upload.service';
import { SharedConfirmationPopupComponent } from '../../../popups/shared-confirmation-popup/shared-confirmation-popup.component';
import { UserFilesCustomDocumentModel, UserFilesDataModel, UserFilesModel } from '../../user-files/user-files.model';
import { UserFilesService } from '../../user-files/user-files.service';
import { HealthFileUploadPopupComponent } from '../health-file-upload-popup/health-file-upload-popup.component';

@Component({
  selector: 'app-health-file-list-widget',
  templateUrl: './health-file-list-widget.component.html',
  styleUrls: ['./health-file-list-widget.component.scss']
})
export class HealthFileListWidgetComponent implements OnInit {

  @Input() tradeId!: string
  @Input() isLoading: boolean = false;
  @Input() hideStatus: boolean = false;
  @Input() hideExpireDate: boolean = false;
  @Input() customDocuments!: UserFilesModel[];
  @Input() currentDocumentCategory!: number;

  @Output() documentChangeEvent = new EventEmitter<UserFilesModel>();
  @Output() otherDocumentChangeEvent = new EventEmitter<UserFilesDataModel>();
  
  customDocumentData = new UserFilesCustomDocumentModel()

  documentGroup = DocumentGroup;
  documentCategory = DocumentCategory;
  documentStatus = DocumentStatus;
  

  constructor(
    public dialog: MatDialog,
    private userFilesService: UserFilesService,
    private alertService: AlertService,
    private uploadService: UploadService,
  ) { }

  ngOnInit(): void {
  }

  createUpdateDocumentPopup() {
    this.setCreateDocumentData(null);
    const dialogRef = this.dialog.open(HealthFileUploadPopupComponent, {
      maxWidth: '500px',
      width: '90%',
      position: {top: '60px'},
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      closeOnNavigation: true,
      disableClose: true,
      data: {isUpdate: false, documentData: this.customDocumentData, hideExpireDate: this.hideExpireDate}, //data: this.customDocumentData,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        // if(this.customDocumentData.isOtherDoc) {
        //   this.customDocumentData = result
        //   this.otherDocumentChangeEvent.emit(this.customDocumentData)
        // } else {
        //   this.customDocumentData.document = result
        //   this.documentChangeEvent.emit(this.customDocumentData.document)
        // }
        this.customDocuments.unshift(result)
        this.documentChangeEvent.emit(result)
      }
    });
  }

  updateDocumentPopup(customDocument: UserFilesModel) {
    this.setCreateDocumentData(customDocument) 
    const dialogRef = this.dialog.open( HealthFileUploadPopupComponent, {
      maxWidth: '640px',
      width: '50%',
      position : {top: '60px'},
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      closeOnNavigation: true,
      disableClose: true,
      data: {isUpdate: true, documentData: this.customDocumentData, hideExpireDate: this.hideExpireDate},
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        // if(this.customDocumentData.isOtherDoc) {
        //   this.customDocumentData = result
        //   this.otherDocumentChangeEvent.emit(this.customDocumentData)
        // } else {
        //   this.customDocumentData.document = result
        //   this.documentChangeEvent.emit(this.customDocumentData.document)
        // }
        this.updateListItem(result)
        this.documentChangeEvent.emit(result)
      }
    });
  }

  deleteDocumentConfirmation(customDocument:UserFilesModel) {
    this.setCreateDocumentData(customDocument)
    const dialogRef = this.dialog.open(SharedConfirmationPopupComponent, {
      maxWidth: '500px',
      width: '90%',
      position: {
        top: '60px',
      },
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      closeOnNavigation: true,
      disableClose: true,
      data: {
        title: 'Please Confirm',
        message: 'Are you sure you want to delete this document?',
        confirmTxt: 'Yes, Delete',
        cancelTxt: 'Cancel',
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      const isArchived = true
      if (result) {
        this.deleteUserDocument(customDocument.id)
      }
    });
  }
  
  deleteUserDocument(documentId: string) {
    this.isLoading = true
    this.userFilesService.deleteDocument(documentId).subscribe({
      next: (res) => {
        if (res.success) {
          this.alertService.success('Document deleted successfully')
          this.customDocumentData.document = null!
          this.removeFromList(documentId)
          this.documentChangeEvent.emit()
        } else {
          this.alertService.error(res.error.message)
        }
        this.isLoading = false
      }, error: (err) => {
        this.isLoading = false
        this.alertService.error('Operation failed')
      },
    });
  }

  // Download Document
  downloadDocument(key: string, name: string) {
    const documentUrl = this.uploadService.getUrlByS3KEY(key);
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', documentUrl);
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  removeFromList(id: string) {
    const index = this.customDocuments.findIndex(x => x.id === id);
    if (index > -1) {
      this.customDocuments.splice(index, 1);
    }
  }

  updateListItem(data: UserFilesModel) {
    const index = this.customDocuments.findIndex(x => x.id === data.id);
    this.customDocuments[index] = data
  }

  setCreateDocumentData(customDocument:any) {
    this.customDocumentData = new UserFilesCustomDocumentModel()
    if (customDocument) {
      this.customDocumentData.document = customDocument
      this.customDocumentData.documentName = customDocument.name
    }
    this.customDocumentData.documentCategory = this.currentDocumentCategory
    this.customDocumentData.documentGroup = this.documentGroup.Mandatory
    this.customDocumentData.hasExpiry = true
    this.customDocumentData.isBusinessDocument = false
    this.customDocumentData.isBusinessServiceDocument = false
    this.customDocumentData.currentUserId = this.tradeId
  }

}
