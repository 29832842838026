import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SharedAlertPopupComponent } from '../shared-alert-popup/shared-alert-popup.component';

@Component({
  selector: 'app-shared-update-question-popup',
  templateUrl: './shared-update-question-popup.component.html',
  styleUrls: ['./shared-update-question-popup.component.scss']
})
export class SharedUpdateQuestionPopupComponent implements OnInit {

  isUpdated = true
  isTrue!: boolean

  constructor(
    public dialogRef: MatDialogRef<SharedUpdateQuestionPopupComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: {question: any, isTrue: boolean, title: string, documentData : any},
  ) { }

  ngOnInit(): void {
    this.isTrue = this.data.isTrue
  }

  answerOnChange(event: any) {

    let checkFalse = this.data.documentData.length > 0 ? true : false ;

    if(checkFalse == true && this.isTrue == true){
      this.checkValidator();
      this.dialogRef.close(this.isTrue)
    }else{
      this.isTrue = (event.value == '1')
      this.isUpdated = (this.data.isTrue !== this.isTrue) 
    }
  }

  checkValidator(){
    const dialogRef = this.dialog.open( SharedAlertPopupComponent, {
      maxWidth: '500px',
      width: '90%',
      position: {
        top: '60px',
      },
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      closeOnNavigation: true,
      disableClose: true,
      data: {
        title: 'Something went wrong',
        message: 'Please make sure all the documents uploaded in the professional indemnity section has been removed before proceed to "NO"',
        confirmTxt: 'Ok',
      },
    });
  }
  save() {
    this.dialogRef.close(this.isTrue)
  }

  close() {
    this.dialogRef.close(null)
  }
}
