import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BusinessServiceModel, BusinessServiceUpdateModel } from '../../../models/tradie/tradie.model';
import { AlertService } from '../../../services/alert/alert.service';
import { TradieService } from '../../../services/tradie/tradie.service';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-shared-update-service-category-specialities-popup',
  templateUrl: './shared-update-service-category-specialities-popup.component.html',
  styleUrls: ['./shared-update-service-category-specialities-popup.component.scss']
})
export class SharedUpdateServiceCategorySpecialitiesPopupComponent implements OnInit {

  isLoading = false;

  // Specialty
  selectable = true;
  removable = true;
  addOnBlur = false;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  specialtyList: string[] = [];
  @ViewChild('chipInput', {static: false}) chipInput!: ElementRef;

  businessServiceUpdateData = new BusinessServiceUpdateModel();

  constructor(
    private alertService: AlertService,
    private tradieService: TradieService,
    public dialogRef: MatDialogRef<SharedUpdateServiceCategorySpecialitiesPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public businessData: BusinessServiceModel
  ) { }

  ngOnInit(): void {
    this.specialtyList = this.businessData.businessServiceSpecialty.map(e => e.specialtyName)
    this.setRequestUpdateData(this.businessData)
  }

  onSubmit() {
    this.updateBusinessService(this.businessServiceUpdateData)
  }

  removeSpecialty(specialty: any): void {
    const index = this.specialtyList.indexOf(specialty);
    if (index >= 0) {
      this.specialtyList.splice(index, 1);
    }
  }

  addSpecialtyEvent(event: MatChipInputEvent): void {
    const value = event.value.replace(/\s/g, ' ');
    this.addToSpecialtyList(value)
  }

  addSpecialty(value:string) {
    this.addToSpecialtyList(value)
  }

  addToSpecialtyList(specialtyAddr: string) {
    const specialty = specialtyAddr.replace(/\s/g, ' ');
    if ((specialty || '').trim()) {
        this.specialtyList.push(specialty);
        this.chipInput.nativeElement.value = '';
    }
  }

  createArray(array:any) {
    let specialtyArray = []
    for (let i = 0 ; i < array.length ; i++){
      specialtyArray.push(array[i].specialtyName)
    }
    return specialtyArray
  }

  setRequestUpdateData(data: BusinessServiceModel) : BusinessServiceUpdateModel { // 1$ = 100 cents
    this.businessServiceUpdateData.id = this.businessData.id
    this.businessServiceUpdateData.businessHourCallOutFee = (data.businessHourCallOutFee * 100)
    this.businessServiceUpdateData.businessHourHourlyRate = (data.businessHourHourlyRate * 100)
    this.businessServiceUpdateData.afterHourCallOutFee = (data.afterHourCallOutFee * 100)
    this.businessServiceUpdateData.afterHourHourlyRate = (data.afterHourHourlyRate * 100)
    this.businessServiceUpdateData.quotationOnly = data.quotationOnly
    this.businessServiceUpdateData.speciality = this.specialtyList
    return this.businessServiceUpdateData
  }

  private updateBusinessService(data: BusinessServiceUpdateModel) {
    this.isLoading = true;
    this.tradieService.updateBusinessService(data).subscribe({
      next: (response: any) => {
        if (response.success) {
          this.alertService.success('Service category details successfully updated');
          // this.updatedRates = response.data
          this.specialtyList = response.data.businessServiceSpecialty.map((e: any) => e.specialtyName)
          this.dialogRef.close(response.data.businessServiceSpecialty)
        } else {
          this.alertService.error(response.error.message);
        }
        this.isLoading = false;
      },
      error: (error: any) => {
        this.isLoading = false;
        this.alertService.error('Operation failed');
      }
    });
  }
  
  close() {
    this.dialogRef.close()
  }
}
