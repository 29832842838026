import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/core/auth/auth.service';
import { DocumentCategory, DocumentGroup, DocumentStatus } from 'src/app/core/enums/document.enum';
import { AlertService } from 'src/app/modules/shared/services/alert/alert.service';
import { UploadService } from 'src/app/modules/shared/services/upload/upload.service';
import { SharedConfirmationPopupComponent } from '../../../popups/shared-confirmation-popup/shared-confirmation-popup.component';
import { BusinessFileUploadPopupComponent } from '../business-file-upload-popup/business-file-upload-popup.component';
import { BusinessFilesCustomDocumentModel, BusinessFilesModel } from '../business-files.model';
import { BusinessFilesService } from '../business-files.service';

@Component({
  selector: 'app-business-file-list-widget',
  templateUrl: './business-file-list-widget.component.html',
  styleUrls: ['./business-file-list-widget.component.scss']
})
export class BusinessFileListWidgetComponent implements OnInit {

  @Input() businessInfoId!: string
  @Input() hideStatus: boolean = false
  @Input() isOptional: boolean = false
  @Input() hasExpiry: boolean = true
  @Input() businessDocuments!: BusinessFilesModel[]
  @Input() currentDocumentCategory!: number
  @Output() removeDocumentEvent = new EventEmitter<any>();
  @Output() updateDocumentEvent = new EventEmitter<any>();
  @Output() updateDocumentStatusEvent = new EventEmitter<any>();
  
  businessDocument!: BusinessFilesModel
  businessDocumentData = new BusinessFilesCustomDocumentModel()
  documentCategory = DocumentCategory
  documentGroup = DocumentGroup
  documentStatus = DocumentStatus
  isLoading = false

  constructor(
    private alertService: AlertService,
    private uploadService: UploadService,
    public dialog: MatDialog,
    private businessDocumentServiceNew: BusinessFilesService
  ) { }

  ngOnInit(): void {
  }

  uploadBusinessDocument() {
    this.setCreateDocumentData(null) 
    const dialogRef = this.dialog.open( BusinessFileUploadPopupComponent, {
      maxWidth: '640px',
      width: '50%',
      position : {top: '60px'},
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      closeOnNavigation: true,
      disableClose: true,
      data: {isUpdate: false, documentData: this.businessDocumentData},
    });

    dialogRef.afterClosed().subscribe( (data) => {
      if (data) {
        // this.businessDocument = data
        this.businessDocuments.unshift(data)
        this.updateDocumentEvent.emit(data)
      }
    });
  }

  updateBusinessDocument(businessDocument: BusinessFilesModel) {
    this.setCreateDocumentData(businessDocument)
    const dialogRef = this.dialog.open( BusinessFileUploadPopupComponent, {
      maxWidth: '640px',
      width: '50%',
      position : {top: '60px'},
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      closeOnNavigation: true,
      disableClose: true,
      data: {isUpdate: true, documentData: this.businessDocumentData},
    });

    dialogRef.afterClosed().subscribe( (data) => {
      if (data) {
        // this.businessDocument = data
        this.updateListItem(data)
        this.updateDocumentEvent.emit(data)
      }
    });
  }

  removeDocumentConfirm(businessDocument: BusinessFilesModel) {
    this.setCreateDocumentData(businessDocument)
    const dialogRef = this.dialog.open( SharedConfirmationPopupComponent, {
      maxWidth: '500px',
      width: '90%',
      position: {
        top: '60px',
      },
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      closeOnNavigation: true,
      disableClose: true,
      data: {
        title: 'Please Confirm',
        message: 'Are you sure you want to delete this document?',
        confirmTxt: 'Yes, Delete',
        cancelTxt: 'Cancel',
      },
    });

    dialogRef.afterClosed().subscribe( success => {
      if (success) {
        const isArchived = true
        this.deleteBusinessDocument(businessDocument, isArchived)
      }
    });
  }

  private deleteBusinessDocument(businessDocument: BusinessFilesModel, isArchived: boolean) {
    this.isLoading = true;
    this.businessDocumentServiceNew.deleteBusinessDocument(businessDocument.id, isArchived).subscribe({
      next: (result: any) => {
        if (result.success) {
          this.alertService.success('Document deleted successfully!');
          this.removeDocumentEvent.emit(businessDocument.id)
          // this.businessDocument = null!
          this.removeFromList(businessDocument.id)
        } else {
          this.alertService.error(result.error.message);
        }
        this.isLoading = false;
      }, error: err => {
        this.alertService.error('Operation failed!');
        this.isLoading = false;
      }
    });
  }

  // Download Document
  downloadDocument(key: string, name: string) {
    const documentUrl = this.uploadService.getUrlByS3KEY(key);
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', documentUrl);
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    link.remove(); 
  }

  setCreateDocumentData(businessDocument: any) {
    this.businessDocumentData = new BusinessFilesCustomDocumentModel()
    if (businessDocument) {
      this.businessDocumentData.document = businessDocument
      this.businessDocumentData.documentName = businessDocument.name // this.businessDocumentData.documentName = null!
    }
    this.businessDocumentData.documentCategory = this.currentDocumentCategory
    this.businessDocumentData.documentGroup = this.isOptional ? this.documentGroup.Additional : this.documentGroup.Mandatory
    this.businessDocumentData.hasExpiry = this.hasExpiry
    this.businessDocumentData.isBusinessDocument = true
    this.businessDocumentData.businessInfoId = this.businessInfoId
    this.businessDocumentData.isBusinessServiceDocument = false
  }

  updateListItem(data: BusinessFilesModel) {
    const index = this.businessDocuments.findIndex(x => x.id === data.id);
    this.businessDocuments[index] = data
  }

  removeFromList(id: string) {
    const index = this.businessDocuments.findIndex(x => x.id === id);
    if (index > -1) {
      this.businessDocuments.splice(index, 1);
    }
  }
}
