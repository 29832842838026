import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-shared-preferences-text-input-widget',
  templateUrl: './shared-preferences-text-input-widget.component.html',
  styleUrls: ['./shared-preferences-text-input-widget.component.scss']
})
export class SharedPreferencesTextInputWidgetComponent implements OnInit{

  @Input() questionItem: any;
  @Output() updateAnswerEvent = new EventEmitter<any>();

  answer = new FormControl('');
  hasValue = false
  valueChanged = false
  originalValue!: string
  currentValue!: string

  constructor() { }

  ngOnInit(): void {
    this.originalValue = this.questionItem.answers[0]?.value
    this.answer.setValue(this.originalValue)
    this.hasValue = (this.originalValue?.length > 0)
  }

  onKeyup() {
    this.currentValue = this.answer.value
    this.hasValue = (this.currentValue?.length > 0)
    this.valueChanged = (this.currentValue != this.originalValue)
  }

  save() {
    let answer = {
      questionId: this.questionItem?.question?.id,
      id: this.questionItem?.answers[0]?.id,
      value: this.currentValue
    }
    this.updateAnswerEvent.emit(answer)
    this.valueChanged = false
  }
}
