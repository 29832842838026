import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { JobMediaType } from 'src/app/core/enums/main.enum';
import { MediaModel } from '../../../models/jobs/jobs.model';
import { SharedMediaPreviewPopupComponent } from '../../popups/shared-media-preview-popup/shared-media-preview-popup.component';

@Component({
  selector: 'app-shared-media-preview-widget',
  templateUrl: './shared-media-preview-widget.component.html',
  styleUrls: ['./shared-media-preview-widget.component.scss']
})
export class SharedMediaPreviewWidgetComponent implements OnInit {

  @Input() mediaList!: MediaModel[]
  jobMediaType = JobMediaType;

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  public viewFile( URL: string ,type: number) {
    if ( (type === this.jobMediaType.PDF) || (type === this.jobMediaType.Word) ) { 
      this.downloadDocument(URL, 'Document')
    } else {
      this.viewMediaPreview(URL)
    }
  }

  private viewMediaPreview(mediaURL: string) {
    const dialogRef = this.dialog.open(SharedMediaPreviewPopupComponent, {
      width: '70%',
      height: '80%',
      data: mediaURL,
      closeOnNavigation: true,
    });
  }

  // Download Document
  private  downloadDocument(URL: string, name: string) {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', URL);
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

}
