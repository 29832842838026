export class HealthAndSafetyFiles {
}

export interface HealthAndSafetyFilesCreateModel {
    userId: string;
    businessInfoId: string;
    manageHealthSafety: boolean;
    name: string;
    link: string;
    expiryDate: any;
    documentType: number;
    healthSafetyDocumentType: number;
}

export interface HealthAndSafetyFilesUpdateModel {
    id: string;
    userId: string;
    businessInfoId: string;
    manageHealthSafety: boolean;
    name: string;
    link: string;
    expiryDate: any;
    healthSafetyDocumentType: number;
    documentType: number;
}

export interface HealthAndSafetyFilesModel { 
    id: string;
    expiryDate: string;
    lastUpdatedDate: string;
    name: string;
    link: string;
    documentType: number;
    healthSafetyDocumentType: number;
    isArchived: boolean;
    status: number;
    comment: string;
}

export class HealthAndSafetyFilesCustomDocumentModel {
    documentName!: string;
    documentCategory!: number;
    documentGroup!: number;
    hasExpiry!: boolean;
    currentUserId!: string;
  
    document!: HealthAndSafetyFilesModel;
    businessInfoId!: any;
    businessServiceId!: any;
    fileLocation!: any;
  
    isOtherDoc!: boolean;
    otherDocExpiry!: string;
    otherDocURL!: string;
}
