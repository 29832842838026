<div class="d-flex justify-content-between align-items-center m-0 ps-4 pe-2 py-3">
  <p *ngIf="!isUpdate" class="popup-header mb-0">Add Service Area</p>
  <p *ngIf="isUpdate" class="popup-header mb-0">Update Service Area</p>
  <button mat-icon-button mat-dialog-close><mat-icon>cancel</mat-icon></button>
</div>
<mat-divider></mat-divider>

<div class="popup-wr p-4">
  <form class="row" [formGroup]="formLocation" (ngSubmit)="onSubmit()">
    <div class="col-7">
      <section class="maps-wr">
        <agm-map 
          [latitude]="currentLatitude" 
          [longitude]="currentLongitude" 
          [zoom]="zoom"
          [disableDefaultUI]="false"
          (mapClick)="mapClicked($event)">
          
          <agm-marker 
            *ngIf="isCurrentLocationSet && isAddressSet"
            [latitude]="currentLatitude" 
            [longitude]="currentLongitude" 
            [markerDraggable]="true"
            [iconUrl]="iconURL"
            (dragEnd)="markerDragEnd($event)">
          </agm-marker>

          <agm-circle 
            [latitude]="currentLatitude" 
            [longitude]="currentLongitude" 
            [radius]="markerRadious"
            [fillColor]="'#39CCF2'"
            [circleDraggable]="false"
            [editable]="false">
          </agm-circle>
        </agm-map>
      </section>
    </div>
    <div class="col-5">
      <p class="font-semi-bold">You will only receive work for each state you are operating in. Your licenses and registrations will be checked against what you have selected</p>

      <div class="info w-100 mb-4">
        <mat-form-field class="state-select">
          <mat-select formControlName="state"  required placeholder="Select State">
            <mat-option *ngFor="let state of states" [value]="state.value">
              {{state.name}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="f['state'].errors?.['required']">
            Please enter state.
          </mat-error>
        </mat-form-field>

        <mat-form-field class="w-100" appearance="legacy">
          <mat-label>Location</mat-label>
          <input 
            formControlName="geoLocationAddress"
            type="text" 
            matInput 
            placeholder="Please enter location" 
            (keydown.enter)="$event.preventDefault()" 
            autocorrect="off" 
            autocapitalize="off" 
            spellcheck="off" 
            required
            #search>
          <mat-icon class="pointer" *ngIf="search.value" (click)="resetMap(); search.value = '' " matSuffix>close</mat-icon>
          <mat-error *ngIf="f['geoLocationAddress'].errors?.['required']">
              Please enter location.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="info mb-3 w-100">
        <mat-form-field class="w-100" appearance="legacy">
          <mat-label>Radius (km)</mat-label>
          <input 
            formControlName="radious"
            type="number" 
            matInput 
            placeholder="Please enter radius" 
            autocomplete="off"
            required>
            <mat-error *ngIf="f['radious'].errors?.['required']">
                Please enter radius.
            </mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="currentLatitude && currentLongitude && markerRadious && isAddressSet">
        <div class="action-btns d-flex align-items-center justify-content-end w-100 mt-3 mr-4">
          <button class="btn primary w-mid" mat-flat-button [disabled]="isLoading">Save</button>
          <mat-spinner class="ms-2" diameter="25" *ngIf="isLoading"></mat-spinner>
        </div>
      </div>
    </div>
  </form>

  <div 
    *ngIf="isLoading" 
    class="map-loader w-100 h-100">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
</div>
