import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DocumentGroup, DocumentStatus } from 'src/app/core/enums/document.enum';
import { AlertService } from 'src/app/modules/shared/services/alert/alert.service';
import { UploadService } from 'src/app/modules/shared/services/upload/upload.service';
import { SwmsFilesService } from '../swms-files.service';
import { SwmsFileUploadPopupComponent } from '../swms-file-upload-popup/swms-file-upload-popup.component';
import { SharedConfirmationPopupComponent } from '../../../popups/shared-confirmation-popup/shared-confirmation-popup.component';
import { SWMSFilesCustomDocumentModel, SWMSFilesModel } from '../swms-files.model';

@Component({
  selector: 'app-swms-file-list-widget',
  templateUrl: './swms-file-list-widget.component.html',
  styleUrls: ['./swms-file-list-widget.component.scss']
})
export class SwmsFileListWidgetComponent implements OnInit, OnChanges {

  @Input() businessInfoId!: string
  @Input() hideStatus: boolean = false
  @Input() isOptional: boolean = false
  @Input() hasExpiry: boolean = true
  @Input() sWMSQuestionItem: any
  @Input() sWMSDocuments: SWMSFilesModel[] = []
  @Input() tradeId!: string
  @Input() questionId!: string
  @Output() removeDocumentEvent = new EventEmitter<any>();
  @Output() updateDocumentEvent = new EventEmitter<any>();
  @Output() updateDocumentStatusEvent = new EventEmitter<any>();

  sWMSDocument!: SWMSFilesModel
  sWMSDocumentData = new SWMSFilesCustomDocumentModel()
  documentGroup = DocumentGroup
  documentStatus = DocumentStatus

  isLoading = false
  hasData = false

  constructor(
    private alertService: AlertService,
    private uploadService: UploadService,
    public dialog: MatDialog,
    private sWMSDocumentServiceNew: SwmsFilesService
  ) { }

  ngOnChanges(): void {
    if (this.sWMSDocuments.length === 0) {
      this.sWMSDocuments = []
    }
  }

  ngOnInit(): void {
    this.checkHasData()
  }

  uploadBusinessDocument() {
    this.setCreateDocumentData(null) 
    const dialogRef = this.dialog.open( SwmsFileUploadPopupComponent, {
      maxWidth: '640px',
      width: '50%',
      position : {top: '60px'},
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      closeOnNavigation: true,
      disableClose: true,
      data: {isUpdate: false, documentData: this.sWMSDocumentData, userId: this.tradeId, questionId: this.questionId },
    });

    dialogRef.afterClosed().subscribe( (data) => {
      if (data) {
        this.sWMSDocuments.unshift(data)
        this.updateDocumentEvent.emit(data)
      }
    });
  }

  updateBusinessDocument(sWMSDocument: SWMSFilesModel) {
    this.setCreateDocumentData(sWMSDocument)
    const dialogRef = this.dialog.open( SwmsFileUploadPopupComponent, {
      maxWidth: '640px',
      width: '50%',
      position : {top: '60px'},
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      closeOnNavigation: true,
      disableClose: true,
      data: {isUpdate: true, documentData: this.sWMSDocumentData, userId: this.tradeId, questionId: this.questionId},
    });

    dialogRef.afterClosed().subscribe( (data) => {
      if (data) {
        this.updateListItem(data)
        this.updateDocumentEvent.emit(data)
      }
    });
  }

  removeDocumentConfirm(sWMSDocument: SWMSFilesModel) {
    this.setCreateDocumentData(sWMSDocument)
    const dialogRef = this.dialog.open( SharedConfirmationPopupComponent, {
      maxWidth: '500px',
      width: '90%',
      position: {
        top: '60px',
      },
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      closeOnNavigation: true,
      disableClose: true,
      data: {
        title: 'Please Confirm',
        message: 'Are you sure you want to delete this document?',
        confirmTxt: 'Yes, Delete',
        cancelTxt: 'Cancel',
      },
    });

    dialogRef.afterClosed().subscribe( success => {
      if (success) {
        const isArchived = true
        this.deleteBusinessDocument(sWMSDocument, isArchived)
      }
    });
  }

  private deleteBusinessDocument(sWMSDocument: SWMSFilesModel, isArchived: boolean) {
    this.isLoading = true;
    this.sWMSDocumentServiceNew.deleteSWMSDocument(sWMSDocument.id).subscribe({
      next: (result: any) => {
        if (result.success) {
          this.alertService.success('Document deleted successfully!');
          this.removeDocumentEvent.emit(sWMSDocument.id)
          this.removeFromList(sWMSDocument.id)
        } else {
          this.alertService.error(result.error.message);
        }
        this.isLoading = false;
      }, error: err => {
        this.alertService.error('Operation failed!');
        this.isLoading = false;
      }
    });
  }

  // Download Document
  downloadDocument(key: string, name: string) {
    const documentUrl = this.uploadService.getUrlByS3KEY(key);
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', documentUrl);
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    link.remove(); 
  }

  setCreateDocumentData(sWMSDocument: any) {
    this.sWMSDocumentData = new SWMSFilesCustomDocumentModel()
    if (sWMSDocument) {
      this.sWMSDocumentData.document = sWMSDocument
      this.sWMSDocumentData.documentName = sWMSDocument.documentName
    }
    this.sWMSDocumentData.documentGroup = this.isOptional ? this.documentGroup.Additional : this.documentGroup.Mandatory
    this.sWMSDocumentData.hasExpiry = this.hasExpiry
    this.sWMSDocumentData.businessInfoId = this.businessInfoId
    this.sWMSDocumentData.currentUserId = this.tradeId
  }

  updateListItem(data: SWMSFilesModel) {
    const index = this.sWMSDocuments.findIndex(x => x.id === data.id);
    this.sWMSDocuments[index] = data
  }

  removeFromList(id: string) {
    const index = this.sWMSDocuments.findIndex(x => x.id === id);
    if (index > -1) {
      this.sWMSDocuments.splice(index, 1);
      this.checkHasData()
    }
  }

  checkHasData() {
    if(this.sWMSDocuments != undefined || this.sWMSDocuments != null) {
      if(this.sWMSDocuments?.length>0) {
        this.hasData = true
      } else {
        this.hasData = false
      }
    } else {
      this.sWMSDocuments = []
      this.hasData = false
    }
  }

}
