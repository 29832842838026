import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    private _snackBar: MatSnackBar 
) { }

// convenience methods
success(message: string) {
    this.openSnackBar(message, '', 'success-snackbar');
}

error(message: string) {
    this.openSnackBar(message, '', 'error-snackbar');
}

openSnackBar(message: string, action: string, type: string) {
    this._snackBar.open(message, action, {
    duration: 3000,
    horizontalPosition: 'right', 
    verticalPosition: 'top',
    panelClass: [type] 
    });
}
}

