import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getPreferrenceAnswer'
})
export class GetPreferrenceAnswerPipe implements PipeTransform {

  transform(answerId: string, answersList: any[] ): string {
    const preferrence = answersList?.find(answer => answer.id === answerId);
    return preferrence ? preferrence.answer : '--';
  }

}
