export class SwmsFiles {
}

export interface SWMSFilesCreateModel {
    businessInfoId: string;
    questionId: string;
    isChecked: boolean;
    link: string;
    documentStatus: number;
    expireDate: any;
    documentName: string;
}

export interface SWMSFilesUpdateModel {
    id: string;
    swmsDetailsId: string;
    userId: string;
    businessInfoId: string;
    questionId: string;
    isChecked: boolean;
    link: string;
    documentStatus: number;
    documentName: string;
    expireDate: any;
}

export interface SWMSFilesModel {
    id: string;
    swmsHeaderId: string;
    link: string;
    documentStatus: number;
    isArchived: boolean;
    expireDate: any;
    documentName: string;
    lastUpdatedDate: string;
    status: number;
    comment: string;
}

export interface SWMSFilesGetDataModel {
    id: string;
    userId: string;
    businessInfoId: string;
    questionId: string;
    isArchived: boolean;
    lastUpdatedDate: string;
    isChecked: boolean;
    swmsDetailModel: SWMSFilesModel[]
}

export class SWMSFilesCustomDocumentModel {
    documentName!: string;
    documentCategory!: number;
    documentGroup!: number;
    hasExpiry!: boolean;
    currentUserId!: string;
  
    document!: SWMSFilesModel;
    businessInfoId!: any;
    businessServiceId!: any;
    fileLocation!: any;
  
    isOtherDoc!: boolean;
    otherDocExpiry!: string;
    otherDocURL!: string;
}
