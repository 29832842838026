<div class="d-flex justify-content-between align-items-center m-0">
  <span class="example-spacer py-3 ps-4">{{imageDetails.title}}</span>
  <button class="close-icon me-3" mat-icon-button matDialogClose tabindex="-1">
      <mat-icon>cancel</mat-icon>
  </button>
</div>

<mat-divider></mat-divider>

<div class="popup-wr p-1 d-flex justify-content-between">
  <form class="mt-0  px-4" (ngSubmit)="onSubmit()">
      <div class="d-flex flex-column img-wr align-items-center justify-content-center px-4 mt-3" [ngStyle]="{'background-image': 'url(' + imgURL + ')'}">
          <p class="mt-2">image size 512px * 512px</p>
          <div class="brows-wr text-center">
            <input hidden id="file" type="file" accept='image/*' name="image" #fileInput (change)="fileProgress($event)"/>
          </div>
      </div>

      <div *ngIf="imageDetails.description" class="mt-2">
        <small class="m-0"> {{imageDetails.description}} </small>
      </div>

      <div mat-dialog-actions class="d-flex align-items-center justify-content-end py-3 mb-1 action-btn">
        <a mat-button class="btn primary-link browse-btn me-2" (click)="fileInput.click()">Browse</a>
        <button class="btn primary shadow-none" mat-raised-button [disabled]="!hasFile">Save</button>
        <mat-spinner class="ms-2" diameter="25" *ngIf="isLoading"></mat-spinner>
      </div>
  </form>
</div>
