export class Location {}

export class ServiceArea {
  id!: string;
  userId!: string;
  name!: string;
  businessInfoId!: string;
  raduis!: number;
  state!:number;
  geoLocation!: GeoLocation;

  public setGeoLocation(latitude: number, longitude: number) {
    this.geoLocation = {
      latitude: latitude,
      longitude: longitude,
    };
  }
}
export interface GeoLocation {
  latitude: number;
  longitude: number;
}
export interface ServiceAreaList {
  id: string;
  name: string;
  raduis: number;
  state: string;
  userId: string;
  businessInfoId: string;
  lastUpdatedTime: string;
  location: GeoLocation;
  isArchived: boolean;
}
