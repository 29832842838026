import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UpdateProfileImage, UpdateUserAddress } from '../models/user.model';
// import { UpdateProfileImage, UpdateUserAddress } from 'src/app/modules/shared/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private endpoint = environment.endpointURL + '/api/User';

  constructor( private http: HttpClient ) { }

    public updateUserActiveStatus(userId: string, isActive: boolean): Observable<any> {
      const url = `${this.endpoint}/SetActiveStatus?userId=${userId}&isActive=${isActive}`;
      return this.http.patch<any>(url, userId);
    }

    public getUserById(userId: string): Observable<any> {
      const url = `${this.endpoint}?id=${userId}`;
      return this.http.get<any>(url);
    }

    public userUpdatePassword(data: any): Observable<any> {
      const url = `${this.endpoint}/UpdatePassword`;
      return this.http.post<any>(url, data);
    }

    public updateUserFullName(data: any): Observable<any> {
      const url = `${this.endpoint}/PersonalInfo`;
      return this.http.put<any>(url, data);
    }

    public updateUserAddress(data: UpdateUserAddress): Observable<any> {
      const url = `${this.endpoint}/UpdateAddress`;
      return this.http.put<any>(url, data);
    }

    public addUserAddress(data: any): Observable<any> {
      const url = `${this.endpoint}/AddAddress`;
      return this.http.post<any>(url, data);
    }

    public updateMobileNumberByAdmin(userId: string, mobileNumber: string): Observable<any> {
      const url = `${this.endpoint}/UpdateMobileNumberByAdmin?userId=${userId}&mobileNumber=${mobileNumber}`;
      return this.http.put<any>(url, userId);
    }

    public updateEmailByAdmin(userId: string, email: string): Observable<any> {
      const url = `${this.endpoint}/UpdateEmailByAdmin?userId=${userId}&email=${email}`;
      return this.http.put<any>(url, userId);
    }

    public updateProfileImage(data: UpdateProfileImage): Observable<any> {
      const url = `${this.endpoint}/UpdateProfileUrl`;
      return this.http.patch<any>(url, data);
    }
}