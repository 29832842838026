import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DocumentGroup, DocumentStatus } from 'src/app/core/enums/document.enum';
import { AlertService } from 'src/app/modules/shared/services/alert/alert.service';
import { UploadService } from 'src/app/modules/shared/services/upload/upload.service';
import { SharedConfirmationPopupComponent } from '../../../popups/shared-confirmation-popup/shared-confirmation-popup.component';
import { IndemnityFileUploadPopupComponent } from '../indemnity-file-upload-popup/indemnity-file-upload-popup.component';
import { IndemnityCreateUploadModel, IndemnityDocumentDetailModel, IndemnityFilesCustomDocumentModel } from '../indemnity-files.model';
import { IndemnityFilesService } from '../indemnity-files.service';

@Component({
  selector: 'app-indemnity-file-list-widget',
  templateUrl: './indemnity-file-list-widget.component.html',
  styleUrls: ['./indemnity-file-list-widget.component.scss']
})
export class IndemnityFileListWidgetComponent implements OnInit {

  indemnityCreateUploadModel!: IndemnityCreateUploadModel;
  indemnityDocumentDetailModel !: IndemnityDocumentDetailModel;
  indemnityFilesCustomDocumentModel!: IndemnityFilesCustomDocumentModel;

  @Input() businessInfoId!: string
  @Input() hideStatus: boolean = false
  @Input() isOptional: boolean = false
  @Input() hasExpiry: boolean = true
  @Input() isEditable: boolean = true 
  @Input() currentDocumentCategory!: number
  @Input() businessDocuments !: IndemnityDocumentDetailModel[];
  @Input() tradeId!: string
  @Output() removeDocumentEvent = new EventEmitter<any>();
  @Output() updateDocumentEvent = new EventEmitter<any>();
  @Output() updateDocumentStatusEvent = new EventEmitter<any>();

  documentGroup = DocumentGroup
  documentStatus = DocumentStatus
  isLoading = false
  constructor(
    private alertService: AlertService,
    private uploadService: UploadService,
    public dialog: MatDialog,
    public indemnityService: IndemnityFilesService
  ) { }

  ngOnInit(): void {
  }

  uploadBusinessDocument() {
    const dialogRef = this.dialog.open( IndemnityFileUploadPopupComponent, {
      maxWidth: '640px',
      width: '50%',
      position : {top: '60px'},
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      closeOnNavigation: true,
      disableClose: true,
      data: {isUpdate: false, documentData: this.indemnityCreateUploadModel , userId : this.tradeId , businessInfoId: this.businessInfoId },
    });

    dialogRef.afterClosed().subscribe( (data) => {
      if (data) {
        this.businessDocuments.unshift(data)
        this.updateDocumentEvent.emit(data)
      }
    });
  }

 
  updateBusinessDocument(indemnityDocument: any) {
    const dialogRef = this.dialog.open( IndemnityFileUploadPopupComponent, {
      maxWidth: '640px',
      width: '50%',
      position : {top: '60px'},
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      closeOnNavigation: true,
      disableClose: true,
      data: {isUpdate: true, documentData: indemnityDocument, userId: this.tradeId, businessInfoId: this.businessInfoId },
    });

    dialogRef.afterClosed().subscribe( (data) => {
      if (data) {
        this.updateListItem(data)
        this.updateDocumentEvent.emit(data)
      }
    });
  }

  
  updateListItem(data: IndemnityDocumentDetailModel) {
    const index = this.businessDocuments.findIndex(x => x.id === data.id);
    this.businessDocuments[index] = data
  }

  removeDocumentConfirm(indemnityDocument: IndemnityDocumentDetailModel) {
    const dialogRef = this.dialog.open( SharedConfirmationPopupComponent, {
      maxWidth: '500px',
      width: '90%',
      position: {
        top: '60px',
      },
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      closeOnNavigation: true,
      disableClose: true,
      data: {
        title: 'Please Confirm',
        message: 'Are you sure you want to delete this document?',
        confirmTxt: 'Yes, Delete',
        cancelTxt: 'Cancel',
      },
    });

    dialogRef.afterClosed().subscribe( success => {
      if (success) {
        const isArchived = true
        this.deleteBusinessDocument(indemnityDocument, isArchived)
      }
    });
  }

  private deleteBusinessDocument(indemnityDocument: IndemnityDocumentDetailModel, isArchived: boolean) {
    this.indemnityService.deleteIndemnityDocument(indemnityDocument.id).subscribe({
      next: (result: any) => {
        if (result.success) {
          this.alertService.success('Document deleted successfully!');
          this.removeDocumentEvent.emit(indemnityDocument.id)
          this.removeFromList(indemnityDocument.id)
        } else {
          this.alertService.error(result.error.message);
        }
        this.isLoading = false;
      }, error: err => {
        this.alertService.error('Operation failed!');
        this.isLoading = false;
      }
    });
  }

  // Download Document
  downloadDocument(key: string, name: string) {
    const documentUrl = this.uploadService.getUrlByS3KEY(key);
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', documentUrl);
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }


  removeFromList(id: string) {
    // const index = this.indemnityDocumentDetailModel.findIndex(x => x.id === id);
    // if (index > -1) {
    //   this.indemnityDocumentDetailModel.splice(index, 1);
    // }
  }


}
