export class LiabilityFiles {}

export class LiabilityDocumentUpdateModel {
  id?: string;
  name!: string;
  link!: string;
  userId!: string;
  businessInfoId!: string;
  expireDate!: string;
  documentCategory!: number;
  documentType!: number;
  group!: number;
  publicLiabilityAmount!: number;
  productsLiabilityAmount!: number;
}

export interface LiabilityDocumentListModel {
  id: string;
  name: string;
  link: string;
  documentType: number;
  documentCategory: number;
  group: number;
  publicLiabilityAmount: number;
  productsLiabilityAmount: number;
  expireDate: string;
  status: number;
  comment: string;
  isArchived: boolean;
  lastUpdatedDate: string;
  lastUpdatedUser: string;
}