import {
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { environment } from 'src/environments/environment';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SignaturePad } from 'angular2-signaturepad';
import { JobMediaType, MediaPostTime } from 'src/app/core/enums/main.enum';
import { FileUpload } from 'src/app/modules/shared/models/file-upload.model';
import { DatePipe } from '@angular/common';
import * as AWS from 'aws-sdk';
import { AlertService } from 'src/app/modules/shared/services/alert/alert.service';
import { DocumentCategoryType } from 'src/app/core/enums/document.enum';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UserFilesService } from '../../file-upload/user-files/user-files.service';
import { UserFilesCustomDocumentModel, UserFilesCreateModel, UserFilesEditModel } from '../../file-upload/user-files/user-files.model';

@Component({
  selector: 'app-shared-digital-signature-popup',
  templateUrl: './shared-digital-signature-popup.component.html',
  styleUrls: ['./shared-digital-signature-popup.component.scss'],
  providers: [DatePipe]
})
export class SharedDigitalSignaturePopupComponent implements OnInit {

  @ViewChild(SignaturePad) signaturePad!: SignaturePad;
  signatureImg!: string;
  isPrivate = false;
  hasSignature = false;
  isLoading = false;

  userDocumentCreateData = new UserFilesCreateModel();
  userDocumentUpdateData = new UserFilesEditModel();

  signatureName = 'trade_digital_signature'
  filelocation = 'User/Documents/'

  signaturePadOptions: Object = {
    minWidth: 2,
    canvasWidth: 550,
    canvasHeight: 300,
  };

  mediaObject = new FileUpload();
  mediaType = JobMediaType;
  mediaPostTime = MediaPostTime;
  fileType = DocumentCategoryType;

  formData!: FormData;
  reader!: FileReader;
  fileData!: File;
  fileName!: string;
  files: File[] = [];
  selectedFile!: FileUpload;
  imagePath!: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public signatureData: UserFilesCustomDocumentModel,
    public dialogRef: MatDialogRef<SharedDigitalSignaturePopupComponent>,
    private datePipe: DatePipe,
    private userFilesService: UserFilesService,
    private alertService: AlertService,
    private authService: AuthService,
  ) {}

  ngAfterViewInit() {
    // this.signaturePad is now available
    this.signaturePad.set('minWidth', 2);
    this.signaturePad.clear();
  }

  ngOnInit(): void {
    const curentUserId = this.authService.currentUserValue.userId
    this.filelocation = `User/${curentUserId}/Documents/`
    this.signatureData.documentName = this.signatureData.documentName;

    this.userDocumentCreateData.userId = curentUserId;
    this.userDocumentUpdateData.userId = curentUserId;
    this.userDocumentCreateData.documentCategory = this.signatureData.documentCategory;
    this.userDocumentCreateData.expireDate = null;
    this.userDocumentCreateData.group = this.signatureData.documentGroup;
    this.userDocumentCreateData.name = this.signatureData.documentName;
  }

  drawComplete() {
    this.hasSignature = true;
  }

  drawStart() {}

  clearSignature() {
    this.signaturePad.clear();
    this.hasSignature = false;
  }

  savePad() {
    const base64Data = this.signaturePad.toDataURL();
    this.signatureImg = base64Data;
    const fileType = 'png';
    this.base64toFile(
      this.signatureImg,
      this.signatureData.documentName,
      fileType
    ).then((file) => {
      this.uploadMediaObject(file);
    });
  }

  async base64toFile(url: string, filename: string, mimeType: any) {
    mimeType = mimeType || (url.match(/^data:([^;]+);/) || '')[1];
    const res = await fetch(url);
    const buf = await res.arrayBuffer();
    return new File([buf], filename, { type: mimeType });
  }

  // Upload selected media
  private uploadMediaObject(file: File) {
    this.isLoading = true;
    this.formData = new FormData();
    this.fileName = '';
    this.formData.append('file', file);

    const contentType = file.type;
    const fileName = this.getFileNameByCategory(
      this.signatureName
    );
    const fileExtension = 'png';

    const bucket = new AWS.S3({
      accessKeyId: this.convertKey(environment.config.keyId),
      secretAccessKey: this.convertKey(environment.config.key),
      region: environment.config.region,
    });

    const ACL = this.isPrivate ? 'private' : 'public-read';

    const params = {
      Bucket: environment.config.location,
      Key: this.filelocation + fileName + '.' + fileExtension,
      Body: file,
      ACL: ACL,
      ContentType: contentType,
    };

    bucket.upload(params, (error: any, response: any) => {
      if (error) {
        this.isLoading = false;
        return false;
      }
      const filelink = this.isPrivate ? response.Key : response.Location;

      this.userDocumentCreateData.link = filelink;
      this.userDocumentCreateData.documentType = this.fileType.PNG;

      if (this.signatureData.document) {
        this.userDocumentUpdateData.id = this.signatureData.document.documentsHeaderId
        this.userDocumentUpdateData.detailsId = this.signatureData.document.id
        this.userDocumentUpdateData.documentsHeaderId = this.signatureData.document.documentsHeaderId
        this.userDocumentUpdateData.link = this.userDocumentCreateData.link
        this.userDocumentUpdateData.documentCategory = this.userDocumentCreateData.documentCategory
        this.userDocumentUpdateData.documentType = this.userDocumentCreateData.documentType
        this.userDocumentUpdateData.expireDate = this.userDocumentCreateData.expireDate
        this.userDocumentUpdateData.name = this.userDocumentCreateData.name
        this.userDocumentUpdateData.group = this.userDocumentCreateData.group

        this.updateUserDocument(this.userDocumentUpdateData);
      } else {
        this.createUserDocument(this.userDocumentCreateData);
      }
      

      this.files = [];
      return true;
    });
  }

  // Create user document
  private createUserDocument(data: UserFilesCreateModel) {
    this.userFilesService.createDocumentById(data).subscribe({
      next: (response: any) => {
        if (response.success) {
          this.alertService.success('Digital signature added successfully');
          this.dialogRef.close(response.data);
        } else {
          this.alertService.error(response.error.message);
        }
        this.isLoading = false;
      },
      error: (error: any) => {
        this.isLoading = false;
        this.alertService.error('Operation failed!');
      },
    });
  }

  // Update user document
  private updateUserDocument(data: UserFilesEditModel) {
    this.userFilesService.updateDocument(data).subscribe({
      next: (response: any) => {
        if (response.success) {
          this.alertService.success('Digital signature updated successfully');
          this.dialogRef.close(response.data);
        } else {
          this.alertService.error(response.error.message);
        }
        this.isLoading = false;
      },
      error: (error: any) => {
        this.isLoading = false;
        this.alertService.error('Operation failed!');
      },
    });
  }

  getFileNameByCategory(name: string) {
    const timePrefix = this.datePipe.transform(new Date(), 'yyyyMMddhhmmss');
    return name.split(' ').join('_').toLocaleLowerCase() + '_' + timePrefix;
  }

  private convertKey(key: string) {
    const newkey = atob(key); 
    return key;
  }
}
