<div *ngIf="mediaList?.length" class="media-wr d-flex flex-wrap">
    <div *ngFor="let item of mediaList" >
        <mat-card class="media-item mat-elevation-z0 d-flex justify-content-center align-items-center p-0 me-3 mb-3">
            <div class="inner-wr center-img-wr rounded">
            <div *ngIf="item.mediaType == jobMediaType.Image" class="item-image" [ngStyle]="{'background-image': 'url(' + item.link + ')'}"></div>
            <video *ngIf="item.mediaType == jobMediaType.Video" [src]="item.link"></video>
            </div>
            <div *ngIf="item.link" [ngClass]="{'has-image': item.link}" class="img-overlay d-flex justify-content-center align-items-center cursor-pointer" (click)="viewFile(item.link, item.mediaType)">
            <svg *ngIf="item.mediaType == jobMediaType.Video" class="selected media-large-icon" xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29">
                <g id="Group_3030" data-name="Group 3030" transform="translate(-690 -790)">
                <circle id="Ellipse_160" data-name="Ellipse 160" cx="14.5" cy="14.5" r="14.5" transform="translate(690 790)" fill="#212121" opacity="0.52"/>
                <path id="Polygon_15" data-name="Polygon 15" d="M6.634,1.5a1,1,0,0,1,1.732,0l5.769,10a1,1,0,0,1-.866,1.5H1.731a1,1,0,0,1-.866-1.5Z" transform="translate(713 797) rotate(90)" fill="#fff"/>
                </g>
            </svg>
            <svg *ngIf="item.mediaType == jobMediaType.Image" class="selected media-large-icon" xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29">
                <g id="preview-img" transform="translate(-547 -690)">
                <g id="Ellipse_172" data-name="Ellipse 172" transform="translate(547 690)" fill="#212121" stroke="#fff" stroke-width="1" opacity="0.52">
                    <circle cx="14.5" cy="14.5" r="14.5" stroke="none"/>
                    <circle cx="14.5" cy="14.5" r="14" fill="none"/>
                </g>
                <g id="Group_3279" data-name="Group 3279">
                    <path id="Path_2228" data-name="Path 2228" d="M93.363,241.942a.787.787,0,0,0-1.11,0l-1.81,1.81-3.376-3.376a.787.787,0,0,0-1.11,0l-5.509,5.509A.787.787,0,0,0,81,247.222H96.739a.787.787,0,0,0,.559-1.346Z" transform="translate(472.622 463.564)" fill="#fff"/>
                    <ellipse id="Ellipse_173" data-name="Ellipse 173" cx="2.316" cy="2.316" rx="2.316" ry="2.316" transform="translate(561.518 698.213)" fill="#fff"/>
                </g>
                </g>
            </svg>
            <img *ngIf="item.mediaType == jobMediaType.PDF" class="selected media-large-icon"  width="29" height="29" src="/assets/icons/wizard/pdf-ico.svg">
            <img *ngIf="item.mediaType == jobMediaType.Word" class="selected media-large-icon"  width="29" height="29" src="/assets/icons/wizard/word-file.svg">
            </div>
        </mat-card>
         <small class="mt-1">{{ (item.link.split('/').pop()!.length > 18) ?  '...'+item.link.split('/').pop()?.substring(18) : item.link.split('/').pop() }}</small>
    </div>
</div>

<div *ngIf="mediaList?.length === 0" class="mb-3 text-center">
    <p class="m-0 text-secondary font-bold">photos or videos not available</p>
</div>