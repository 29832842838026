import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Document, BusinessDocumentCreateModel, BusinessDocumentUpdateModel } from 'src/app/modules/shared/models/document.model';

@Injectable({
  providedIn: 'root'
})
export class BusinessDocumentService {

  private endpoint = environment.endpointURL + '/api/BusinessDocument';

  constructor( private http: HttpClient ) { }

  public createBusinessDocumentById(data: BusinessDocumentCreateModel): Observable<any> {
    const url = `${this.endpoint}`;
    return this.http.post<any>(url, data);
  }

  public deleteBusinessDocument(documentId: string, isArchived: boolean): Observable<any> {
    const url = `${this.endpoint}?id=${documentId}&isArchived=${isArchived}`;
    return this.http.delete<any>(url);
  }

  public updateBusinessDocument(data: BusinessDocumentUpdateModel): Observable<any> {
    const url = `${this.endpoint}`;
    return this.http.put<any>(url, data);
  }

  public getBusinessDocumentListById(businessInfoId: string): Observable<Document> {
    const url = `${this.endpoint}/GetByBusinessInfoId?businessInfoId=${businessInfoId}`;
    return this.http.get<any>(url);
  }

  public updateBusinessDocumentStatus(id: string, status: number): Observable<any> {
    const url = `${this.endpoint}/Status?id=${id}&status=${status}`;
    return this.http.patch(url, id);
  }
}
