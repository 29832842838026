import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/auth/auth.service';
import { TradeProfile } from 'src/app/modules/onboarding/shared/models/profile.model';
import { TradieService } from '../../services/tradie/tradie.service';

@Component({
  selector: 'app-shared-welcome-page',
  templateUrl: './shared-welcome-page.component.html',
  styleUrls: ['./shared-welcome-page.component.scss']
})
export class SharedWelcomePageComponent implements OnInit , OnDestroy{

  @Input() profileData!: TradeProfile;
  @Output() startOnboardingEvent = new EventEmitter<boolean>();

  constructor(
    private router: Router,
    private authService: AuthService,
    @Inject(DOCUMENT) private document: Document,
    private tradieService: TradieService,
  ) { }

  ngOnInit(): void {
    this.document.body.classList.add('no-scroll');

  }

  ngOnDestroy(): void {
    this.document.body.classList.remove('no-scroll');
  }

  startOnboarding() {
    const isStart = true
    this.startOnboardingEvent.emit(isStart);
  }

  onSuccess() {
    throw new Error('Method not implemented.');
  }

  logout() {
    this.authService.logout()
  }
}
