import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PopupData } from '../../../models/common/common.model';

@Component({
  selector: 'app-shared-alert-popup',
  templateUrl: './shared-alert-popup.component.html',
  styleUrls: ['./shared-alert-popup.component.scss']
})
export class SharedAlertPopupComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<SharedAlertPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PopupData
  ) { }

  ngOnInit(): void {
  }

  closePopUp() : void {
    this.dialogRef.close();
  }
}
