import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from 'src/app/modules/onboarding/shared/services/user.service';
import { AlertService } from 'src/app/modules/shared/services/alert/alert.service';

@Component({
  selector: 'app-shared-update-name-popup',
  templateUrl: './shared-update-name-popup.component.html',
  styleUrls: ['./shared-update-name-popup.component.scss']
})
export class SharedUpdateNamePopupComponent implements OnInit {

  formUpdateName!: FormGroup;
  submitted = false;
  isLoading = false;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<SharedUpdateNamePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public tradeInfo: any,
    private userService: UserService,
    private alertService: AlertService
  ) { }

  ngOnInit(): void {
    this.formUpdateName = this.formBuilder.group({
      userId: [this.tradeInfo.id, Validators.required],
      firstName: [this.tradeInfo.firstName, [Validators.required, Validators.pattern('^[a-zA-Z]+$')]],
      lastName: [this.tradeInfo.lastName, [Validators.required, Validators.pattern('^[a-zA-Z]+$')]]
    });
  }

  get f() { return this.formUpdateName.controls; }

  onSubmit() {
    this.submitted = true;
    
    // If invalid
    if (this.formUpdateName.invalid) {
      
      return;
    }
    
    this.isLoading = true;
    this.userService.updateUserFullName(this.formUpdateName.value).subscribe( response => {
      if (response.success) {
        this.alertService.success('Name successfully updated');
        this.dialogRef.close(response.data);
      } else {
        this.alertService.error('Operation failed');
      }
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
      this.alertService.error('Operation failed');
    });
  }
}
