export enum Main {}

export enum OperatingSystemType {
  IOS = 1,
  Android = 2,
  Web = 3,
}

export enum UserType {
  SuperAdmin = 1,
  Admin = 2,
  StrataManager = 3,
  Tradie = 4,
  Resident = 5,
  RAndM = 6,
}

export enum JobMediaType {
  Image = 1,
  Video = 2,
  PDF = 3,
  Word = 4,
}

export enum MediaPostTime {
  Initial = 1,
  AfterCompleted = 2,
}

export enum AddressType {
  Home = 1,
}

export enum BusinessServiceStatus {
  PendingApproval = 1,
  ActionRequired = 2,
  Alert = 3,
  Approved = 4,
  Restricted = 5,
  NotApproved = 6,
  Exempt = 7,
}

export enum RequestStatus {
  Pending = 1,
  Accept = 2,
  Cancel = 3,
  Delete = 4,
  Expired = 5,
}

export enum FixType {
  Emergency = 1,
  Scheduled = 2,
  QuoteJob = 3,
}

export enum RefererenceType {
  MaintenanceJob = 1,
  CommunalRequest = 2,
  RecurringJob = 3,
  Quotation = 4,
}

export enum TimeSlot {
  T0930 = 1,
  T1130 = 2,
  T1330 = 3,
  T1530 = 4,
}

export enum RecurringType {
  Daily = 1,
  Weekly = 2,
  Fortnightly = 3,
  Monthly = 4,
  Annually = 5,
}

export enum RequestStatusType {
  Pending = 1,
  Accept = 2,
  Cancel = 3,
  Delete = 4,
  Expired = 5,
}

export enum JobStatusType {
  Initialized = 0,
  Draft = 1,
  Requested = 2,
  Accepted = 3,
  Assigned = 4,
  Enroute = 5,
  Started = 6,
  Completed = 7,
  Cancelled = 8,
  Expired = 9,
  Invoiced = 10,
  InvoiceApproved = 11,
  InvoicePaid = 12,
}

export enum StateType {
  NewSouthWales = 1,
  Victoria = 2,
  Queensland = 3,
  WesternAustralia = 4,
  SouthAustralia = 5,
  Tasmania = 6,
  NorthernTerritory = 7,
  AustralianCapitalTerritory = 8,
}

export enum OwrnershipType {
  Owner = 1,
  Tenant = 2,
}

export enum TradieRole {
  Admin = 1,
  TeamMember = 2,
}

export enum Post {
  Owner = 1,
  Admin = 2,
  ProjectManager = 3,
  Worker = 4,
}

export enum TradeAdminPost {
  Owner = 1,
  Admin = 2,
  ProjectManager = 3,
}

export enum GeneralComplianceStatusType {
  PendingApproval = 1,
  ActionRequired = 2,
  Alert = 3,
  Approved = 4,
  Restricted = 5,
  Expired = 6,
  Exempt = 7
}

export enum BusinessStatus {
  NewApplicant = 1,
  Approved = 2,
  Archived = 3,
  Suspended = 4,
  Disabled = 5,
  InviteSent = 6,
  Onboarding = 7

}

export enum  GeneralComplianceType {
  Insurances = 1,
  HealthSafety = 2,
}


export enum AdditionalPreferencesQuestionType {
  RadioButton = 1,
  TextBox = 2,
  CheckBox = 3
}

export enum  LeadStatusType {
  InviteSent = 1,
  Onboarding = 2,
  NewApplicant = 3,
  Approved = 4
}