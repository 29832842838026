import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DocumentCreateModel, DocumentEditModel } from 'src/app/modules/shared/models/document.model';


@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  private endpoint = environment.endpointURL + '/api/Document';

  constructor( private http: HttpClient ) { }

  public createDocumentById(data: DocumentCreateModel): Observable<any> {
    const url = `${this.endpoint}`;
    return this.http.post<any>(url, data);
  }

  public deleteDocument(documentId: string, isArchived: boolean): Observable<any> {
    const url = `${this.endpoint}?id=${documentId}&isArchived=${isArchived}`;
    return this.http.delete<any>(url);
  }

  public updateDocument(data: DocumentEditModel): Observable<any> {
    const url = `${this.endpoint}`;
    return this.http.put<any>(url, data);
  }

  public getDocumentsByUserId(userId : string): Observable<any> {
    const url = `${this.endpoint}/GetByUserId?UserId=${userId}`;
    return this.http.get<any>(url);
  }
}
