import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DocumentCategory, DocumentCategoryType, DocumentGroup, DocumentStatus } from 'src/app/core/enums/document.enum';
import { CustomDocumentModel, DocumentModel, MainDocumentTypes } from '../../../models/document.model';
import { HealthSafetyCompliance, ProfessionalIdemnityCompliance } from '../../../models/tradie/tradie.model';
import { AlertService } from '../../../services/alert/alert.service';
import { DocumentService } from '../../../services/documents/document.service';
import { UploadService } from '../../../services/upload/upload.service';
import { SharedConfirmationPopupComponent } from '../../popups/shared-confirmation-popup/shared-confirmation-popup.component';
import { SharedUploadUserDucumentPopupComponent } from '../../popups/shared-upload-user-ducument-popup/shared-upload-user-ducument-popup.component';

@Component({
  selector: 'app-shared-user-document-widget',
  templateUrl: './shared-user-document-widget.component.html',
  styleUrls: ['./shared-user-document-widget.component.scss']
})
export class SharedUserDocumentWidgetComponent implements OnInit {

  @Input() isLoading: boolean = false;
  @Input() hideStatus: boolean = false;
  @Input() customDocumentData!: CustomDocumentModel;
  @Input() idemnityComplianceData!: ProfessionalIdemnityCompliance;
  @Input() healthSafetyComplianceData!: HealthSafetyCompliance;
  @Input() mainDocumentType!: MainDocumentTypes;
  @Input() availableDocTypes!: number[];

  @Output() documentChangeEvent = new EventEmitter<DocumentModel>();
  @Output() documentDeleteEvent = new EventEmitter<DocumentModel>();
  @Output() otherDocumentChangeEvent = new EventEmitter<CustomDocumentModel>();

  docTypes = MainDocumentTypes
  
  documentGroup = DocumentGroup;
  documentType = DocumentCategoryType;
  documentCategory = DocumentCategory;
  documentStatus = DocumentStatus

  constructor(
    public dialog: MatDialog,
    private documentService: DocumentService,
    private alertService: AlertService,
    private uploadService: UploadService,
  ) { }

  ngOnInit(): void {
  }

  createUpdateDocumentPopup() {
    const dialogRef = this.dialog.open(SharedUploadUserDucumentPopupComponent, {
      maxWidth: '500px',
      width: '90%',
      position: {
        top: '60px',
      },
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      closeOnNavigation: true,
      disableClose: true,
      data: {documentData: this.customDocumentData, types: this.availableDocTypes},
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        if(this.customDocumentData.isOtherDoc) {
          this.customDocumentData = result
          this.otherDocumentChangeEvent.emit(this.customDocumentData)
        } else {
          this.customDocumentData.document = result
          this.documentChangeEvent.emit(this.customDocumentData.document)
        }
      }
    });
  }

  deleteDocumentConfirmation() {
    const dialogRef = this.dialog.open(SharedConfirmationPopupComponent, {
      maxWidth: '500px',
      width: '90%',
      position: {
        top: '60px',
      },
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      closeOnNavigation: true,
      disableClose: true,
      data: {
        title: 'Please Confirm',
        message: 'Are you sure you want to delete this document?',
        confirmTxt: 'Yes, Delete',
        cancelTxt: 'Cancel',
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      const isArchived = true
      if (result) {
        this.deleteUserDocument(this.customDocumentData.document ,isArchived)
      }
    });
  }
  
  deleteUserDocument(document: DocumentModel, isArchived: boolean) {
    this.isLoading = true
    const docId = document.id
    this.documentService.deleteDocument(docId, isArchived).subscribe({
      next: (res) => {
        if (res.success) {
          this.alertService.success('Document deleted successfully')
          this.customDocumentData.document = null!
          this.documentDeleteEvent.emit(document)
        } else {
          this.alertService.error(res.error.message)
        }
        this.isLoading = false
      }, error: (err) => {
        this.isLoading = false
        this.alertService.error('Operation failed')
      },
    });
  }

  
  // Download Document
  downloadDocument(key: string, name: string) {
    const documentUrl = this.uploadService.getUrlByS3KEY(key);
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', documentUrl);
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}
