export const environment = {
  production: false,
  endpointURL: 'https://gateway.fixby.xigenix.com', // Development API

  config : {
    location: 'xigenix-fixby',
    key: 'PnzDdD7cUqMDKXEFcKsKcwBe7NpQcuGtuOjlMkmM',
    keyId: 'AKIAQWL5IXWYYDYT3ZFW',
    region: 'ap-southeast-2',
    expireTimeInMinutes: 1,  // ( default : file urls expires in 1 min )
  },

  map: {
    googleMapsKey: 'AIzaSyBs3KBTiJ8XwjZqOfZhWDqEklq3i4EwOdA',
    geoLocations: {
      australia: {latitude: -26.01042, longitude: 133.9408053}
    },
    zoom: 8
  },

  stripe : {
    key : 'pk_test_51IlmLpKueDECEAqNYprBWgAoPQzvXfNozDyIah0m7YHDaJz1ZtQ0dmjYhLqRNSld1dZGuXhGZfcPQtlLF2jZfUCC008BPQyWds'
  },

  showCompliance: true
};
