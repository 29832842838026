import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BusinessInfo } from '../../../models/tradie/tradie.model';
import { AlertService } from '../../../services/alert/alert.service';
import { TradieService } from '../../../services/tradie/tradie.service';


@Component({
  selector: 'app-shared-update-business-name-popup',
  templateUrl: './shared-update-business-name-popup.component.html',
  styleUrls: ['./shared-update-business-name-popup.component.scss']
})
export class SharedUpdateBusinessNamePopupComponent implements OnInit {

  formUpdateBusinessName!: FormGroup;
  submitted = false;
  isLoading = false;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<SharedUpdateBusinessNamePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public  businessInfo: BusinessInfo,
    private alertService: AlertService,
    private tradieService: TradieService
  ) { }

  ngOnInit(): void {
    this.formUpdateBusinessName = this.formBuilder.group({
      id: this.businessInfo.id,
      businessName: [this.businessInfo.businessName, Validators.required],
      entityType: [this.businessInfo.entityType, Validators.required],
    });
  }

    // convenience getter for easy access to form fields
    get f() { return this.formUpdateBusinessName.controls; }

    onSubmit() {
      this.submitted = true;
      
      // If invalid
      if (this.formUpdateBusinessName.invalid) {
        return;
      }
      
      this.isLoading = true;
      this.tradieService.updateBusinessInfo(this.formUpdateBusinessName.value).subscribe( response => {
        if (response.success) {
          this.alertService.success('Business name successfully updated');
          this.dialogRef.close(response.data);
        } else {
          this.alertService.error(response.error.message);
        }
        this.isLoading = false;
      }, error => {
        this.isLoading = false;
        this.alertService.error('Operation failed');
      });
    }
}
