import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AddBank, UpdateBank } from '../../models/bank/bank.model';

@Injectable({
  providedIn: 'root'
})
export class BankService {

  private endpoint = environment.endpointURL+'/api';
  private bankEndpoint = environment.endpointURL + '/api/UserBankAccount';
  private endpointUserCard = environment.endpointURL+'/api/UserCard';
  private endpointBusinessCreaditCard = environment.endpointURL+'/api/BusinessCreditCard';
  private endpointFinancial = environment.endpointURL+'/api/Financial';

  constructor( private http: HttpClient ) { }

  // Bank
  public getBankAccountsById(userId: string): Observable<any> {
    const url = `${this.bankEndpoint}/GetByUserId?userId=${userId}`;
    return this.http.get<any>(url);
  }

  public createBankAccount(data: AddBank): Observable<any> {
    const url = `${this.bankEndpoint}`;
    return this.http.post<any>(url, data);
  }

  public updateBankAccount(data: UpdateBank): Observable<any> {
    const url = `${this.bankEndpoint}`;
    return this.http.put<any>(url, data);
  }

  public deleteBankAccount(id: string, isArchived: boolean): Observable<any> {
    const url = `${this.bankEndpoint}?id=${id}&isArchived=${isArchived}`;
    return this.http.delete(url);
  }

  // Cards

    // Get Stripe Intent
    public setupIntent(id: string) {
      const url = `${this.endpoint}/Stripe/SetupIntent?userId=${id}`;
      return this.http.post<any>(url, id);
    }
    
    // ---- Business Credit Card --- //
    public getByBusinessInfoId(businessInfoId : string) {
      const url = `${this.endpointBusinessCreaditCard}/GetByBusinessInfoId?businessInfoId=${businessInfoId}`;
      return this.http.get<any>(url);
    }
  
    public createBusinessCreaditCard(data: any) {
      const url = `${this.endpointBusinessCreaditCard}`;
      return this.http.post<any>(url, data);
    }
  
    // --- User Cards --- //
    public createUserCard(data: any){
      const url = `${this.endpointUserCard}`;
      return this.http.post<any>(url, data)
    }
  
    public getUserCardById(id: string) {
      const url = `${this.endpointUserCard}?userId=${id}`;
      return this.http.get<any>(url)
    }
  
    public updateUserCardDefault(data: any){
      const url = `${this.endpointUserCard}/SetDeaultCard`;
      return this.http.put<any>(url, data)
    }
  
    public patchExpDate(data: any){
      const url = `${this.endpointUserCard}`;
      return this.http.patch<any>(url, data)
    }
  
    public deleteUserCardById(id: string) {
      const url = `${this.endpointUserCard}?cardId=${id}`;
      return this.http.delete<any[]>(url)
    }
  
  // Financial
  public payment(data: any){
    const url = `${this.endpointFinancial}/Payment`;
    return this.http.post<any>(url, data)
  }
  
  public paymentSubscription(data: any){
    const url = `${this.endpointFinancial}/Subscription`;
    return this.http.post<any>(url, data)
  }
  
}