import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BusinessServiceAddModel, BusinessServiceUpdateModel, RegisterBusiness, TradieUpdateLogo } from '../models/tradie.model';
import { CreateAnswerModel, UpdateAnswerModel } from '../models/aditional-preferences/aditional-preferences.model';
import { BusinessServiceDocumentModel } from 'src/app/modules/shared/models/document.model';
import { SWMS } from '../models/SWMS/swms.model';

@Injectable({
  providedIn: 'root'
})
export class TradieService {
  private endpoint = environment.endpointURL + '/api/Tradie';

  constructor( private http: HttpClient ) { }

  public updateOrientationStatus(data: any) {
    const url = `${this.endpoint}/UpdateOrientationStatus`;
    return this.http.patch<any>(url, data);
  }

  public createIndemnitydoc(data: any): Observable<any> {
    const url = `${this.endpoint}/ProfessionalIdemnityInsurance`;
    return this.http.post<any>(url,data);
  }


  public getOnboardingProgress(tradeId: string): Observable<any> {
    const url = `${this.endpoint}/GetOnboardingProgress?id=${tradeId}`;
    return this.http.get<any>(url);
  }

  public registerBusiness(data: RegisterBusiness): Observable<any> {
    const url = `${this.endpoint}/RegisterBusiness`;
    return this.http.post<any>(url, data);
  }

  public updateBusiness(data: RegisterBusiness): Observable<any> {
    const url = `${this.endpoint}/RegisterBusiness`;
    return this.http.patch<any>(url, data);
  }

  public getAbnInfo(abn: string): Observable<any> {
    const url = `${this.endpoint}/AbnInfo?abn=${abn}`;
    return this.http.get<any>(url);
  }

  public checkIsExistsABN(abn: string): Observable<any> {
    const url = `${this.endpoint}/IsExistsABN?abn=${abn}`;
    return this.http.get<any>(url);
  }

  // --- BUSINESS SERVICE DOCUMENT --- //
  public addBusinessServiceDocument(data: BusinessServiceDocumentModel): Observable<any> {
    const url = `${this.endpoint}/BusinessServiceDocument`;
    return this.http.post<any>(url, data);
  }

  public updateBusinessServiceDocument(data: BusinessServiceDocumentModel): Observable<any> {
    const url = `${this.endpoint}/BusinessServiceDocument`;
    return this.http.put<any>(url, data);
  }

  public deleteBusinessServiceDocument(id: string): Observable<any> {
    const url = `${this.endpoint}/BusinessServiceDocument?id=${id}`;
    return this.http.delete<any>(url);
  }

  // --- Trade Profile --- //
  public updateLogo(data: TradieUpdateLogo): Observable<any> {
    const url = `${this.endpoint}/UpdateLogo`;
    return this.http.patch<any>(url, data);
  }


  // --- Business Services --- //
  public addBusinessService(data: BusinessServiceAddModel): Observable<any> {
    const url = `${this.endpoint}/BusinessService`;
    return this.http.post<any>(url, data);
  }

  public updateBusinessService(data: BusinessServiceUpdateModel): Observable<any> {
    const url = `${this.endpoint}/BusinessService`;
    return this.http.put<any>(url, data);
  }

  public verifyBusinessService(id: string, isVerified: boolean): Observable<any> {
    const url = `${this.endpoint}/VerifyBusinessService?id=${id}&isVerified=${isVerified}`;
    return this.http.patch<any>(url, id);
  }

  public deleteBusinessService(id: string): Observable<any> {
    const url = `${this.endpoint}/BusinessService?id=${id}`;
    return this.http.delete<any>(url);
  }

  // --- Professional Idemnity Compliance -- //
  public createProfessionalIdemnityCompliance(data: any): Observable<any> {
    const url = `${this.endpoint}/ProfessionalIdemnityCompliance`;
    return this.http.post<any>(url, data);
  }

  public updateProfessionalIdemnityCompliance(data: any): Observable<any> {
    const url = `${this.endpoint}/ProfessionalIdemnityCompliance`;
    return this.http.put<any>(url, data);
  }

  public getProfessionalIdemnityCompliance(businessInfoId: string): Observable<any> {
    const url = `${this.endpoint}/ProfessionalIdemnityCompliance?businessInfoId=${businessInfoId}`;
    return this.http.get<any>(url);
  }
  
  // --- Health Safety Compliance -- //
  public createHealthSafetyCompliance(data: any): Observable<any> {
    const url = `${this.endpoint}/HealthSafetyCompliance`;
    return this.http.post<any>(url, data);
  }

  public updateHealthSafetyCompliance(data: any): Observable<any> {
    const url = `${this.endpoint}/HealthSafetyCompliance`;
    return this.http.put<any>(url, data);
  }

  public deleteHealthSafetyCompliance(id: string, businessInfoId: string, isArchived: boolean, userId : string ): Observable<any> {
    const url = `${this.endpoint}/HealthSafetyCompliance?id=${id}&businessInfoId=${businessInfoId}&isArchived=${isArchived}&UserId=${userId}`;
    return this.http.delete<any>(url);
  }

  public getHealthSafetyCompliance(businessInfoId: string): Observable<any> {
    const url = `${this.endpoint}/HealthSafetyCompliance?businessInfoId=${businessInfoId}`;
    return this.http.get<any>(url);
  }

  // --- SWMS --- // 
  public createSWMS(data: SWMS): Observable<any> {
    const url = `${this.endpoint}/SWMS`;
    return this.http.post<any>(url, data);
  }
  public updateSWMS(data: SWMS): Observable<any> {
    const url = `${this.endpoint}/SWMS`;
    return this.http.put<any>(url, data);
  }
  public getSWMSList(businessInfoId: string): Observable<any> {
    const url = `${this.endpoint}/SWMS?businessInfoId=${businessInfoId}`;
    return this.http.get<any>(url);
  }

  // --- Aditional Preference --- //
  public getAditionalPreference(businessInfoId: string): Observable<any> {
    const url = `${this.endpoint}/AditionalPreference?businessInfoId=${businessInfoId}`;
    return this.http.get<any>(url);
  }

  public createAditionalPreference(data: CreateAnswerModel): Observable<any> {
    const url = `${this.endpoint}/AditionalPreference`;
    return this.http.post<any>(url, data);
  }

  public updateAditionalPreference(data: UpdateAnswerModel): Observable<any> {
    const url = `${this.endpoint}/AditionalPreference`;
    return this.http.put<any>(url, data);
  }

  public deleteAditionalPreference(answerId: string): Observable<any> {
    const url = `${this.endpoint}/AditionalPreference?id=${answerId}`;
    return this.http.delete<any>(url);
  }


  // set trade entity
  public updateTradeComplianceEntity(data: any): Observable<any> {
    const url = `${this.endpoint}/UpdateTradeComplianceEntity`;
    return this.http.patch<any>(url, data);
  }

  public SetOnboardingStatus(data: any) {
    const url = `${this.endpoint}/SetOnboardingStatus`;
    return this.http.post<any>(url, data);
  }
}
