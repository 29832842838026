<article [ngSwitch]="currentStatus">
  <div class="status-wr d-flex align-items-center" *ngSwitchCase="documentStatus.Processing">
    <img class="icon me-1" src="/assets/icons/status/processing.svg" />    
    <span class="label font-semi-bold processing lh-1">Processing</span>
  </div>

  <div class="status-wr d-flex align-items-center" *ngSwitchCase="documentStatus.Verified">
    <img class="icon me-1" src="/assets/icons/status/verified.svg" />    
    <span class="label font-semi-bold verified lh-1">Verified</span>
  </div>

  <div class="status-wr d-flex align-items-center" *ngSwitchCase="documentStatus.Rejected">
    <img class="icon me-1" src="/assets/icons/status/rejected.svg" />    
    <span class="label font-semi-bold rejected lh-1">Rejected</span>
  </div>

  <div class="status-wr d-flex align-items-center" *ngSwitchCase="documentStatus.Expired">
    <img class="icon me-1" src="/assets/icons/status/expired.svg" />    
    <span class="label font-semi-bold expired lh-1">Expired</span>
  </div>

  <div class="status-wr d-flex align-items-center" *ngSwitchCase="documentStatus.Exempt">
    <img class="icon me-1" src="/assets/icons/status/exempt.svg" />    
    <span class="label font-semi-bold exempt lh-1">Exempt</span>
  </div>

  <div class="status-wr d-flex align-items-center" *ngSwitchCase="documentStatus.Alert">
    <img class="icon me-1" src="/assets/icons/status/alert.svg" />    
    <span class="label font-semi-bold alert lh-1">Alert</span>
  </div>
</article>