export class UserFiles {
}

export class UserFilesModel {
    id!: string;
    documentsHeaderId!: string;
    name!: string;
    link!: string;
    documentType!: number;
    documentCategory!: number;
    group!: number;
    isArchived!: boolean;
    expireDate!: string;
    lastUpdatedDate!: string;
    status!: number;
    comment!: string;
}

export class UserFilesDataModel {
    id!: string;
    userId!: number;
    lastUpdatedDate!: string;
    isArchived!: boolean;
    documentsDetails!: UserFilesModel[];
}

export class UserFilesResponseModel {
    data!: any;
    success!: boolean;
    error!: any;
}

export class UserFilesCreateModel {
    name!: string;
    link!: string;
    userId!: string;
    expireDate!: any;
    documentCategory!: number;
    documentType!: number;
    group!: number;
}

export class UserFilesEditModel {
    id!: string;
    userId!: string;
    detailsId!: string;
    documentsHeaderId!: string;
    name!: string;
    link!: string;
    documentType!: number;
    documentCategory!: number;
    group!: number;
    status!: number;
    expireDate!: string;
}

export class UserFilesCustomDocumentModel {
    documentName!: string;
    documentCategory!: number;
    documentGroup!: number;
    hasExpiry!: boolean;
    isBusinessDocument!: boolean;
    isBusinessServiceDocument!: boolean;
    currentUserId!: string;
  
    document!: UserFilesModel;
    businessInfoId!: any;
    businessServiceId!: any;
    fileLocation!: any;
  
    isOtherDoc!: boolean;
    otherDocExpiry!: string;
    otherDocURL!: string;
  }