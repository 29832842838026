import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ServiceCategory } from '../../../models/service-category/service-category.model';
import { BusinessService } from '../../../models/tradie/tradie.model';
import { AlertService } from '../../../services/alert/alert.service';
import { ServiceCategoryService } from '../../../services/service-category/service-category.service';
import { TradieService } from '../../../services/tradie/tradie.service';
import { SharedConfirmationPopupComponent } from '../../popups/shared-confirmation-popup/shared-confirmation-popup.component';
import { SharedUpdateServiceCategoryPopupComponent } from '../../popups/shared-update-service-category-popup/shared-update-service-category-popup.component';

@Component({
  selector: 'app-shared-category-rates-widget',
  templateUrl: './shared-category-rates-widget.component.html',
  styleUrls: ['./shared-category-rates-widget.component.scss']
})
export class SharedCategoryRatesWidgetComponent implements OnInit {

  @Input() businessService!: BusinessService;
  @Input() isDisplayOnly!: boolean;
  @Input() deleteOnly!: boolean;
  @Output() removeCategoryEvent = new EventEmitter<any>();

  isLoading = false
  serviceCategoryList: ServiceCategory[] = [];
  specialtyList: any[] = []

  constructor(
    private tradieService: TradieService,
    private alertService: AlertService,
    private serviceCategoriesStoreService: ServiceCategoryService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.specialtyList = this.businessService.businessServiceSpecialty.filter(i=>i.isArchived == false);
    this.getServiceCategories()
  }

  editCategoryRates() {
    const dialogRef = this.dialog.open( SharedUpdateServiceCategoryPopupComponent, {
      maxWidth: '640px',
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      closeOnNavigation: true,
      data: { isAdd: false, id: this.businessService.businessInfoId, data: this.businessService, serviceCategories: this.serviceCategoryList}
    });

    dialogRef.afterClosed().subscribe( result => {
      if (result) {
        this.businessService = result
      }
    });
  }

  removeCategoryConfirmation() {
    const dialogRef = this.dialog.open( SharedConfirmationPopupComponent, {
      position: {
        top: '60px'
      },
      width: '330px',
      data: {
        title: 'CONFIRM',
        message: 'Are you sure you want to remove this service category?',
        confirmTxt: 'Yes, Remove',
        cancelTxt: 'Cancel'
      }
    });

    dialogRef.afterClosed().subscribe( isTrue => {
      if (isTrue) {
        this.removeCategory();
      }
    });
  }

  private removeCategory() {
    this.isLoading = true
    const catId = this.businessService.id
    this.tradieService.deleteBusinessService(catId).subscribe( res => {
      if (res.success) {
        this.removeCategoryEvent.emit(catId)
        this.alertService.success('Service category removed successfully')
      } else {
        this.alertService.error(res.error.message)
      }
      this.isLoading = false
    }, err => {
      this.isLoading = false
      this.alertService.error('Operation failed, please try again')
    })
  }

  private getServiceCategories() {
    this.serviceCategoriesStoreService.getStoredServiceCategories().subscribe((res) => {
      this.serviceCategoryList = res.data;
    });
  }
}
