import { Directive } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';


@Directive({
  selector: '[appMatchedEmailValidator]'
})
export class MatchedEmailValidatorDirective {
  constructor() { }
}

export const emailMatchValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const email = control.get('email');
  const confirmEmail = control.get('confirmEmail');
  return email?.value === confirmEmail?.value ? null : { notmatched: true };
};