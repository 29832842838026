import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddressType } from 'src/app/core/enums/main.enum';
import { UserAddress } from '../../../models/user/user.model';
import { AlertService } from '../../../services/alert/alert.service';
import { UserService } from '../../../services/user/user.service';

@Component({
  selector: 'app-shared-update-address-popup',
  templateUrl: './shared-update-address-popup.component.html',
  styleUrls: ['./shared-update-address-popup.component.scss']
})
export class SharedUpdateAddressPopupComponent implements OnInit {

  formUpdateAddress!: FormGroup;
  submitted = false;
  isLoading = false;
  addressType = AddressType;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<SharedUpdateAddressPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public userAddressData: UserAddress,
    private userService: UserService,
    private alertService: AlertService
  ) { }

  ngOnInit(): void {
    this.formUpdateAddress = this.formBuilder.group({
      userId: this.userAddressData.userId,
      type: this.addressType.Home,
      streetName: [this.userAddressData.streetName, Validators.required],
      unitNumber: [this.userAddressData.unitNumber, Validators.required],
      city: [this.userAddressData.city, Validators.required],
      state: [this.userAddressData.state, Validators.required],
      zipCode: [this.userAddressData.zipCode, Validators.required],
    });

    if (this.userAddressData.id) {
      this.formUpdateAddress.addControl('id', new FormControl(this.userAddressData.id));
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.formUpdateAddress.controls; }

  onSubmit() {
    this.submitted = true;
    this.isLoading = true;

    // If invalid
    if (this.formUpdateAddress.invalid) {
      this.isLoading = false;
      return;
    }

    if (this.userAddressData.id) {
      this.updateUserAddress();
    } else {
      this.addUserAddress();
    }
  }

  // Add user address
  addUserAddress() {
    this.userService.addUserAddress(this.formUpdateAddress.value).subscribe( response => {
      if (response.success) {
        this.alertService.success('Address successfully added');
        const address = response.data
        this.dialogRef.close(address);
      } else {
        this.alertService.error('Operation failed');
      }
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
      this.alertService.error('Operation failed');
    });
  }

  // Update user address
  updateUserAddress() {
    this.userService.updateUserAddress(this.formUpdateAddress.value).subscribe( response => {
      if (response.success) {
        this.alertService.success('Address successfully updated');
        const address = response.data;
        this.dialogRef.close(address);
      } else {
        this.alertService.error('Operation failed');
      }
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
      this.alertService.error('Operation failed');
    });
  }

}
