import { Component, ElementRef, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { ServiceArea, ServiceAreaList } from 'src/app/modules/onboarding/shared/models/location/location.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../../services/alert/alert.service';
import { LocationService } from 'src/app/modules/onboarding/shared/services/location/location.service';
import { StateTypeLabelMapping } from './StateTypeLabelMapping';
import { StateType } from 'src/app/core/enums/main.enum';

@Component({
  selector: 'app-shared-update-location-popup',
  templateUrl: './shared-update-location-popup.component.html',
  styleUrls: ['./shared-update-location-popup.component.scss']
})
export class SharedUpdateLocationPopupComponent implements OnInit {

  @ViewChild('search') public searchElementRef!: ElementRef;
  
  submitted = false
  isCurrentLocationSet = false
  isAddressSet = false
  isLoading = false
  isMapClicked = false
  isUpdate = false
  createLocationData = new ServiceArea()

  defaultLatitude = environment.map.geoLocations.australia.latitude;
  defaultLongitude = environment.map.geoLocations.australia.longitude;
  currentLatitude!: number;
  currentLongitude!: number;
  zoom = environment.map.zoom;

  address!: string;
  private geoCoder: any;
  markerRadious: number = 0; // Initial radious
  state:number = 0; //Initial state

  formLocation!: FormGroup;
  iconURL = './assets/icons/common/pin.svg'
  stateType = StateType;
  stateTypeLabels = StateTypeLabelMapping;
  keys: string[];

  states = [
    { name: 'New South Wales', value:this.stateType.NewSouthWales},
    { name: 'Victoria',value:this.stateType.Victoria },
    { name: 'Queensland',value:this.stateType.Queensland },
    { name: 'Western Australia',value:this.stateType.WesternAustralia },
    { name: 'South Australia' ,value:this.stateType.SouthAustralia},
    { name: 'Tasmania',value:this.stateType.Tasmania },
    { name: 'Northern Territory',value:this.stateType.NorthernTerritory },
    { name: 'Australian Capital Territory',value:this.stateType.AustralianCapitalTerritory },
  ]
  
  constructor(
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private locationService: LocationService,
    public dialogRef: MatDialogRef<SharedUpdateLocationPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {businessInfoId: string, tradeId: string, area: ServiceAreaList},
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone
  ) {
    this.keys = Object.keys(this.stateTypeLabels);
  }

  ngOnInit(): void {
    this.formLocation = this.formBuilder.group({
      geoLocationAddress: ['', Validators.required],
      state :  ['', Validators.required],
      radious: ['', [ Validators.pattern('^\-?[0-9]+(?:\.[0-9]{1,2})?$'), Validators.required] ]
    });
    
    this.formLocation.get("radious")?.valueChanges.subscribe( radious => {
      this.markerRadious = radious * 1000 // km to meter
    })

    this.formLocation.get("state")?.valueChanges.subscribe( state => {
      this.state = state
    })
    
    this.mapsAPILoader.load().then(() => {
      this.setLocation(this.data.area);
      this.geoCoder = new google.maps.Geocoder;
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.currentLatitude = place.geometry.location.lat();
          this.currentLongitude = place.geometry.location.lng();
          this.setAddress(this.currentLatitude, this.currentLongitude)
        });
      });
    });

    if (this.data.area) {
      this.isUpdate = true
      this.setUpdateData()
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.formLocation.controls; }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.formLocation.invalid) {
      return;
    }
    this.saveLocation()
  }

  saveLocation() {
    this.createLocationData.userId = this.data.tradeId
    this.createLocationData.name = this.address
    this.createLocationData.businessInfoId = this.data.businessInfoId
    this.createLocationData.raduis = this.markerRadious
    this.createLocationData.state = this.state
    this.createLocationData.setGeoLocation(this.currentLatitude, this.currentLongitude)

    if (this.createLocationData.id) {
      this.updateLocation(this.createLocationData)
    } else {
      this.createLocation(this.createLocationData)
    }
  }

  private createLocation(createLocationData: ServiceArea) {
    this.isLoading = true
    this.locationService.createServiceArea(createLocationData).subscribe({
      next: res => {
        if (res.success) {
          this.dialogRef.close(res.data)
          this.alertService.success('Service area added successfully')
        } else {
          this.alertService.error(res.error.message)
        }
        this.isLoading = false
      },
      error: err => {
        this.alertService.error('Operation failed')
        this.isLoading = false
      }
    })
  }

  private updateLocation(createLocationData: ServiceArea) {
    this.isLoading = true
    this.locationService.updateServiceArea(createLocationData).subscribe({
      next: res => {
        if (res.success) {
          this.dialogRef.close(res.data)
          this.alertService.success('Service area updated successfully')
        } else {
          this.alertService.error(res.error.message)
        }
        this.isLoading = false
      },
      error: err => {
        this.alertService.error('Operation failed')
        this.isLoading = false
      }
    })
  }

  setUpdateData() {
    this.createLocationData.id = this.data.area.id
    this.address = this.data.area.name
    this.formLocation.controls['geoLocationAddress'].setValue(this.address)
    this.formLocation.controls['radious'].setValue(this.data.area.raduis / 1000) // meter to km
    this.formLocation.controls['state'].setValue(this.data.area.state) 
    this.currentLatitude = this.data.area.location.latitude
    this.currentLongitude = this.data.area.location.longitude
    this.isMapClicked = true
  }

  // Set given location in the map or default
  setLocation(area: any) {
    this.currentLatitude = (area == null) ? this.defaultLatitude : area.location.latitude;
    this.currentLongitude = (area == null) ? this.defaultLongitude: area.location.longitude;
    this.isCurrentLocationSet = true
    this.zoom = 4
  }

  // Get Current Location Coordinates - Need user permissions
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.currentLatitude = position.coords.latitude;
        this.currentLongitude = position.coords.longitude;
        this.isCurrentLocationSet = true
        this.zoom = 12
      });
    }
  }

  mapClicked($event: any) {
    this.isMapClicked = true
    this.currentLatitude = $event.coords.lat;
    this.currentLongitude = $event.coords.lng;
    this.setAddress(this.currentLatitude, this.currentLongitude)
  }

  markerDragEnd($event: MouseEvent) {
    this.currentLatitude = $event.coords.lat;
    this.currentLongitude = $event.coords.lng;
    this.setAddress(this.currentLatitude, this.currentLongitude);
  }

  setAddress(latitude: number, longitude: number) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results : any, status: any) => {
      if (status === 'OK') {
        if (results[0]) {
          this.address = results[0].formatted_address;
          this.formLocation.controls['geoLocationAddress'].setValue(this.address)
          this.formLocation.controls['radious'].setValue(1)
          this.isAddressSet = true
          this.zoom = 15
        } else {
          this.alertService.error('No results found')
        }
      } else {
        this.alertService.error('Geocoder failed due to: ' + status)
      }
    });
  }

  resetMap() {
    this.currentLatitude = this.defaultLatitude
    this.currentLongitude = this.defaultLongitude
    this.markerRadious = 0
    this.zoom = 4
    this.formLocation.controls['geoLocationAddress'].setValue('')
    this.formLocation.controls['radious'].setValue('')
    this.isAddressSet = false
  }
}
