import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import * as AWS from 'aws-sdk'; // TEMP
import { ImageUpdateModel } from '../../../models/user/user.model';
import { AlertService } from '../../../services/alert/alert.service';

@Component({
  selector: 'app-shared-image-upload-popup',
  templateUrl: './shared-image-upload-popup.component.html',
  styleUrls: ['./shared-image-upload-popup.component.scss']
})
export class SharedImageUploadPopupComponent implements OnInit {

  @ViewChild('fileInput') fileInputRef!: ElementRef;
  isLoading = false;

  formData!: FormData;
  reader!: FileReader;
  fileData!: File;
  fileName!: string;
  currentURL!: string;
  fileValue!: string;
  imgURL!: any;
  hasFile!: boolean;
  public imagePath!: string;

  imgeData!: ImageUpdateModel;

  constructor(
    private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) public imageDetails: ImageUpdateModel,
    public dialogRef: MatDialogRef<SharedImageUploadPopupComponent>,
  ) { }

  ngOnInit(): void {
    this.imgURL = this.imageDetails.currentImageUrl;
  }

  // Upload selected image
  onSubmit() {
    this.formData = new FormData();
    this.formData.append('file', this.fileData);
    this.isLoading = true;

    const contentType = this.fileData.type;
    const fileName = this.fileData.name;
    const fileExtension = fileName.split('.').pop();

    const bucket = new AWS.S3(
      {
        accessKeyId: environment.config.keyId,
        secretAccessKey: environment.config.key,
        region: environment.config.region,
      }
    );

    const params = {
      Bucket: environment.config.location,
      Key: this.imageDetails.s3Location + this.imageDetails.fileName + '.' + fileExtension,
      Body: this.fileData,
      ACL: 'public-read',
      ContentType: contentType,
    };

    bucket.upload( params, (error: any, response: any) => {
      if (error) {
          this.alertService.error('Operation failed!');
          return false;
      }
      this.dialogRef.close(response.Location);
      return true;
    });
  }

  // Browse image and preview.
  fileProgress(event: any) {
    this.reader = new FileReader();

    this.fileName = '';
    this.fileData = event.target.files[0];
    if (this.fileData) {
      this.hasFile = true;
    }
    // this.fileName = event.target.files[0].name;
    this.fileName = '/Items';

    const mimeType = event.target.files[0].type;

    // Check for images only
    if (mimeType.match(/image\/*/) == null) {
      this.alertService.error('File type not supported!');
      return;
    }

    // check for max size
    // const maxFileSize = 1024 * 1024; // 1 MB
    // if (this.fileData.size > maxFileSize) {
    //   this.alertService.error('Max file size is 1MB');
    //   this.hasFile = false;
    //   return;
    // }

    // For iamge preview
    this.imagePath = event.target.files;
    this.reader.readAsDataURL(event.target.files[0]);
    this.reader.onload = (_event) => {
      this.imgURL = this.reader.result;
    };

    this.fileInputRef.nativeElement.value = "";
  }
}
