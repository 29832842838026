<div class="d-flex flex-wrap align-items-center">
    <div class="step-01 status rounded-pill d-flex align-items-center my-2" [class.succes]="isPosted">
        <img class="tic pending" src="../../../../../../assets/icons/tick_pending.svg">
        <img class="tic success" src="../../../../../../assets/icons/tick_success.svg">
        <span class="label text-uppercase font-bold px-1 lh-1">posted</span>
    </div>
    <div class="arrow mx-2 d-flex align-items-center">
        <img class="tic success" src="../../../../../../assets/icons/job/right-arrow.svg">
    </div>
    <div class="step-01 status rounded-pill d-flex align-items-center my-2" [class.succes]="isAccepted">
        <img class="tic pending" src="../../../../../../assets/icons/tick_pending.svg">
        <img class="tic success" src="../../../../../../assets/icons/tick_success.svg">
        <span class="label text-uppercase font-bold px-1 lh-1">accepted</span>
    </div>
    <div class="arrow mx-2 d-flex align-items-center">
        <img class="tic success" src="../../../../../../assets/icons/job/right-arrow.svg">
    </div>
    <div class="step-01 status rounded-pill d-flex align-items-center my-2" [class.succes]="isInProgress">
        <img class="tic pending" src="../../../../../../assets/icons/tick_pending.svg">
        <img class="tic success" src="../../../../../../assets/icons/tick_success.svg">
        <span class="label text-uppercase font-bold px-1 lh-1">in progress</span>
    </div>
    <div class="arrow mx-2 d-flex align-items-center">
        <img class="tic success" src="../../../../../../assets/icons/job/right-arrow.svg">
    </div>
    <div class="step-01 status rounded-pill d-flex align-items-center my-2" [class.succes]="isCompleted">
        <img class="tic pending" src="../../../../../../assets/icons/tick_pending.svg">
        <img class="tic success" src="../../../../../../assets/icons/tick_success.svg">
        <span class="label text-uppercase font-bold px-1 lh-1">Complete</span>
    </div>
    <div class="arrow mx-2 d-flex align-items-center">
        <img class="tic success" src="../../../../../../assets/icons/job/right-arrow.svg">
    </div>
    <div class="step-01 status rounded-pill d-flex align-items-center my-2" [class.succes]="isInvoiceRecived">
        <img class="tic pending" src="../../../../../../assets/icons/tick_pending.svg">
        <img class="tic success" src="../../../../../../assets/icons/tick_success.svg">
        <span class="label text-uppercase font-bold px-1 lh-1">invoice received</span>
    </div>
    <div class="arrow mx-2 d-flex align-items-center">
        <img class="tic success" src="../../../../../../assets/icons/job/right-arrow.svg">
    </div>
    <div class="step-01 status rounded-pill d-flex align-items-center my-2" [class.succes]="isInvoiceIssued">
        <img class="tic pending" src="../../../../../../assets/icons/tick_pending.svg">
        <img class="tic success" src="../../../../../../assets/icons/tick_success.svg">
        <span class="label text-uppercase font-bold px-1 lh-1">invoice issued</span>
    </div>
</div>