<section class="d-flex justify-content-between align-items-center m-0 ps-4 pe-2 py-3">
  <p class="popup-header mb-0 mt-1">Upload Image</p>
  <button mat-icon-button mat-dialog-close><mat-icon>cancel</mat-icon></button>
</section>
<mat-divider></mat-divider>

<section class="popup-wr p-4">

  <div class="row mb-2">
    <div class="col-8 preview-wr">
      <article class="preview w-100 h-100 border d-flex align-items-center" [class.has-img]="!browse.value">

        <div *ngIf="!browse.value" class="place-holder text-center w-100">
          <img class="icon" src="/assets/icons/common/default-icon.svg" alt="">
          <p class="label mt-1 mb-0 font-semi-bold">No Image selected</p>
        </div>

        <image-cropper
          *ngIf="browse.value"
          [imageChangedEvent]="imageChangedEvent"
          [maintainAspectRatio]="true"
          [containWithinAspectRatio]="containWithinAspectRatio"
          [aspectRatio]="1 / 1"
          [cropperMinWidth]="128"
          [onlyScaleDown]="true"
          [roundCropper]="false"
          [canvasRotation]="canvasRotation"
          [transform]="transform"
          [alignImage]="'center'"
          
          format="png"
          (imageCropped)="imageCropped($event)"
          (imageLoaded)="imageLoaded()"
          (cropperReady)="cropperReady($event)"
          (loadImageFailed)="loadImageFailed()">
        </image-cropper>
      </article>
    </div>
    <div class="col-4">
        <div class="final-preview-wr border-bottom pb-3 d-flex flex-column align-items-center">
          <div class="final-preview-img rounded-circle d-flex justify-content-center align-items-center">
            <img *ngIf="!croppedImage" class="icon" src="/assets/icons/common/default-icon.svg" />
            <img *ngIf="croppedImage" class="croppeed-img rounded-circle center-img" [src]="croppedImage"/>
          </div>
          <div class="text-center pt-3">
            <span>Final Result</span>
          </div>
        </div>

        <div class="controls d-flex flex-wrap pt-3">
          <div class="mb-2">
            <button class="btn-control me-2" mat-button (click)="zoomOut()">Zoom -</button>
            <button class="btn-control" mat-button (click)="zoomIn()">Zoom +</button>
          </div>

          <div class="mb-2">
            <button class="btn-control me-2" mat-button (click)="rotateLeft()">Rotate Left</button>
            <button class="btn-control" mat-button (click)="rotateRight()">Rotate Right</button>
          </div>

          <div class="mb-2">
            <button class="btn-control me-2" mat-button (click)="flipHorizontal()">Flip Horizontal</button>
            <button class="btn-control" mat-button (click)="flipVertical()">Flip Vertical</button>
          </div>

          <div>
            <button class="btn-control" mat-button (click)="resetImage()">Reset Image</button>
          </div>
        </div>

    </div>
  </div>

  <div class="d-flex justify-contente-start">

    <input #browse hidden type="file" (change)="fileChangeEvent($event)" accept="image/*"/>

    <a class="link primary large me-2 text-uppercase pointer" (click)="browse.click()">[ Browse File ]</a>
    <span class="me-auto">{{ selectedFileName ? selectedFileName : 'No file choosen'}}</span>
  </div>

  <div class="action-wr d-flex align-items-center justify-content-end">
    <mat-spinner class="me-2" diameter="25" *ngIf="isLoading"></mat-spinner>
    <button class="btn primary w-mid" mat-flat-button (click)="save()">Save</button>
  </div>

</section>