import { Pipe, PipeTransform } from '@angular/core';
import { ServiceCategory } from '../models/service-category/service-category.model';
@Pipe({
  name: 'getCategoryIcon',
})
export class GetCategoryIconPipe implements PipeTransform {
  transform(catId: string, catList: ServiceCategory[]): string {
    const serviceCategory = catList?.find((cat) => cat.id === catId);
    return serviceCategory?.iconUrl
      ? serviceCategory.iconUrl
      : '/assets/images/empty_category.png';
  }
}
