import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-shared-media-preview-popup',
  templateUrl: './shared-media-preview-popup.component.html',
  styleUrls: ['./shared-media-preview-popup.component.scss']
})
export class SharedMediaPreviewPopupComponent implements OnInit {

  imageUrl!: string;
  isLoading!: boolean;

  isVideo = false;

  constructor(
    public dialogRef: MatDialogRef<SharedMediaPreviewPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public mediaUrl: string
  ) { }

  ngOnInit(): void {
    this.imageUrl = this.mediaUrl;

    if (this.isVideoFormat(this.imageUrl)) {
      this.isVideo = true;
    }

    if (this.isImageFormat(this.imageUrl)) {
      this.isVideo = false;
    }
  }

  getExtension(filename: string) {
    var parts = filename.split('.');
    return parts[parts.length - 1];
  }

  isVideoFormat(fileName: string) {
    let ext = this.getExtension(fileName)
    switch (ext.toLowerCase()) {
      case 'avi':
      case 'mp4':
      case 'mov':
        return true;
    }
    return false;
  }

  isImageFormat(fileName: string) {
    let ext = this.getExtension(fileName)
    switch (ext.toLowerCase()) {
      case 'jpg':
      case 'jpeg':
      case 'png':
        return true;
    }
    return false;
  }

  closePopUp(): void {
    const cancle = false;
    this.dialogRef.close(cancle);
  }

}
