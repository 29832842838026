import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ServiceCategory } from '../../models/service-category/service-category.model';

@Injectable({
  providedIn: 'root'
})
export class ServiceCategoryService {

  private endpoint = environment.endpointURL + '/api/ServiceCategory';

  private serviceCategoryList!: ServiceCategory[];
  private observable!: Observable<any>;

  constructor(private http: HttpClient) { }

  getStoredServiceCategories() {
    // If data is available
    if(this.serviceCategoryList) {
      // if `data` is available just return it as `Observable`
      return of(this.serviceCategoryList);
    } else if(this.observable) {
      // if `this.observable` is set then the request is in progress
      // return the `Observable` for the ongoing request
      return this.observable;
    } else {
      // create the request, store the `Observable` for subsequent subscribers
      const url = `${this.endpoint}/All`;
      this.observable = this.http.get<ServiceCategory>(url).pipe(
        map( (response: any ) =>  {
          // when the cached data is available we don't need the `Observable` reference anymore
          this.observable = null!;
          if(response.success) {
            this.serviceCategoryList = response.data;
            return this.serviceCategoryList;
          } else {
            return null;
          }
          // make it shared so more than one subscriber can get the result
          }), share()
        );

      // console.log( 'getStoredServiceCategories' ,this.observable);

      return this.observable;
    }
  }

  public getDocumentsByServiceCategoryIds(data: any): Observable<any> {
    const url = `${this.endpoint}/Document/GetByServiceCategoryIds`;
    return this.http.post<any>(url, data);
  }


}
