import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BusinessInfo } from '../../../models/tradie/tradie.model';
import { AlertService } from '../../../services/alert/alert.service';
import { TradieService } from '../../../services/tradie/tradie.service';

@Component({
  selector: 'app-shared-update-business-email-popup',
  templateUrl: './shared-update-business-email-popup.component.html',
  styleUrls: ['./shared-update-business-email-popup.component.scss']
})
export class SharedUpdateBusinessEmailPopupComponent implements OnInit {

  formUpdateContact!: FormGroup;
  currentMobile!: string
  submitted = false;
  isLoading = false;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<SharedUpdateBusinessEmailPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public  businessInfo: BusinessInfo,
    private alertService: AlertService,
    private tradieService: TradieService,
  ) { }

  ngOnInit(): void {
    const afterHNumber = (this.businessInfo.phoneAfterHours ? this.businessInfo.phoneAfterHours : null)

    this.formUpdateContact = this.formBuilder.group({
      businessInfoId: this.businessInfo.id,
      phoneBusinessHours: [this.businessInfo.phoneBusinessHours, [Validators.pattern('^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$')]],
      phoneAfterHours: [afterHNumber, [Validators.pattern('^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$')]],
      email: [this.businessInfo.email, [Validators.required, Validators.pattern('[A-Z0-9a-z_.%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}')]], 
    });
  }

    // convenience getter for easy access to form fields
    get f() { return this.formUpdateContact.controls; }

    onSubmit() {
      this.submitted = true;
      // If invalid
      if (this.formUpdateContact.invalid) {
        return;
      }
      this.updateBusinessContact()
    }

     // Update user address
     updateBusinessContact() {
      this.isLoading = true;
      this.tradieService.updateBusinessContact(this.formUpdateContact.value).subscribe({
        next: (response: any) => {
          if (response.success) {
            this.alertService.success('Business phone successfully updated');
            const address = response.data;
            this.dialogRef.close(address);
          } else {
            this.alertService.error('Operation failed');
          }
        }, error : err => {
          this.isLoading = false;
          this.alertService.error('Operation failed');
        }
      });
  }
}
