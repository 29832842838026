<div class="popup-wr bg-gradient">

    <button (click)="closePopUp()" class="close-icon" mat-icon-button tabindex="-1">
        <mat-icon>close</mat-icon>
    </button>
  
    <img *ngIf="!isVideo" class="popup-img" [src]="imageUrl">
  
    <video class="pop-up-video" *ngIf="isVideo" controls autoplay>
      <source [src]="imageUrl" id="video_here">
        Your browser does not support HTML5 video.
    </video>
  
    <!-- <div class="popup-overlay d-flex align-items-center justify-content-center">
        <mat-spinner class="spinner" diameter="50"></mat-spinner>
    </div> -->
  
  </div>
  