import { UserFilesModel } from "../components/file-upload/user-files/user-files.model";

export class Document {}
export class DocumentModel {
  id!: string;
  businessInfoId!: string;
  name!: string;
  link!: string;
  userId!: string;
  documentType!: number;
  documentCategory!: number;
  group!: number;
  status!: number;
  expireDate!: string;
  createdDate!: string;
  lastUpdatedDate!: string;
  isArchived!: boolean;
  isVerified!: boolean;
  comment!: string;
}

export interface DocumentUpdateModel {
  hasUserId: boolean;
  isBusinessDocument: boolean;
  isUpdate: boolean;
  document: Document;
  userId: string;
  businessInfoId: string;

  title: string;
  s3Location: string;
  fileName: string;
}

export interface BusinessDocumentCreateModel {
  name: string;
  link: string;
  userId: string;
  businessInfoId?: string;
  expireDate: any;
  documentCategory: number;
  documentType: number;
  group: number;
}

export interface BusinessDocumentUpdateModel {
  id: string;
  name: string;
  link: string;
  userId: string;
  expireDate: any;
  documentCategory: number;
  documentType: number;
  group: number;
}

export interface RequiredBusinessDocumentsModel {
  category: number;
  displayOrder: number;
  group: number;
  id: string;
  isArchived: false;
  name: string;
  serviceCategoryId: string;
}

export interface BusinessDocumentModel {
  businessServiceId: string;
  documentType: number;
  expireDate: string;
  id: string;
  isArchived: false;
  link: string;
  name: string;
  status: number;
}
export interface BusinessDocumentAddModel {
  businessServiceId: string;
  name: string;
  link: string;
  documentCategory: number;
  documentType: number;
  group: number;
  expireDate: any;
}

export class TradieUpdateLogo {
  businessInfoId!: string;
  logoUrl!: string;
}

///

export class DocumentCreateModel {
  name!: string;
  link!: string;
  userId!: string;
  expireDate!: any;
  documentCategory!: number;
  documentType!: number;
  group!: number;
}

export class DocumentEditModel {
  id!: string;
  name!: string;
  link!: string;
  userId!: string;
  expireDate!: any;
  documentCategory!: number;
  documentType!: number;
  group!: number;
}

export class CustomDocumentModel {
  documentName!: string;
  documentCategory!: number;
  documentGroup!: number;
  hasExpiry!: boolean;
  isBusinessDocument!: boolean;
  isBusinessServiceDocument!: boolean;
  currentUserId!: string;

  document!: DocumentModel;
  businessInfoId!: any;
  businessServiceId!: any;
  fileLocation!: any;

  isOtherDoc!: boolean;
  otherDocExpiry!: string;
  otherDocURL!: string;
}

export enum MainDocumentTypes {
  UserDoc = 1,
  // Health & Safety
  OHSManagementSystemDoc = 2,
  RelevantAccreditationDoc = 3,
  GenericJSADoc = 4,
  StandardOperatingProcedureDoc = 5,
  RiskAssessmentDoc = 6,
  // Idemnity
  IdemnityInsuranceDoc = 7,
  IdemnityInsuranceCurrencyDoc = 8,
  // SWMS docs
  SWMS = 9,
}


export class BusinessServiceDocumentModel {
  id?: string;
  name!: string;
  link!: string;
  documentCategory!: number;
  documentType!: number;
  group!: number;
  expireDate!: any;
}

export interface BusinessDocument {
  id:               string;
  name:             string;
  link:             string;
  userId:           string;
  businessInfoId:   string;
  documentType:     number;
  documentCategory: number;
  group:            number;
  expireDate:       string;
  createdDate:      string;
  lastUpdatedDate:  string;
  isArchived:       boolean;
  status:           number;
}

export class SwmsDocument {
  id!:         string;
  userId!:         string;
  businessInfoId!: string;
  questionId!:     string;
  link!:           string;
  expireDate!:     string;
  documentStatus!: number;
  isChecked!:      boolean;
}
export interface SwmsListModel {
  userId:         string;
  businessInfoId: string;
  questionId:     string;
  link:           string;
  isChecked:      boolean;
}
