import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SwmsFilesService {

  private endpoint = environment.endpointURL + '/api/Tradie';

  constructor(private http: HttpClient) { }

  public createSWMSDocument(data: any): Observable<any> {
    const url = `${this.endpoint}/SWMSDocumentNew`;
    return this.http.post<any>(url, data);
  }

  public updateSWMSDocument(data: any): Observable<any> {
    const url = `${this.endpoint}/SWMSDocumentNew`;
    return this.http.put<any>(url, data);
  }

  public getSWMSDocument(businessInfoId: string): Observable<any> {
    const url = `${this.endpoint}/SWMSDocumentNew?businessInfoId=${businessInfoId}`;
    return this.http.get<any>(url);
  }

  public deleteSWMSDocument(id: string): Observable<any> {
    const url = `${this.endpoint}/SWMSDocumentNew?id=${id}`;
    return this.http.delete<any>(url);
  }

  public deleteSWMSDocumentAll(id: string): Observable<any> {
    const url = `${this.endpoint}/SWMSDocumentDeleteAll?headerId=${id}`;
    return this.http.delete<any>(url);
  }
}
