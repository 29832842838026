export class AditionalPreferences {}

export interface AditionalPreferencesQuestion {
  id: string;
  question: string;
  order: string;
  isArchived: boolean;
  answers: AditionalPreferencesAnswer[];
}

export interface AditionalPreferencesAnswer {
  id: string;
  questionId: string;
  answer: string;
  order: string;
  isArchived: boolean;
}

export class CreateAnswerModel {
  additionalPreferences: Preference[] = [];
}

export class UpdateAnswerModel {
  id!: string;
  userId!: string;
  businessInfoId!: string;
  questionId!: string;
  answerId!: string;
  value!: string;
}

export interface AdditionalPreference {
  id?: string;
  userId: string;
  businessInfoId: string;
  questionId: string;
  answerId: string;
}
export interface Preference {
  userId: string;
  businessInfoId: string;
  questionId: string;
  answerId: string;
}
