<div class="d-flex justify-content-between align-items-center m-0 ps-4 pe-2 py-3">
  <p class="popup-header mb-0 font-bold">Update Business Name</p>
  <button mat-icon-button>
      <mat-icon matDialogClose>cancel</mat-icon>
  </button>
</div>

<mat-divider></mat-divider>

<div class="popup-wr p-4">
  <form class="row mt-0" [formGroup]="formUpdateBusinessName" (ngSubmit)="onSubmit()">
      <div class="col-12">
        <mat-form-field class="w-100" appearance="legacy">
            <mat-label>Business Name</mat-label>
            <input formControlName="businessName" matInput placeholder="Please type" required>
            <mat-error *ngIf="f['businessName'].errors?.['required']">
              Please enter business name.
            </mat-error>
        </mat-form-field>
      </div>

      <div class="col-12 action-btns text-right w-100 mt-3">
          <div class="d-flex align-items-center justify-content-end">
              <button class="btn primary shadow-none" mat-raised-button [disabled]="isLoading">Update</button>
              <mat-spinner class="ms-2" diameter="25" *ngIf="isLoading"></mat-spinner>
          </div>
      </div>
  </form>
</div>









