import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { RefreshTokenModel, TokenModel, TokenResponseModel } from 'src/app/modules/account/shared/models/account.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private endpoint = environment.endpointURL;
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  tokenInfo: any;

    constructor(
        private http: HttpClient,
        private router: Router
    ) {
      this.currentUserSubject = new BehaviorSubject<any>(
        JSON.parse(localStorage.getItem('FIX_TRADES_TOKEN') || '{}')
      );
      this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): TokenResponseModel {
        return this.currentUserSubject.value;
    }

    // Login
    public getToken(data: TokenModel): Observable<TokenModel> {
      const url = `${this.endpoint}/api/Token`;
      return this.http.post<TokenModel>(url, data)
        .pipe(map((response: any) => {
          this.storeToken(response.data);
          return response;
        }));
    }

    public getTokenByRefreshToken(data: RefreshTokenModel): Observable<RefreshTokenModel>{
      const url = `${this.endpoint}/api/Token/Refresh`;
      return this.http.post<RefreshTokenModel>(url, data)
          .pipe(map((response: any) => {
            this.storeToken(response.data);
            return response;
          }));
    }

    // Remove current user from local storage
    logout() {
        localStorage.removeItem('FIX_TRADES_TOKEN'); 
        localStorage.removeItem('FIX_TRADE_ROLE'); 
        localStorage.removeItem('FIX_IS_TRADE_ONBOARD'); 
        this.currentUserSubject.next(null);
        this.router.navigate(['/account/login']);
    }

    storeToken(data: any) {
      localStorage.setItem('FIX_TRADES_TOKEN', JSON.stringify(data));
      this.currentUserSubject.next(data);
    }
}