export class Payment {}

export class CreateBusinessCreditCard {
  userId!: string;
  businessInfoId!: string;
  cardHolderName!: string;
  expireDate!: string;
  stripeCardId!: string;
  lastDigits!: number;
  brand!: string;
  isVerified!: boolean;
}

export class CreateCardModel {
  userId!: string;
  cardHolderName!: string;
  expireDate!: string;
  stripeCardId!: string;
  lastDigits!: number;
  brand!: string;
  isVerified!: boolean;
}

export class CreateCardResponseModel {
  data!: CreateCardModel;
  success!: boolean;
  error!: any;
}
//----------

// Get Cards
export class CardModel {
  id!: string;
  userId!: string;
  cardHolderName!: string;
  expireDate!: string;
  isArchived!: boolean;
  isDefault!: boolean;
  isVerified!: boolean;
  stripeCardId!: string;
  lastDigits!: number;
  brand!: string;
}

export class Paging {
  data!: CardModel[];
  totalRecords!: number;
}

export class CardResponseModel {
  data!: Paging;
  success!: boolean;
  error!: any;
}
//----------

//Update Card
export class UpdateDefultCardModel {
  cardId!: string;
  isDefault!: boolean;
}

// Update exp Date
export class PatchExpDateModel {
  id!: string;
  expireDate!: boolean;
}

// Stripe Card Data - Payment Methods
export interface StripeCardData {
  brand: string;
  checks: Checks;
  country: string;
  exp_month: number;
  exp_year: number;
  funding: string;
  generated_from: null;
  last4: string;
  networks: Networks;
  three_d_secure_usage: ThreeDSecureUsage;
  wallet: null;
}

export interface Checks {
  address_line1_check: null;
  address_postal_code_check: null;
  cvc_check: null;
}

export interface Networks {
  available: string[];
  preferred: null;
}

export interface ThreeDSecureUsage {
  supported: boolean;
}
