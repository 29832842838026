<section class="document-inner-wr">
    <div *ngIf="serviceDocument" class="document d-flex align-items-start py-3">
        <img class="icon mt-1" src="/assets/icons/common/document.svg">
        <div class="ms-2">
            <div class="title mb-1 d-flex align-items-center">
                <span class="font-bold lh-1 me-2">{{serviceDocument.name}}</span>
                <span>
                    <app-shared-document-status-widget [currentStatus]="serviceDocument.status"></app-shared-document-status-widget>
                </span>
            </div>
            <div class="d-flex align-items-center">
                <span class="lbl-txt lh-1 me-2">Updated on {{serviceDocument.lastUpdatedDate | date:"dd-MM-yyyy"}}</span>
                <span *ngIf="serviceDocument?.expireDate" class="lbl-txt lh-1 ps-2 border-start">Expires on {{serviceDocument.expireDate | date:"dd-MM-yyyy"}}</span>
                <span class="d-flex align-items-center" *ngIf="serviceDocument.status === documentStatus.Expired">
                    <img class="warning me-1" src="/assets/icons/warning.svg"/>
                    <span class="lbl-txt lh-1 me-2">Expired</span>
                </span>
            </div>
            <div *ngIf="serviceDocument.status == documentStatus.Rejected" class="d-flex align-items-center mt-1">
                <span class="lbl-txt text-black lh-1 me-2">Reject Reason: {{serviceDocument.comment}}</span>
            </div>
        </div>

        <div class="actions d-flex align-items-center ms-auto">
            <span class="action-btn me-2">
                <button mat-icon-button (click)="downloadDocument(serviceDocument.link, serviceDocument.name)" class="ketab-btn d-flex justify-content-center align-items-center">
                    <mat-icon>file_download</mat-icon>
                </button>
            </span>
            <span *ngIf="serviceDocument.status !== documentStatus.Exempt && serviceDocument.status !== documentStatus.Verified" >
            <span class="action-btn">
                <button mat-icon-button [matMenuTriggerFor]="menu" class="ketab-btn d-flex justify-content-center align-items-center">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="updateDocument()"><span>Update</span></button>
                        <button mat-menu-item (click)="removeDocumentConfirm()"><span>Remove</span></button>
                </mat-menu>
            </span>
            </span>
        </div>
    </div>

    <div *ngIf="isLoading" class="inner-preloader d-flex align-items-center justify-content-center">
        <mat-spinner diameter="35"></mat-spinner>
    </div>
</section>