import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PopupData } from '../../../models/common/common.model';

@Component({
  selector: 'app-shared-confirmation-popup',
  templateUrl: './shared-confirmation-popup.component.html',
  styleUrls: ['./shared-confirmation-popup.component.scss']
})
export class SharedConfirmationPopupComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<SharedConfirmationPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PopupData
  ) { }

  ngOnInit(): void {}

  closePopUp() : void {
    const cancel = false;
    this.dialogRef.close(cancel);
  }

  confirm() : void {
    const cancel = true;
    this.dialogRef.close(cancel);
  }
}

