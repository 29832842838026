import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {  FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as AWS from 'aws-sdk';
import { AuthService } from 'src/app/core/auth/auth.service';
import { DocumentCategory, DocumentCategoryType, DocumentGroup } from 'src/app/core/enums/document.enum';
import { BusinessServiceDocumentModel, CustomDocumentModel } from 'src/app/modules/shared/models/document.model';
import { AlertService } from 'src/app/modules/shared/services/alert/alert.service';
import { UploadService } from 'src/app/modules/shared/services/upload/upload.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-shared-category-document-creation-widget',
  templateUrl: './shared-category-document-creation-widget.component.html',
  styleUrls: ['./shared-category-document-creation-widget.component.scss']
})
export class SharedCategoryDocumentCreationWidgetComponent implements OnInit{

  @Input() documentIndex!: number;
  @Input() serviceCategoryId!: string;
  @Output() createDocumentEvent = new EventEmitter<any>();
  @Output() deleteDocEvent = new EventEmitter<any>();
  @Output() onDocStatusChangeEvent = new EventEmitter<boolean>();
  @ViewChild('fileInput') fileInput!: ElementRef
  
  currentUserId!: string;
  curuntDocumentId: any;
  docType: any;

  enableDelete = false;
  isDocUploaded = false;
  isEmptyValues = true;

  formServiceDocument!: FormGroup;
  submitted = false;
  isLoading = false;

  formData!: FormData;
  reader!: FileReader;
  fileData!: File;
  fileName!: string;
  currentURL!: string;
  imgURL: any;
  public imagePath!: string;
  minExpireData = new Date();
  currentDate: any = new Date();

  serviceDocumentData = new BusinessServiceDocumentModel();

  documentType = DocumentCategoryType;
  documentCategory = DocumentCategory;
  documentGroup = DocumentGroup;
  documentData = new CustomDocumentModel()
  isValid = false

  constructor(
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private authService: AuthService,
    private uploadService: UploadService
  ) {}
 
  ngOnInit(): void {
    this.currentUserId = this.authService.currentUserValue.userId
    this.formServiceDocument = this.formBuilder.group({
      name: [ '', Validators.required],
      link: ['', Validators.required],
      documentType: [''],
      expireDate: [''],
      group: [''],
      documentCategory: [''],
    });
    this.onFormStatusChnages()
    this.onformValueChnages()
  }

  // Upload selected image
  onSubmit(data : any) {
    this.submitted = true;
    this.documentData = data;
    if (this.formServiceDocument.invalid) {
      this.isLoading = false;
      return;
    } else{
      this.isLoading = true;
    }

    this.uploadDocToS3()
  }

  // Browse file/document and preview.
  fileProgress(event: any) {
    this.reader = new FileReader();
    this.fileName = '';
    this.fileData = event.target.files[0];
    this.fileName = event.target.files[0].name;
    this.formServiceDocument.patchValue({
      link: this.fileName,
    });
    const fileExtension = this.fileData.name.split('.').pop();
    this.docType = this.getFileExtention(fileExtension); // set file extention
    this.formServiceDocument.controls['documentType'].setValue(this.docType);
    // For iamge preview
    this.imagePath = event.target.files;
    this.reader.readAsDataURL(event.target.files[0]);
    this.reader.onload = (_event) => {
      this.imgURL = this.reader.result;
    };
  }

  uploadDocToS3() {
    this.isLoading = true

    this.formData = new FormData();
    this.formData.append('file', this.fileData);

    const fileName = this.getFileNameByCategory(
      this.formServiceDocument.value.name
    );

    const contentType = this.fileData.type;
    const fileExtension = this.fileData.name.split('.').pop();

    const bucket = new AWS.S3({
      accessKeyId: environment.config.keyId,
      secretAccessKey: environment.config.key,
      region: environment.config.region,
    });

    const params = {
      Bucket: environment.config.location,
      Key: `User/${this.currentUserId}/Documents/` + fileName + '.' + fileExtension,
      Body: this.fileData,
      ACL: 'private',
      ContentType: contentType,
    };

    bucket.upload(params, (error: any, response: any) => {
      if (error) {
        this.alertService.error('Operation failed!');
        return false;
      }
      this.onAWSUploadComplete(response.Key)
      this.formServiceDocument.patchValue({
        link: response.Key,
      });
      return true;
    });
  }

  onAWSUploadComplete(key: string) {
    this.isLoading = false
    this.enableDelete = true
    this.isDocUploaded = true
    const docType = this.getFileExtention(key.split('.').pop())
    const document = {
      index: this.documentIndex,
      name: this.formServiceDocument.value.name,
      link: key,
      documentCategory: this.documentCategory.Other,
      documentType: docType,
      group: this.documentGroup.NotSet,
      expireDate: this.formServiceDocument.value.expireDate
    }
    this.createDocumentEvent.emit(document);
    this.disableForm()
  }

  // Delete business service document
  deleteDocument(curuntDocumentId: any) {
    // this.deleteBusinessDocument(curuntDocumentId)
    const s3key = this.formServiceDocument.getRawValue().link
    let response = this.uploadService.deleteFileByS3KEY(s3key)
    if (response) {
      const doc = {index: this.documentIndex, id: curuntDocumentId, key: s3key}
      this.deleteDocEvent.emit(doc);
    }
  }

  // convenience getter
  get f() { return this.formServiceDocument.controls; }


  // Generate File Name
  getFileNameByCategory(name: string) {
    const timePrefix = this.datePipe.transform(new Date(), 'yyyyMMddhhmmss');
    return name ? name.split(' ').join('_').toLocaleLowerCase() + '_' + timePrefix : '';
  }

  // --- SET FILE / DOCUMENT TYPE --- //
  getFileExtention(fileExtension: any) {
    let extention;
    switch ( fileExtension.toLowerCase() ) {
      case 'pdf': 
        extention = this.documentType.PDF 
        break;
      case 'doc': 
        extention = this.documentType.DOC 
        break;
      case 'docx': 
        extention = this.documentType.DOC 
        break;
      case 'png': 
        extention = this.documentType.PNG 
        break;
      case 'jpeg': 
        extention = this.documentType.JPEG 
        break;
      case 'jpg': 
        extention = this.documentType.JPEG 
        break;
      default:
        break;
    }
    return extention
  }

  convertDate(date: string) {
    return date ? this.datePipe.transform( date,'yyyy-MM-dd' ) : null;
  }

  disableForm() {
    this.formServiceDocument.controls['name'].disable()
    this.formServiceDocument.controls['expireDate'].disable()
    this.formServiceDocument.controls['link'].disable()
  }

  onformValueChnages() {
    this.formServiceDocument.valueChanges.subscribe( value => {
      const hasValue = (value.name || value.link || value.group)
      this.isEmptyValues = (hasValue === undefined)
    })
  }

  onFormStatusChnages() {
    this.formServiceDocument.statusChanges.subscribe( status => {
      this.isValid = (status === 'VALID')
      const isComplete = this.isEmptyValues ? true :  (this.isValid && this.isDocUploaded)
      this.onDocStatusChangeEvent.emit(isComplete)
    })
  }

  resetForm() {
    this.formServiceDocument.controls['name'].setValue('')
    this.formServiceDocument.controls['expireDate'].setValue('')
    this.formServiceDocument.controls['link'].setValue('')
    this.fileInput.nativeElement.value = ""
    this.isEmptyValues = true
    this.onDocStatusChangeEvent.emit(this.isEmptyValues)
  }

  preventDefault(event: any): void {
    if (event.key === 'Space') {
      event.stopPropagation();
    }
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }
}
