import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'setChecked'
})
export class SetCheckedPipe implements PipeTransform {

  transform(ansId: string, ansList: any[]): boolean {
    // console.count('setChecked')
    let isChecked = false
    if (ansList.length) {
      isChecked  = ansList.some( e => e.answerId == ansId)
    }
    return isChecked
  }

}
