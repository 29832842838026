import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatCardNumber'
})
export class FormatCardNumberPipe implements PipeTransform {

  transform(lastDigit: any, type:string, ...args: any[]): any {
    
    var FixedLastDigit;

    if(type=='short'){
      if (lastDigit.toString().length == 1) {
        FixedLastDigit = "**** 000"+lastDigit;
      }
      else if (lastDigit.toString().length == 2) {
        FixedLastDigit = "**** 00"+lastDigit;
      }
      else if (lastDigit.toString().length == 3) {
        FixedLastDigit = "**** 0"+lastDigit;
    }
      else{
        FixedLastDigit = "**** "+lastDigit;
      }  
    }
    else if(type=='long'){
        if (lastDigit.toString().length == 1) {
          FixedLastDigit = "**** **** **** 000"+lastDigit;
        }
        else if (lastDigit.toString().length == 2) {
          FixedLastDigit = "**** **** **** 00"+lastDigit;
        }
        else if (lastDigit.toString().length == 3) {
          FixedLastDigit = "**** **** **** 0"+lastDigit;
      }
        else{
          FixedLastDigit = "**** **** **** "+lastDigit;
        }  
    }
    return FixedLastDigit;
  }

}
