import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ServiceCategoryService } from 'src/app/modules/onboarding/shared/services/service-category/service-category.service';
import { RequiredBusinessDocumentsModel } from 'src/app/modules/shared/models/document.model';

@Component({
  selector: 'app-shared-business-document-info-popup',
  templateUrl: './shared-business-document-info-popup.component.html',
  styleUrls: ['./shared-business-document-info-popup.component.scss']
})
export class SharedBusinessDocumentInfoPopupComponent implements OnInit {

  isLoading = false
  hasData = false

  requiredDocs: RequiredBusinessDocumentsModel[] = []

  constructor(
    public dialogRef: MatDialogRef<SharedBusinessDocumentInfoPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public businessServiceId: string,
    private serviceCategoryService : ServiceCategoryService
  ) { }

  ngOnInit(): void {
    const ids = { serviceCategoryIds: [ this.businessServiceId ] }
    this.getDocumentsByServiceCategoryIds(ids)
  }

  private getDocumentsByServiceCategoryIds(ids: any) {
    this.isLoading = true
    this.serviceCategoryService.getDocumentsByServiceCategoryIds(ids).subscribe( res => {
      if(res.success) {
        this.hasData = true
        this.requiredDocs = res.data.data
      } 
      this.isLoading = false
    }, err => {
      this.isLoading = false
    })
  }

}
