import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-shared-ratings-widget',
  templateUrl: './shared-ratings-widget.component.html',
  styleUrls: ['./shared-ratings-widget.component.scss']
})
export class SharedRatingsWidgetComponent implements OnInit {

  @Input() currentRate: number = 0;
  @Input() isLarge: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
