import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Bank } from '../../../models/bank/bank.model';
import { AlertService } from '../../../services/alert/alert.service';
import { BankService } from '../../../services/bank/bank.service';

@Component({
  selector: 'app-shared-update-bank-popup',
  templateUrl: './shared-update-bank-popup.component.html',
  styleUrls: ['./shared-update-bank-popup.component.scss']
})
export class SharedUpdateBankPopupComponent implements OnInit {

  formBankAccount!: FormGroup;
  submitted = false;
  isLoading = false;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<SharedUpdateBankPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public bankData: {isUpdate: boolean, data: Bank, userId: string},
    private alertService: AlertService,
    private bankService: BankService,
  ) { }

  ngOnInit(): void {
    this.formBankAccount = this.formBuilder.group({
      bank:  ['', Validators.required],
      bsbNumber:  ['', [Validators.required, Validators.pattern('^[0-9]{6}$')]],
      accountName:  ['', [Validators.required]],
      accountNumber:  ['', [Validators.required, Validators.pattern('^[0-9]{6,9}$')]]
    });

    if (this.bankData.isUpdate) {
      this.formBankAccount.addControl('id', new FormControl(this.bankData.data.id));
      this.setUpdateData(this.bankData.data)
    } else {
      this.formBankAccount.addControl('userId', new FormControl(this.bankData.userId));
    }
  }

  get f() { return this.formBankAccount.controls; }

  onSubmit() {
    this.submitted = true;
    this.isLoading = true;

    // If invalid
    if (this.formBankAccount.invalid) {
      this.isLoading = false;
      return;
    }

    if (this.bankData.isUpdate) {
      this.updatebankAccount(this.formBankAccount.value);
    } else {
      this.addbankAccount(this.formBankAccount.value);
    }
  }

  private addbankAccount(data: any) {
    this.bankService.createBankAccount(data).subscribe( (response: any) => {
      if (response.success) {
        this.alertService.success('Bank account added successfully');
        this.dialogRef.close(response.data);
      } else {
        this.alertService.error(response.error.message);
      }
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
      this.alertService.error('Operation failed');
    });
  }

  private updatebankAccount(data: any) {
    this.bankService.updateBankAccount(data).subscribe( (response: any) => {
      if (response.success) {
        this.alertService.success('Bank account updated successfully');
        this.dialogRef.close(response.data);
      } else {
        this.alertService.error(response.error.message);
      }
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
      this.alertService.error('Operation failed');
    });
  }

  setUpdateData(data: Bank) {
    this.formBankAccount.controls['bank'].setValue(data.bank)
    this.formBankAccount.controls['bsbNumber'].setValue(data.bsbNumber)
    this.formBankAccount.controls['accountName'].setValue(data.accountName)
    this.formBankAccount.controls['accountNumber'].setValue(data.accountNumber)
  }
}
