<div class="d-flex justify-content-between align-items-center m-0 ps-4 pe-2 py-3">
  <p class="popup-header mb-0">Update Email Address</p>
  <button mat-icon-button>
      <mat-icon matDialogClose>cancel</mat-icon>
  </button>
</div>

<mat-divider></mat-divider>

<div class="popup-wr p-4">
  <form class="row mt-0" [formGroup]="formUpdateEmailAddress" (ngSubmit)="onSubmit()">
      <div class="col-12">
          <mat-form-field class="w-100" appearance="legacy">
              <mat-label>Email Address</mat-label>
              <input matInput placeholder="Email address" formControlName="email">
              <mat-error *ngIf="f['email'].errors?.['pattern'] && !f['email'].errors?.['required']">
                  Please enter a valid email address.
              </mat-error>
              <mat-error *ngIf="f['email'].errors?.['required']">
                  Please enter email address. 
              </mat-error>
          </mat-form-field>
      </div>

      <div class="col-12 action-btns text-right w-100 mt-3">
          <div class="d-flex align-items-center justify-content-end">
              <button class="btn primary shadow-none" mat-raised-button [disabled]="isLoading">Update</button>
              <mat-spinner class="ms-2" diameter="25" *ngIf="isLoading"></mat-spinner>
          </div>
      </div>
  </form>
</div>