<p class="mb-2 font-semi-bold">Please add a service category and upload all relevant documents.</p>
<p class="mb-2 form-section-info text-secondary mb-2">Note: If you do not see your category listed please contact customer support.</p>
<form class="row mt-0" [formGroup]="formBusinessService" (ngSubmit)="onSubmit()">
  <div class="col-12 mb-2">
    <p class="mb-0">Service category</p>
    <app-shared-select-category-widget
      [serviceCategoriesList]="serviceCategoriesList"
      [currentCategory]="selectedCategory"
      [isViewOnly]="categoryCreated"
      (categoryEvent)="serviceCategoryOnSelect($event)">
    </app-shared-select-category-widget>

    <div class="rate-selection-wr row">
      <div class="col-12">
        <p class="form-sub-title mb-2 text-uppercase">Business Hours <span class="optional-span">(optional)</span></p>
      </div>

      <div class="col-6 mb-2">
        <p class="hourly-rate ">Call out rate (first hour of work)</p>
          <mat-form-field class="w-100" appearance="legacy">
              <input type="number" formControlName="businessHourCallOutFee" matInput placeholder="Please type">
              <mat-error *ngIf="f['businessHourCallOutFee'].errors?.['pattern']">
                Please enter a valid amount.
              </mat-error>
          </mat-form-field>
      </div>

      <div class="col-6 mb-2">
        <p class="hourly-rate ">Hourly rate</p>
          <mat-form-field class="w-100" appearance="legacy">
              <input type="number" formControlName="businessHourHourlyRate" matInput placeholder="Please type">
              <mat-error *ngIf="f['businessHourHourlyRate'].errors?.['pattern']">
                Please enter a valid amount.
              </mat-error>
          </mat-form-field>
      </div>
  
      <div class="col-12">
        <p class="form-sub-title mb-0 text-uppercase">After Hours <span class="optional-span">(optional)</span></p>
      </div>

      <div class="col-6 mb-2">
        <p class="hourly-rate " >Call out rate (first hour of work)</p>
          <mat-form-field class="w-100" appearance="legacy">
              <input type="number" formControlName="afterHourCallOutFee" matInput placeholder="Please type">
              <mat-error *ngIf="f['afterHourCallOutFee'].errors?.['pattern']">
                Please enter a valid amount.
              </mat-error>
          </mat-form-field>
      </div>

      <div class="col-6 mb-2">
        <p class="hourly-rate ">Hourly rate</p>
          <mat-form-field class="w-100" appearance="legacy">
              <input type="number" formControlName="afterHourHourlyRate" matInput placeholder="Please type">
              <mat-error *ngIf="f['afterHourHourlyRate'].errors?.['pattern']">
                Please enter a valid amount.
              </mat-error>
          </mat-form-field>
      </div>
  
      <div class="col-lg-12 mb-2">
        <mat-checkbox class="checkbox" formControlName="quotationOnly" appearance="standard">Receive only quote requests</mat-checkbox>
      </div>
  
      <div *ngIf="!categoryCreated" class="col-12 action-btns text-right w-100 mt-1 mb-3">
          <div class="d-flex align-items-center justify-content-end">
              <button class="btn primary shadow-none px-5" mat-raised-button [disabled]="isLoading || !hasService">Save & Next</button>
              <mat-spinner class="ms-2" diameter="25" *ngIf="isLoading"></mat-spinner> 
          </div>
      </div>
    </div>
  </div>
</form>