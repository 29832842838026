<div class="uploads d-flex align-items-center justify-content-between mb-0">
  <span class="uploads-txt text-md text-uppercase font-bold text-secondary">Uploads</span>
  <img (click)="viewDocumentInfo()" class="me-auto ms-1 pointer" src="/assets/icons/common/info.svg">
  <button 
      mat-flat-button 
      class="btn primary w-mid" 
      (click)="uploadDocument()">Please Upload
  </button>
</div>

<div class="documents-list">
  <div class="document-wr" *ngFor="let document of serviceDocumentList; index as i" [ngClass]="{'border-bottom': serviceDocumentList.length !== i+1}">
    <app-shared-service-document-widget 
        (updateDocumentEvent)="updateDocumentEvent($event)" 
        (removeDocumentEvent)="removeDocumentEvent($event)" 
        [serviceDocument]="document">
    </app-shared-service-document-widget>
  </div>

  <div *ngIf="serviceDocumentList?.length === 0" class="p-3">
    <p class="m-0 text-bold text-center text-secondary">Please add documents.</p>
  </div>
</div>