<div class="document-inner-wr">
    <div *ngIf="customDocuments.length>0" class="mb-1 pt-1">
        <section  *ngFor="let customDocument of customDocuments" class="document d-flex align-items-center px-3 py-2 mb-1 pt-1 rounded">
            <img class="icon mt-1" src="/assets/icons/common/document.svg">
            <div class="ms-2">
                <div class="title mb-1 d-flex align-items-center">
                    <span class="font-bold lh-1 me-2 d-flex align-items-center"> 
                        <span *ngIf="customDocument?.documentCategory === this.documentCategory.OtherProofOfIdentity">Other - </span>
                        <span>{{customDocument?.name}}</span>
                        <span *ngIf="!hideStatus" class="ms-2">
                            <app-shared-document-status-widget [currentStatus]="customDocument?.status"></app-shared-document-status-widget>
                        </span>
                    </span>
                </div>
                <div class="d-flex align-items-center mt-1">
                    <span class="lbl-txt lh-1 me-2">Updated on {{customDocument?.lastUpdatedDate | date:"dd-MM-yyyy"}}</span>
        
                    <span *ngIf="customDocument?.expireDate" class="lbl-txt lh-1 me-2 ps-2 border-start">Expires on {{customDocument?.expireDate | date:"dd-MM-yyyy"}}</span>
                </div>
                <div *ngIf="customDocument.status == documentStatus.Rejected" class="d-flex align-items-center mt-1">
                    <span class="lbl-txt text-black lh-1 me-2">Reject Reason: {{customDocument.comment}}</span>
                </div>
            </div>
            <div class="actions d-flex align-items-center ms-auto">
                <span class="action-btn me-2">
                    <button type="button" mat-icon-button (click)="downloadDocument(customDocument.link, customDocument.name)" class="ketab-btn d-flex justify-content-center align-items-center">
                        <mat-icon>file_download</mat-icon>
                    </button>
                </span>
                
                <span *ngIf="customDocument.status !== documentStatus.Exempt && customDocument.status !== documentStatus.Verified" >
                <span class="action-btn">
                    <button type="button" mat-icon-button [matMenuTriggerFor]="menu" class="ketab-btn d-flex justify-content-center align-items-center">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button type="button" mat-menu-item (click)="updateDocumentPopup(customDocument)"><span>Update</span></button>
                        <button type="button" mat-menu-item (click)="deleteDocumentConfirmation(customDocument)"><span>Remove</span></button>
                    </mat-menu>
                </span>
                </span>
            </div>
          </section>
    </div>
    
  
    <section 
      class="file-upload-wr d-flex align-items-center justify-content-center py-3 custom-border dashed" 
      (click)="createUpdateDocumentPopup()">
      <img class="upload-icon" src="/assets/icons/common/cloud-upload.svg">
      <span class="text-color primary font-semi-bold ms-2"> {{ customDocuments.length == 0 ? 'Please upload' : 'Upload Another' }}</span>
    </section>
  
    <div *ngIf="isLoading" class="inner-preloader d-flex align-items-start justify-content-center">
          <mat-progress-bar mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>
  </div>