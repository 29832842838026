import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from '../../../services/alert/alert.service';
import { UserService } from '../../../services/user/user.service';

import {
  parsePhoneNumber,
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  validatePhoneNumberLength,
} from 'libphonenumber-js';
import { VerificationService } from '../../../services/verification/verification.service';
import { CodeInputComponent } from 'angular-code-input';
@Component({
  selector: 'app-shared-update-phone-popup',
  templateUrl: './shared-update-phone-popup.component.html',
  styleUrls: ['./shared-update-phone-popup.component.scss'],
})
export class SharedUpdatePhonePopupComponent implements OnInit {
  formUpdatePhone!: FormGroup;
  submitted = false;
  isLoading = false;
  isInvalid = true;
  isVerificationSent = false;
  isUpdatingNumber = false;
  
  countryCode = '+61 (Australia)';
  currentMobileNumber!: any;
  currentCode!: any;
  isCodeInvalid = false;
  isCodeCompleted = false;
  // adding to the page props
  @ViewChild('codeInput') codeInput !: CodeInputComponent;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<SharedUpdatePhonePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public tradeInfo: { mobileNumber: string; id: string },
    private alertService: AlertService,
    private userService: UserService,
    private verificationService: VerificationService
  ) {}

  ngOnInit(): void {
    this.formUpdatePhone = this.formBuilder.group({
      id: this.tradeInfo.id,
      mobileNumber: ['', [Validators.required, Validators.pattern('[0-9]{9}')]],
    });

    this.formUpdatePhone.statusChanges.subscribe((state) => {
      this.isInvalid = state === 'INVALID';
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.formUpdatePhone.controls;
  }

  onMobileNumberKeyupEvent() {
    const number = this.formUpdatePhone.value.mobileNumber;
    if (number.startsWith('+')) {
      if (number.length >= 12) this.formatNumber(number);
    } else {
      this.formatNumber(number);
    }
  }

  // Format phone number
  formatNumber(number: string) {
    let phoneNumber = parsePhoneNumber(number, 'AU')!;
    phoneNumber.format('RFC3966');
    if (phoneNumber.country === 'AU') {
      this.countryCode = `+61 (Australia)`;
    } else {
      this.countryCode = `+${phoneNumber.countryCallingCode} (${phoneNumber.country})`;
    }
    this.currentMobileNumber = phoneNumber.number;
    this.formUpdatePhone.controls['mobileNumber'].setValue(
      phoneNumber.nationalNumber
    );
  }

  onCodeChanged(code: any) {}
  onCodeCompleted(code: any) {
    this.currentCode = code
    this.isCodeCompleted = true
    this.isCodeInvalid = false
  }

  verify() {
    const mobileData = {
      mobileNumber: this.currentMobileNumber,
    };
    this.sendVerificationCode(mobileData)
  }

  private sendVerificationCode(data: any) {
    this.verificationService.sendVerificationCode(data).subscribe({
      next: (res) => {
        if(res.success) {
          this.isVerificationSent = res.data
          this.alertService.success('Verification code sent successfully')
          this.formUpdatePhone.controls['mobileNumber'].disable()
        } else {
          this.alertService.error(res.error.message);
          this.formUpdatePhone.controls['mobileNumber'].setValue('')
        }
      }, error: (err) => {
        this.alertService.error('Operation failed')
      },
    });
  }

  onSubmit() {
    this.submitted = true;
    // If invalid
    if (this.formUpdatePhone.invalid) {
      return;
    }
    const verifyData = {
      mobileNumber: this.currentMobileNumber,
      code: this.currentCode
    };
    this.verifyCode(verifyData)
  }

  private verifyCode(data: any) {
    this.isLoading = true
    this.verificationService.phoneVerification(data).subscribe({
      next: (res) => {
        if(res.success) {
          this.isCodeInvalid = false
          this.alertService.success('Mobile number verified successfully')
          this.updateMobileNo(this.tradeInfo.id, this.currentMobileNumber);
        } else {
          this.alertService.error('Operation failed')
          this.resetCode()
          this.isLoading = false
        }
      },
      error: (err) => {
        this.alertService.error('Operation failed')
        this.isLoading = false
      },
    });
  }

  resetCode() {
    this.isCodeInvalid = true
    this.isCodeCompleted = false
    this.codeInput.reset();
  }

  private updateMobileNo(userId: string, mobileNumber: string) {
    this.isUpdatingNumber = true
    const number = '%2B' + mobileNumber.replace(/\+/g, '');
    // const number = mobileNumber.replace(/\+/g, '%2B');
    this.userService.updateMobileNumberByUser(userId, number).subscribe({
      next: (res) => {
        if (res.success) {
          this.alertService.success('Mobile number updated successfully');
          this.dialogRef.close(res.data);
        } else {
          this.alertService.error(res.error.message);
          this.dialogRef.close();
        }
        this.isLoading = this.isUpdatingNumber = false;
      }, error: (err) => {
        this.isLoading = this.isUpdatingNumber = false;
        this.alertService.error('Operation failed');
      },
    });
  }
}
