import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SWMSQuestionService {

  private endpoint = environment.endpointURL + '/api/SWMS';

  constructor( private http: HttpClient ) { }

  // --- SWMS --- // 
  public getSWMSQuestionsList(isArchived: boolean): Observable<any> {
    const url = `${this.endpoint}/SWMSQuestion?isArchived=${isArchived}`;
    return this.http.get<any>(url);
  }
}
