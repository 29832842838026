<div class="popup p-5">
  <button class="close-icon" mat-icon-button (click)="cancel()">
    <mat-icon>cancel</mat-icon>
  </button>
  <h3 class="popup-header mb-0 text-uppercase text-center text-lg mb-2">Verify Mobile Number</h3>
  <p class="font-regular text-center">Please enter verification code sent to</p>
  <p class="font-semi-bold text-center">{{selectedMobileNumber | formatMobileNumber}}</p>

  <section class="d-flex align-items-center justify-content-center">
    <code-input 
      #codeInput
      [isCodeHidden]="false"
      [isCharsCode]="false"
      [codeLength]="4"
      (codeChanged)="onCodeChanged($event)"
      (codeCompleted)="onCodeCompleted($event)">
    </code-input>
  </section>

  <div class="my-3 d-flex align-items-center justify-content-center">
    <button class="btn primary w-regular text-uppercase" mat-flat-button [disabled]="isLoading" (click)="onSubmit()">Done</button>
    <mat-spinner *ngIf="isLoading" class="ms-2" diameter="30"></mat-spinner>
  </div>

  <div class="d-flex align-items-center justify-content-center">
    <span>Didn't received?</span>
    <span 
      class="verify-btn text-uppercase link primary font-bold pointer ms-2" 
      (click)="resendCode(selectedMobileNumber)">[ Resend Code ]
    </span>
  </div>
</div>