export class BusinessFiles {
}

export interface BusinessFilesCreateModel {
    name: string;
    link: string;
    userId: string;
    businessInfoId?: string;
    expireDate: any;
    documentCategory: number;
    documentType: number;
    group: number;
}

export interface BusinessFilesUpdateModel {
    id: string;
    name: string;
    link: string;
    userId: string;
    expireDate: any;
    documentCategory: number;
    documentType: number;
    group: number;
}

export interface BusinessFilesModel {
    id: string;
    name: string;
    link: string;
    documentType: number;
    documentCategory: number;
    group: number;
    expireDate: string;
    lastUpdatedDate: string;
    isArchived: boolean;
    status: number;
    comment: string;
}

export class BusinessFilesCustomDocumentModel {
    documentName!: string;
    documentCategory!: number;
    documentGroup!: number;
    hasExpiry!: boolean;
    isBusinessDocument!: boolean;
    isBusinessServiceDocument!: boolean;
    currentUserId!: string;
  
    document!: BusinessFilesModel;
    businessInfoId!: any;
    businessServiceId!: any;
    fileLocation!: any;
  
    isOtherDoc!: boolean;
    otherDocExpiry!: string;
    otherDocURL!: string;
}