import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DocumentCategory, DocumentGroup, DocumentStatus } from 'src/app/core/enums/document.enum';
import { AlertService } from 'src/app/modules/shared/services/alert/alert.service';
import { UploadService } from 'src/app/modules/shared/services/upload/upload.service';
import { SharedConfirmationPopupComponent } from '../../../popups/shared-confirmation-popup/shared-confirmation-popup.component';
import { UserFileUploadPopupComponent } from '../user-file-upload-popup/user-file-upload-popup.component';
import { UserFilesCustomDocumentModel, UserFilesDataModel, UserFilesModel } from '../user-files.model';
import { UserFilesService } from '../user-files.service';

@Component({
  selector: 'app-user-file-list-widget',
  templateUrl: './user-file-list-widget.component.html',
  styleUrls: ['./user-file-list-widget.component.scss']
})
export class UserFileListWidgetComponent implements OnInit {

  @Input() tradeId!: string
  @Input() customDocuments!: UserFilesModel[];
  @Input() currentDocumentCategory!: number;

  customDocumentData = new UserFilesCustomDocumentModel()


  @Input() isLoading: boolean = false;
  @Input() hideStatus: boolean = false;
  @Input() availableDocTypes!: number[];

  @Output() documentChangeEvent = new EventEmitter<UserFilesModel>();
  @Output() documentDeleteEvent = new EventEmitter<UserFilesModel>();
  @Output() otherDocumentChangeEvent = new EventEmitter<UserFilesCustomDocumentModel>();

  documentGroup = DocumentGroup;
  // documentType = DocumentCategoryType;
  documentCategory = DocumentCategory;
  documentStatus = DocumentStatus

  constructor(
    public dialog: MatDialog,
    private userFilesService: UserFilesService,
    private alertService: AlertService,
    private uploadService: UploadService,
  ) { }

  ngOnInit(): void {
  }

  createDocumentPopup() {
    this.setCreateDocumentData(null)
    const dialogRef = this.dialog.open(UserFileUploadPopupComponent, {
      maxWidth: '500px',
      width: '90%',
      position: {
        top: '60px',
      },
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      closeOnNavigation: true,
      disableClose: true,
      data: {documentData: this.customDocumentData, types: this.availableDocTypes},
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.customDocuments.unshift(result)
        this.documentChangeEvent.emit(result)
      }
    });
  }

  updateDocumentPopup(customDocument: UserFilesModel) {
    this.setCreateDocumentData(customDocument) 
    const dialogRef = this.dialog.open( UserFileUploadPopupComponent, {
      maxWidth: '640px',
      width: '50%',
      position : {top: '60px'},
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      closeOnNavigation: true,
      disableClose: true,
      data: {documentData: this.customDocumentData, types: this.availableDocTypes},
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.updateListItem(result)
        this.documentChangeEvent.emit(result)
      }
    });
  }

  deleteDocumentConfirmation(customDocumentData: UserFilesModel) {
    this.setCreateDocumentData(customDocumentData)
    const dialogRef = this.dialog.open(SharedConfirmationPopupComponent, {
      maxWidth: '500px',
      width: '90%',
      position: {
        top: '60px',
      },
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      closeOnNavigation: true,
      disableClose: true,
      data: {
        title: 'Please Confirm',
        message: 'Are you sure you want to delete this document?',
        confirmTxt: 'Yes, Delete',
        cancelTxt: 'Cancel',
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      const isArchived = true
      if (result) {
        this.deleteUserDocument(customDocumentData)
      }
    });
  }
  
  deleteUserDocument(document: UserFilesModel) {
    this.isLoading = true
    const docId = document.id
    this.userFilesService.deleteDocument(docId).subscribe({
      next: (res) => {
        if (res.success) {
          this.alertService.success('Document deleted successfully')
          this.customDocumentData.document = null!
          this.removeFromList(docId)
          this.documentDeleteEvent.emit(document)
        } else {
          this.alertService.error(res.error.message)
        }
        this.isLoading = false
      }, error: (err) => {
        this.isLoading = false
        this.alertService.error('Operation failed')
      },
    });
  }

  
  // Download Document
  downloadDocument(key: string, name: string) {
    const documentUrl = this.uploadService.getUrlByS3KEY(key);
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', documentUrl);
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  removeFromList(id: string) {
    const index = this.customDocuments.findIndex(x => x.id === id);
    if (index > -1) {
      this.customDocuments.splice(index, 1);
    }
  }

  updateListItem(data: UserFilesModel) {
    const index = this.customDocuments.findIndex(x => x.id === data.id);
    this.customDocuments[index] = data
  }

  setCreateDocumentData(document?: any) {
    this.customDocumentData = new UserFilesCustomDocumentModel()
    if (document) {
      this.customDocumentData.documentCategory = document?.documentCategory
      this.customDocumentData.document = document
      this.customDocumentData.documentName = document?.name
    }
    this.customDocumentData.documentGroup = this.documentGroup.Mandatory
    this.customDocumentData.hasExpiry = true
    this.customDocumentData.isBusinessDocument = false
    this.customDocumentData.isBusinessServiceDocument = false
    this.customDocumentData.currentUserId = this.tradeId
  }

}
