<section class="mb-3">
  <h4 class="font-bold">What do we do?</h4>
  <p>
    Simple. We connect Service Providers, like you, with the people who
    need them, via an online marketplace so that Service Providers and
    their Customers can agree what works needs to be done (we’re not a
    part of those agreements).
  </p>
</section>

<section class="mb-4">
  <h4 class="font-bold">By clicking ‘accept’</h4>
  <p>
    By clicking ‘accept’, you become one of our Customers, and agree to
    be bound by the following terms:
  </p>
</section>

<!-- PART I: DEFINITIONS -->
<section ion class="mb-4">
  <h4 class="font-bold">PART I: DEFINITIONS</h4>
  <p>
    1. The following words and expressions in these Terms have the
    corresponding meaning below:
  </p>

  <div class="inner-section">
    <div class="info d-flex align-items-center mb-2">
      <span class="label font-bold">Account</span>
      <span class="desctiption">means your digital account with us;</span>
    </div>
    <div class="info d-flex align-items-center mb-2">
      <span class="label font-bold">App</span>
      <span class="desctiption">means our mobile application;</span>
    </div>
    <div class="info d-flex align-items-center mb-2">
      <span class="label font-bold">Customer</span>
      <span class="desctiption">means those individuals or entities using our
        Platform to find and retain a Service Provider;</span>
    </div>
    <div class="info d-flex align-items-center mb-2">
      <span class="label font-bold">GST</span>
      <span class="desctiption">means goods and services tax as imposed by
        the A New System (Goods and Services Tax)
        Act 1999 (Cth);</span>
    </div>
    <div class="info d-flex align-items-center mb-2">
      <span class="label font-bold">Platform</span>
      <span class="desctiption">means, our Website, our App, or both
        (depending on the context);</span>
    </div>
    <div class="info d-flex align-items-start mb-2">
      <span class="label font-bold">Service</span>
      <div>
        <p class="desctiption mb-0">
          means the online marketplace that we created to connect service providers with:
        </p>
        <p class="mb-2">1. strata managers;</p>
        <p class="mb-2">2. property managers;</p>
        <p class="mb-2">3. property management offices;</p>
        <p class="mb-2">4. tenants;</p>
        <p class="mb-2">5. owners;</p>
        <p class="mb-2">6. consumers;</p>
        <p class="mb-2">
          7. providers of related services (e.g. property service providers and
          tradespersons);
        </p>
      </div>
    </div>
    <div class="info d-flex align-items-center mb-2">
      <span class="label font-bold">Service Providers</span>
      <span class="desctiption">means the tradespersons and other providers
        of services who use the Platform to advertise
        and offer their services;
      </span>
    </div>
    <div class="info d-flex align-items-center mb-2">
      <span class="label font-bold">Review Facility</span>
      <span class="desctiption">means a facility by which customers or
        putative customer of a service can publicly
        rate and comment on a service provider;</span>
    </div>
    <div class="info d-flex align-items-center mb-2">
      <span class="label font-bold">Terms</span>
      <span class="desctiption">means these terms and conditions (including
        any schedules);
      </span>
    </div>
    <div class="info d-flex align-items-center mb-2">
      <span class="label font-bold">User</span>
      <span class="desctiption">means anyone who uses the Service or
        Platform, including Service Providers and
        Customers;
      </span>
    </div>
    <div class="info d-flex align-items-center mb-2">
      <span class="label font-bold">Website</span>
      <span class="desctiption">means: www.huskiservices.com</span>
    </div>
    <p class="info d-flex align-items-center">
      <span class="label font-bold">we, us, our</span>
      <span class="desctiption">refers to Huski Holding Company Pty Ltd
        (ACN 644 036 984) trading as ‘Huski Trades
        and Services’;</span>
    </p>
  </div>
</section>

<!-- PART II: THE SERVICES -->
<section class="mb-3">
  <h4 class="font-bold mb-3">PART II: THE SERVICES</h4>

  <div class="mb-3">
    <h5 class="font-bold mb-2">Scope of these Terms</h5>
    <p class="mb-2">
      2. These Terms govern your use of the Platform and the Service.
    </p>
  </div>

  <div class="mb-3">
    <h5 class="font-bold mb-2">The Platform and the Service</h5>
    <p class="mb-2">
      2. You may only use the Platform in accordance with these Terms.
    </p>
    <p class="mb-2">
      3. You may use the Service to identify opportunities with potential
      Customers.
    </p>
    <p class="mb-2">4. You must not use the Platform:</p>
    <p class="mb-2 ps-4">a. for an unlawful purpose; </p>
    <p class="mb-2 ps-4">b. to commit a fraud or a dishonest act;</p>
    <p class="mb-2 ps-4">c. to engage in any offensive conduct;</p>
    <p class="mb-2 ps-4">
      d. to infringe any person’s intellectual property rights; or
    </p>
    <p class="mb-2 ps-4">
      e. to interfere with the Platform’s operation or security.
    </p>
    <p class="mb-2">
      5. You must keep your Account login details secure and confidential.
    </p>
    <p class="mb-2">
      6. If you become aware of, or suspect, any data breach, you must notify
      us immediately.
    </p>
    <p class="mb-2">
      7. We agree not to restrict or remove your access to the Platform or
      close your Account unless:
    </p>
    <p class="mb-2 ps-4">
      a. you have breached and/or are in breach of these Terms;
    </p>
    <p class="mb-2 ps-4">
      b. we suspect that you have breached and/or are in breach of
      these Terms;
    </p>
    <p class="mb-2 ps-4">
      c. it is necessary, or we suspect it is necessary, to protect the
      integrity of the Platform; or
    </p>
    <p class="mb-2 ps-4">
      d. if we form the view that your conduct is detrimental to the
      operation of our Service or Platform.
    </p>
    <p class="mb-2">
      8. We may, at our absolute discretion, change how the Platform and
      Service operates from time to time and this may affect its appearance,
      functionality, and stored information.
    </p>
    <p class="mb-2">
      9. We do not perform any checks on the information provided by any
      User, including information about themselves.
    </p>
    <p class="mb-2">
      10. You are responsible for making your own inquiries and conducting
      any due diligence about any user, including a Customer.
    </p>
    <p class="mb-2">
      11. You must not use the Platform or Service as a Service Provider
      unless you have all necessary licences, permits and authorisations to
      provide the services that you agree to provide, or may agree to
      provide, to a Customer.
    </p>
    <p class="mb-2">
      12. You are not our agent and you are not authorised to enter into any
      contracts on our behalf or make any representations on our behalf.
    </p>
    <p class="mb-2">
      13. You acknowledge that if you enter a contract with a Customer, we are
      not a party to that contract.
    </p>
    <p class="mb-2">
      14. When you post information on the Platform for potential Customers to
      respond to, you warrant that the information contained in the posting
      is accurate, not misleading or deceptive, not defamatory, not
      offensive, does not infringe any person’s intellectual property rights, is
      not discriminatory and otherwise complies with all applicable laws.
    </p>
    <p class="mb-2">
      15. You are responsible for obtaining and maintaining insurance to cover
      any potential liability you may have in relation to your business and
      your contract with the Customer.
    </p>
    <h5 class="font-bold mb-2">Review Facility</h5>
    <p class="mb-2">
      16. If we include a Review Facility in the Platform, we are not liable for the
      ratings or any other content submitted by Customers in the course of
      using that function, and you acknowledge that the ratings may be
      unfairly unfavourable.
    </p>
  </div>
</section>

<!-- PART III: PAYMENT -->
<section class="mb-3">
  <h4 class="font-bold mb-3">PART III: PAYMENT</h4>
  <h5 class="font-bold mb-2">Fees & payments</h5>
  <p class="mb-2">
    17. You are not liable to pay fees. Fees are paid by your employer.
  </p>
</section>

<!-- PART IV: INFORMATION -->
<section class="mb-3">
  <h4 class="font-bold mb-3">PART IV: INFORMATION</h4>
  <h5 class="font-bold mb-2">Advertisements</h5>
  <p class="mb-2">
    18. We do not adopt or endorse in any way any of the representations in
    the advertisements published on the Platform or make any
    representation about the goods or services advertised.
  </p>
  <h5 class="font-bold mb-2">Links to third party content</h5>
  <p class="mb-2">
    19. By posting links to third party content, we do not make any
    representations as to the risk of using the link and you agree that you
    use the link at your own risk.
  </p>
  <h5 class="font-bold mb-2">Editing information</h5>
  <p class="mb-2">
    20. We may vary, edit or remove, at our absolute discretion, any
    information on, including content uploaded to, the Platform, at any
    time and for any reason, including where we suspect the information
    may breach these Terms.
  </p>
  <h5 class="font-bold mb-2">Login credentials</h5>
  <p class="mb-2">
    21. You must keep your password confidential, even from us.
  </p>
  <p class="mb-2">
    22. If you suspect that there has been an unauthorised use of the Service
    using your Account, or using your Account password, you must
    change your password immediately and contact us immediately.
  </p>
  <p class="mb-2">
    23. You agree to indemnify us for any loss or damage we suffer that is
    caused by an unauthorised use of the Services via your Account.
  </p>
  <h5 class="font-bold mb-2">GPS Data</h5>
  <p class="mb-2">
    24. You agree that we may access, retain, and use the GPS Data that we
    collect from you and your staff.
  </p>
  <p class="mb-2">25. We promise to make reasonable efforts to de-identify the GPS Data,
    except where it would compromise the Service.</p>
  <h5 class="font-bold mb-2">Personal information</h5>
  <p class="mb-2">
    26. You acknowledge that to the extent permitted by law, we may
    disclose the information you provide to us to other users of the
    Service, as part of the Service, even if that information contains
    personal information and information that may identify you.
  </p>
  <h5 class="font-bold mb-2">Intellectual property</h5>
  <p class="mb-2">
    27. The entire contents and design of the Platform, including all
    trademarks, text, images and audio and video files, is proprietary to
    us or our content providers and is protected by copyright laws.
  </p>
  <p class="mb-2">
    28. You agree that all intellectual property and confidential information
    constituting a part of or otherwise that is otherwise connected to the
    Platform and the Service shall remain our property and it shall not be
    communicated, broadcast, reproduced, disseminated, disclosed,
    supplied or otherwise provided in its entirety or in any part to any third
    party without our prior written consent.
  </p>
  <p class="mb-2">
    29. When you provide any information, data or content to the Platform,
    such as a profile or posting or you make available information, data or
    content to the Service, you grant us an irrevocable, perpetual, nonexclusive, royalty-free, worldwide licence to
    use, copy, adapt and sublicense that content for any purpose and you provide all necessary
    consents in relation to moral rights to enable us to do so. You warrant
    that you have the right to provide this licence and consent in relation
    to all such content.
  </p>
  <h5 class="font-bold mb-2">Confidentiality and Privacy</h5>
  <p class="mb-2">
    30. You promise not to disclose or exploit information that you would be
    expected to suspect is confidential unless you have written
    permission from us.
  </p>
  <p class="mb-2">
    31. If you suspect, believe, or know that confidential information has been
    disclosed or exploited without our knowledge or consent, you shall
    inform us of the potential disclosure as soon as possible.
  </p>
  <p class="mb-2">
    32. We may retain, organise and exploit for commercial purposes any
    information you provide.
  </p>
  <p class="mb-2">
    33. We shall own the property in any document even if the information
    therein is sought in the course of obtaining information for you.
  </p>
  <p class="mb-2">
    34. We will only provide information to you which is:
  <p class="mb-2 ps-4">a. publicly available; or</p>
  <p class="mb-2 ps-4">b. available due to the consent of a Contractor.</p>
</section>

<!-- PART V: LIABILITY -->
<section class="mb-3">
  <h4 class="font-bold mb-3">PART V: LIABILITY</h4>

  <div class="mb-2">
    <h5 class="font-bold mb-2">Limitation of liability</h5>
    <p class="mb-2">
      35. Our liability to you for any alleged loss caused by conduct constituting
      a tort shall be limited to $10.
    </p>
    <p class="mb-2">
      36. Our liability to you for any alleged loss caused by negligence shall be
      limited to $10.
    </p>
  </div>

  <div class="mb-2">
    <h5 class="font-bold mb-2">Indemnity</h5>
    <p class="mb-2">
      37. You indemnify us, and our directors, employees, and agents, against
      all loss caused by:
    </p>
    <p class="mb-2 ps-4">a. your breach of:</p>
    <p class="mb-2 ps-5">i. these Terms;</p>
    <p class="mb-2 ps-5">ii. any law;</p>
    <p class="mb-2 ps-5">iii. a person’s intellectual property rights;</p>

    <p class="mb-2 ps-4">
      b. your fraud, negligence, misrepresentation, misleading or
      deceptive conduct;
    </p>
    <p class="mb-2 ps-4">c. your use of the Platform;</p>
    <p class="mb-2 ps-4">
      d. someone else’s use of the Platform via your Account.
    </p>
    <p class="mb-2 ps-4">
      e. your provision to us of incorrect payment information;
    </p>
    <p class="mb-2 ps-4">f. your breach of a contract with a Customer.</p>
  </div>

  <div class="mb-2">
    <h5 class="font-bold mb-2">Disclaimer</h5>
    <p class="mb-2">
      38. To the extent permitted by law and except as set out in these Terms,
      we exclude liability for any claims, losses, damage, cost or expense
      incurred by you in connection to your use of the Platform or the
      Service. We do not warrant that the Platform will be uninterrupted,
      error-free or free from viruses or harmful code.
    </p>
    <p class="mb-2">
      39. To the extent permitted by law we exclude all representations and
      warranties, express or implied, including, but not limited to, those
      relating to fitness for a particular purpose.
    </p>
    <p class="mb-2">
      40. You acknowledge that the Platform is provided "as is" and that we do
      not make any warranty or representation as to the suitability of the
      Platform or Service for any purpose.
    </p>
    <p class="mb-2">
      41. To the extent permitted by law, we will not be liable to you for indirect
      or consequential loss arising from or connected to this agreement in
      contract, negligence or otherwise unless that loss arises as a result of
      our own negligence or wilful misconduct.
    </p>
    <p class="mb-2">
      42. Our liability to you for loss or damage of any kind arising out of this
      agreement, or in connection with it, is reduced to the extent (if any)
      that you cause or contribute to the loss or damage. This reduction
      applies whether our liability is in contract, negligence or otherwise.
    </p>
    <p class="mb-2">
      43. The Service comes with guarantees that cannot be excluded under
      the Australian Consumer Law. You have certain rights under the
      Australian Consumer Law that are not excluded by these Terms. In
      addition to other entitlements, for a major failure you may be entitled
      to a refund or compensation for the reduced value of the Service. For
      a minor failure, we may choose to provide you with a refund or resupply the Service.
    </p>
    <p class="mb-2">
      44. To obtain compensation, you will need to provide sufficient evidence
      of the loss or damage suffered, and sufficient evidence that such loss
      or damage was a consequence of our failure to comply with a
      consumer guarantee under the Australian Consumer Law.
    </p>
    <p class="mb-2">
      45. We have no obligation to you to assist or be involved in any dispute
      between Customers and Service Providers, although we may, at our
      absolute discretion, elect to assist or become involved.
    </p>
    <p class="mb-2">
      46. Our Platform and Service are only for use within Australia.

    </p>
  </div>
</section>

<!-- PART VI: OTHER -->
<section>
  <h4 class="font-bold mb-3">PART VI: OTHER</h4>

  <div class="mb-2">
    <h5 class="font-bold mb-2">Assignments</h5>
    <p class="mb-2">
      47. These Terms, or any rights arising under it, cannot be assigned
      without the signed written confirmation of you and us.
    </p>
  </div>

  <div class="mb-2">
    <h5 class="font-bold mb-2">Force Majeure</h5>
    <p class="mb-2">
      48. We shall not be liable for any failure to comply with a Term of this
      agreement if the failure is caused by an event not within our
      reasonable control.
    </p>
    <p class="mb-2"></p>
  </div>

  <div class="mb-2">
    <h5 class="font-bold mb-2">Non-waiver</h5>
    <p class="mb-2">
      49. Our failure to insist upon compliance with any part of these Terms, or
      delay in exercising any power or right per these Terms, shall not be
      construed as a waiver of that part.
    </p>
  </div>

  <div class="mb-2">
    <h5 class="font-bold mb-2">Severability</h5>
    <p class="mb-2">
      50. If any part of these Terms is invalid or unenforceable, the remainder
      shall be enforceable to the extent permitted by law and shall be
      interpreted as far as possible as was originally intended before the
      severance.
    </p>
  </div>

  <div class="mb-2">
    <h5 class="font-bold mb-2">Governing law</h5>
    <p class="mb-2">
      51. These Terms are governed by the law of the state or territory in
      Australia in which you reside. If you do not reside in a state or territory
      in Australia, these Terms are governed by the laws of Victoria,
      Australia.
    </p>
  </div>

  <div class="mb-2">
    <h5 class="font-bold mb-2">Amendments</h5>
    <p class="mb-2">
      52. These Terms can only be amended if the amendment is in writing and
      signed by you and us.
    </p>
  </div>

  <div class="mb-2">
    <h5 class="font-bold mb-2">Termination</h5>
    <p class="mb-2">
      53. You and we may agree to terminate this agreement in writing.
    </p>
    <p class="mb-2">
      54. Subject to payment of an early termination Fee, you may terminate
      these Terms. The Early Termination Fee shall equal the amount of
      the Subscription Fee that is referable to the period from the Early
      Termination to the last date of the Subscription Period.
    </p>
  </div>

  <div class="mb-2">
    <h5 class="font-bold mb-2">Disputes</h5>
    <p class="mb-2">
      55. All Users are encouraged to resolve disputes (including claims for
      further work, returns or refunds) with other Users. Accordingly, you
      acknowledge and agree that we may, in our absolute discretion,
      provide any User’s information as we decide is suitable to other Users
      involved in any dispute.
    </p>
    <p class="mb-2">
      56. If we provide information about other Users to you for the purposes of
      resolving disputes, you acknowledge and agree that such information
      will be used only for the purpose of resolving the dispute (and no
      other purpose) and that you will be responsible and liable to us for
      any costs, losses or liabilities incurred by us in relation to any claims
      relating to any other use of information not permitted by this
      agreement.
    </p>
    <p class="mb-2">
      57. All Users must continue to perform their obligations during the period
      of any dispute, except to the extent prevented by the nature of the
      dispute.
    </p>
    <p class="mb-2">
      58. All Users must bear their own costs relating to any dispute.
    </p>
  </div>

  <div class="mb-2">
    <h5 class="font-bold mb-2">Entire Agreement</h5>
    <p class="mb-2">
      59. These Terms constitutes the entire agreement between you and Us
    </p>
  </div>
</section>