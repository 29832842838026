<section class="row">
  <mat-form-field class="col-10">
    <input #answerValue matInput (keyup)="onKeyup()" [formControl]="answer">
    <button 
      class="btn primary mb-2" 
      mat-button 
      matSuffix 
      mat-flat-button 
      aria-label="Save" 
      [disabled]="!(hasValue && valueChanged)" 
      (click)="save()">Save
    </button>
  </mat-form-field>
</section>
