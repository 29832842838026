import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import {
  Validators,
  FormBuilder,
  FormGroup,
  FormControl,
} from '@angular/forms';
import { environment } from 'src/environments/environment'; 
import {
  DocumentCategory,
  DocumentCategoryType,
  DocumentGroup,
} from 'src/app/core/enums/document.enum';
import {
  BusinessDocumentAddModel,
  BusinessDocumentCreateModel,
  BusinessDocumentUpdateModel,
  CustomDocumentModel,
  DocumentCreateModel,
  DocumentEditModel,
} from 'src/app/modules/shared/models/document.model';
import * as AWS from 'aws-sdk';
import { AlertService } from '../../../services/alert/alert.service';
import { BusinessDocumentService } from '../../../services/documents/business-document.service';
import { DocumentService } from '../../../services/documents/document.service';
import { TradieService } from 'src/app/modules/onboarding/shared/services/tradie.service';

@Component({
  selector: 'app-shared-document-upload-popup',
  templateUrl: './shared-document-upload-popup.component.html',
  styleUrls: ['./shared-document-upload-popup.component.scss'],
  providers: [DatePipe],
})
export class SharedDocumentUploadPopupComponent implements OnInit {

  formNewdoc!: FormGroup;
  submitted = false;
  isLoading = false;

  formData!: FormData;
  reader!: FileReader;
  fileData!: File;
  fileName!: string;
  currentURL!: string;
  imgURL: any;
  public imagePath!: string;
  minExpireData = new Date();

  userDocumentCreateData!: DocumentCreateModel
  userDocumentUpdateData!: DocumentEditModel

  documentUploadData!: BusinessDocumentUpdateModel;
  businessServiceDocumentData!: BusinessDocumentAddModel;

  businessDocumentUpdateData!: BusinessDocumentUpdateModel;
  businessDocumentCreateData!: BusinessDocumentCreateModel;

  documentGroup = DocumentGroup;
  documentType = DocumentCategoryType;
  documentCategory = DocumentCategory;

  constructor(
    private businessDocumentService: BusinessDocumentService,
    private documentService: DocumentService,
    private tradieService: TradieService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public documentData: CustomDocumentModel,
    public dialogRef: MatDialogRef<SharedDocumentUploadPopupComponent>
  ) {}

  ngOnInit(): void {
    this.formNewdoc = this.formBuilder.group({
      name: [ '', Validators.required],
      link: ['', Validators.required],
      documentType: ['', Validators.required],
      // expireDate: ['', Validators.required],
      // group: ['', Validators.required],
      // documentCategory: ['', Validators.required],
      // userId: ['', Validators.required],
    });

    this.setDocumentUpdateFormData(this.documentData);
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.formNewdoc.controls;
  }

  // Upload selected image
  onSubmit() {
    this.isLoading = true;
    this.submitted = true;
    // If invalid
    if (this.formNewdoc.invalid) {
      this.isLoading = false;
      return;
    }

    if (this.documentData?.document?.link === this.formNewdoc.value.link) {
      this.saveForm(); // Updating & not updating the file
    } else {
      this.uploadDocToS3(); // updating the file
    }
  }


  setDocumentUpdateFormData(document: CustomDocumentModel) {

    if (document?.isOtherDoc) {
      this.formNewdoc.controls['name'].setValue(document?.documentName);
      if (document?.hasExpiry) {
        this.formNewdoc.addControl('expireDate', new FormControl(new Date(this.documentData?.otherDocExpiry)) );
      }
    } else {
      this.formNewdoc.addControl('group', new FormControl(document?.documentGroup) );
      this.formNewdoc.addControl('documentCategory', new FormControl(document?.documentCategory) );
      this.formNewdoc.controls['name'].setValue(document?.documentName ? document?.documentName : '');

      if (document?.hasExpiry) {
        this.formNewdoc.addControl('expireDate', new FormControl() );
      }
  
      if (this.documentData?.document?.id) { // Is Update
        
        this.formNewdoc.addControl('id', new FormControl(this.documentData.document.id) );
        this.formNewdoc.controls['link'].setValue(this.documentData?.document.link);

        if (document?.hasExpiry) {
          console.log('add expire date');
          this.formNewdoc.controls['expireDate'].setValue(new Date(this.documentData?.document.expireDate));
        }
        this.setFileExtention(this.documentData.document.link.split('.').pop())
      }
  
      if (this.documentData?.isBusinessDocument) { // Is business document
        this.formNewdoc.addControl('businessInfoId', new FormControl(this.documentData.businessInfoId) );
      } 
  
      if (this.documentData?.isBusinessServiceDocument) { // Is business service(service category) document
        this.formNewdoc.addControl('businessServiceId', new FormControl(this.documentData.businessServiceId) );
      } else {
        this.formNewdoc.addControl('userId', new FormControl(document?.currentUserId) );
      }
    }
  }
  

  uploadDocToS3() {
    this.formData = new FormData();
    this.formData.append('file', this.fileData);

    const fileName = this.getFileNameByCategory(
      this.formNewdoc.value.name
    );
    const contentType = this.fileData.type;
    const fileExtension = this.fileData.name.split('.').pop();

    const bucket = new AWS.S3({
      accessKeyId: environment.config.keyId,
      secretAccessKey: environment.config.key,
      region: environment.config.region,
    });

    const params = {
      Bucket: environment.config.location,
      Key: this.documentData.fileLocation + fileName + '.' + fileExtension,
      Body: this.fileData,
      ACL: 'private',
      ContentType: contentType,
    };

    bucket.upload(params, (error: any, response: any) => {
      if (error) {
        this.alertService.error('Operation failed!');
        return false;
      }

      this.formNewdoc.patchValue({
        // link: response.Location,
        link: response.Key,
      });
      if(this.documentData.isOtherDoc) {
        this.documentData.otherDocURL = response.Key
        this.documentData.otherDocExpiry =  this.formNewdoc.value.expireDate
        this.dialogRef.close(this.documentData);
      } else {
        this.saveForm();
      }
      return true;
    });
  }

  saveForm() {
    // console.log('saveForm');
    if (this.documentData?.currentUserId) {

      if (this.documentData.isBusinessServiceDocument) {
        if (this.documentData?.document?.id) {
          this.updateBusinessServiceDocument(this.formNewdoc.getRawValue());
        } else {
          this.createBusinessServiceDocument(this.formNewdoc.getRawValue());
        }
      } else if (this.documentData.isBusinessDocument) {
        // console.log('isBusinessDocument');
        if (this.documentData?.document?.id) {
          this.updateBusinessDocument(this.formNewdoc.getRawValue());
        } else {
          this.createBusinessDocument(this.formNewdoc.getRawValue());
        }
      } else {
        if (this.documentData?.document?.id) {
          this.updateUserDocument(this.formNewdoc.getRawValue());
        } else {
          this.createUserDocument(this.formNewdoc.getRawValue());
        }
      }
    } else {
      this.dialogRef.close(false);
    }
  }

  // Browse file/document and preview.
  fileProgress(event: any) {
    this.reader = new FileReader();

    this.fileName = '';
    this.fileData = event.target.files[0];
    this.fileName = event.target.files[0].name;

    this.formNewdoc.patchValue({
      link: this.fileName,
    });

    const fileExtension = this.fileData.name.split('.').pop();

    this.setFileExtention(fileExtension); // set file extention

    const mimeType = event.target.files[0].type;
    const maxFileSize = 1024 * 1024;

    // Check for images only
    // if (mimeType.match(/image\/*/) == null) {
    //   this.alertService.error('File type not supported!');
    //   return;
    // }

    // check for max size
    // if (this.fileData.size > maxFileSize) {
    //   this.alertService.error('Max file size is 1MB');
    //   return;
    // }

    // For iamge preview
    this.imagePath = event.target.files;
    this.reader.readAsDataURL(event.target.files[0]);
    this.reader.onload = (_event) => {
      this.imgURL = this.reader.result;
    };
  }

  // Create business document
  private createBusinessDocument(data: BusinessDocumentCreateModel) {
    this.businessDocumentCreateData = data;
    this.businessDocumentCreateData.expireDate = this.datePipe.transform(
      data.expireDate,
      'yyyy-MM-dd'
    ); // Convert Date

    this.isLoading = true;
    this.businessDocumentService
      .createBusinessDocumentById(this.businessDocumentCreateData)
      .subscribe({
        next: (response: any) => {
          if (response.success) {
            this.alertService.success('Document created successfully');
            this.dialogRef.close(response.data);
          } else {
            this.alertService.error(response.error.message);
          }
          this.isLoading = false;
        },
        error: (error: any) => {
          this.alertService.error('Operation failed!');
        },
      });
  }

  // Edit business document
  private updateBusinessDocument(data: BusinessDocumentUpdateModel) {
    this.documentUploadData = data;
    this.documentUploadData.expireDate = this.datePipe.transform(
      data.expireDate, 'yyyy-MM-dd'
    ); // Convert Date

    this.isLoading = true;
    this.businessDocumentService
      .updateBusinessDocument(this.documentUploadData)
      .subscribe({
        next: (response: any) => {
          if (response.success) {
            this.alertService.success('Document updated successfully');
            this.dialogRef.close(response.data);
          } else {
            this.alertService.error(response.error.message);
          }
          this.isLoading = false;
        },
        error: (error: any) => {
          this.alertService.error('Operation failed!');
        },
      });
  }

  // Create business service document
  private createBusinessServiceDocument(data: BusinessDocumentAddModel) {
    this.businessServiceDocumentData = data;
    this.businessServiceDocumentData.expireDate = this.datePipe.transform(
      data.expireDate,
      'yyyy-MM-dd'
    ); // Convert Date

    this.isLoading = true;
    this.tradieService
      .addBusinessServiceDocument(this.businessServiceDocumentData)
      .subscribe({
        next: (response: any) => {
          if (response.success) {
            this.alertService.success('Document created successfully');
            this.dialogRef.close(response.data);
          } else {
            this.alertService.error(response.error.message);
          }
          this.isLoading = false;
        },
        error: (error: any) => {
          this.alertService.error('Operation failed!');
        },
      });
  }

  // Edit business service document
  private updateBusinessServiceDocument(data: BusinessDocumentUpdateModel) {
    this.businessDocumentUpdateData = data;
    this.businessDocumentUpdateData.expireDate = this.datePipe.transform(
      data.expireDate,
      'yyyy-MM-dd'
    ); // Convert Date

    this.isLoading = true;
    this.tradieService
      .updateBusinessServiceDocument(this.businessDocumentUpdateData)
      .subscribe({
        next: (response: any) => {
          if (response.success) {
            this.alertService.success('Document updated successfully');
            this.dialogRef.close(response.data);
          } else {
            this.alertService.error(response.error.message);
          }
          this.isLoading = false;
        },
        error: (error: any) => {
          this.isLoading = false;
          this.alertService.error('Operation failed!');
        },
      });
  }

  // Create user document
  private createUserDocument(data: DocumentCreateModel) {
    this.userDocumentCreateData = data;
    this.userDocumentCreateData.expireDate = this.datePipe.transform(
      data.expireDate,
      'yyyy-MM-dd'
    ); // Convert Date

    this.isLoading = true;
    this.documentService
      .createDocumentById(this.userDocumentCreateData)
      .subscribe({
        next: (response: any) => {
          if (response.success) {
            this.alertService.success('Document created successfully');
            this.dialogRef.close(response.data);
          } else {
            this.alertService.error(response.error.message);
          }
          this.isLoading = false;
        },
        error: (error: any) => {
          this.isLoading = false;
          this.alertService.error('Operation failed!');
        },
      });
  }

  // Edit user document
  private updateUserDocument(data: DocumentEditModel) {
    this.userDocumentUpdateData = data;
    this.userDocumentUpdateData.expireDate = this.datePipe.transform(
      data.expireDate,
      'yyyy-MM-dd'
    ); // Convert Date

    this.isLoading = true;
    this.documentService
      .updateDocument(this.userDocumentUpdateData)
      .subscribe({
        next: (response: any) => {
          if (response.success) {
            this.alertService.success('Document updated successfully');
            this.dialogRef.close(response.data);
          } else {
            this.alertService.error(response.error.message);
          }
          this.isLoading = false;
        },
        error: (error: any) => {
          this.isLoading = false;
          this.alertService.error('Operation failed!');
        },
      });
  }

  // Generate File Name
  getFileNameByCategory(name: string) {
    const timePrefix = this.datePipe.transform(new Date(), 'yyyyMMddhhmmss');
    return name ? name.split(' ').join('_').toLocaleLowerCase() + '_' + timePrefix : '';
  }

  // Set documentType form value
  setFileExtention(fileExtension: any) {
    switch (
      fileExtension // - SET FILE / DOCUMENT TYPE
    ) {
      case 'pdf':
        this.formNewdoc.controls['documentType'].setValue(
          this.documentType.PDF
        );
        break;
      case 'doc':
        this.formNewdoc.controls['documentType'].setValue(
          this.documentType.DOC
        );
        break;
      case 'docx':
        this.formNewdoc.controls['documentType'].setValue(
          this.documentType.DOC
        );
        break;
      case 'png':
        this.formNewdoc.controls['documentType'].setValue(
          this.documentType.PNG
        );
        break;
      case 'jpeg':
        this.formNewdoc.controls['documentType'].setValue(
          this.documentType.JPEG
        );
        break;
      case 'jpg':
        this.formNewdoc.controls['documentType'].setValue(
          this.documentType.JPEG
        );
        break;
      default:
        break;
    }
  }
}
