<div class="me-0">
  <span *ngIf="labelOnly" class="status font-bold">{{curuntStatus}}</span>
</div>

<section *ngIf="!labelOnly"  class="status-wr d-flex justify-content-end w-100">
  
  <div class="status d-flex flex-column justify-content-center px-2 py-1 ms-auto">
    <span class="font-semi-bold text-secondary" *ngIf="generalComplianceType === gComplianceType.Insurances">Insurance Status</span>
    <span class="font-semi-bold text-secondary" *ngIf="generalComplianceType === gComplianceType.HealthSafety">Health & Safety Status</span>
  
    <span [ngSwitch]="currentComplianceStatusData?.status">
      <span class="status-text font-bold lh-1" *ngSwitchCase="complianceStatus.PendingApproval">Pending Approval</span>
      <span class="status-text font-bold lh-1" *ngSwitchCase="complianceStatus.ActionRequired">Action Required</span>
      <span class="status-text font-bold lh-1" *ngSwitchCase="complianceStatus.Alert">Alert</span>
      <span class="status-text font-bold lh-1" *ngSwitchCase="complianceStatus.Approved">Approved</span>
      <span class="status-text font-bold lh-1" *ngSwitchCase="complianceStatus.Restricted">Restricted</span>
      <span class="status-text font-bold lh-1" *ngSwitchCase="complianceStatus.Expired">Expired</span>
      <span class="status-text font-bold lh-1" *ngSwitchCase="complianceStatus.Exempt">Exempt</span>
      <span *ngSwitchDefault>--</span>
    </span>
  </div>

</section>