<div class="select-category-widget">
  <div 
      #catSelecter 
      *ngIf="serviceCategoriesList.length" 
      class="cat-select-wr d-flex justify-content-between align-items-center pointer py-2 border-bottom px-2"
      (click)="showSerchBox();"
      [ngClass]="{'view-only': isViewOnly}"
    >
    <img *ngIf="selectedCategory?.iconUrl" class="object-fit me-2" [src]="selectedCategory?.iconUrl" width="30" height="30">
    <span class="me-auto font-semi-bold">{{selectedCategory?.name ? selectedCategory?.name : 'Please select your service category'}}</span>
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#888888"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M7 10l5 5 5-5H7z"/></svg>
  </div>

  <!-- <div [ngClass]="{'show': isSearchVisible}" class="autocomplete-wr shadow rounded"> -->
  <div *ngIf="isSearchVisible" class="autocomplete-wr shadow rounded">
    <div class="cat-search-wr d-flex py-2">
      <input
        class="cat-search w-100"
        matInput
        #categorySelect
        aria-label="Service Category"
        placeholder="Search service category"
        [matAutocomplete]="auto"
        [formControl]="categoryCtrl"
      >
      <button class="auto-close" mat-button *ngIf="categorySelect.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearAutocomplete()">
        <mat-icon>close</mat-icon>
      </button>
      <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="onServiceCategorySelect($event); categorySelect.value='';"
        [displayWith]="displayFn"
        autoActiveFirstOption="true"
        >
        <mat-option *ngFor="let category of filteredCategories | async" [value]="category">
          <div class="d-flex align-items-center">
            <img *ngIf="category.iconUrl" class="object-fit me-2" aria-hidden [src]="category.iconUrl" height="25" width="25">
            <span class="autocomplete-txt">{{category.name}}</span>
          </div>
        </mat-option>
        <mat-option class="w-100 text-center text-secondary" *ngIf="noResult && categorySelect.value">
          No results
        </mat-option>
      </mat-autocomplete>
    </div>
  <!-- <div class="p-3 text-center bg-light" *ngIf="noResult && categorySelect.value">
    <span class="text-secondary">No results</span>
  </div> -->
</div>
<div class="trick-space" *ngIf="!isSearchVisible"></div>
