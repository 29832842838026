import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdditionalPreferencesQuestionType } from 'src/app/core/enums/main.enum';
import { SavedAnswerModel } from 'src/app/modules/onboarding/shared/models/aditional-preferences/aditional-preferences.model';
import { CreateAnswerModel, UpdateAnswerModel } from '../../../models/aditional-preferences/aditional-preferences.model';
import { AlertService } from '../../../services/alert/alert.service';
import { TradieService } from '../../../services/tradie/tradie.service';
@Component({
  selector: 'app-shared-update-preferences-popup',
  templateUrl: './shared-update-preferences-popup.component.html',
  styleUrls: ['./shared-update-preferences-popup.component.scss']
})
export class SharedUpdatePreferencesPopupComponent implements OnInit {

  isLoading = false
  isCompleted = true
  isFirstAnswerYes = true
  createAnswerModel!: CreateAnswerModel
  updateAnswerModel!: UpdateAnswerModel
  completedAnswers: any[] = []
  currentQuestionList: any[] = []
  currentIndex!: number
  questionType = AdditionalPreferencesQuestionType
  
  constructor(
    private tradieService: TradieService,
    private alertService: AlertService,
    public dialogRef: MatDialogRef<SharedUpdatePreferencesPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public preferrencesData: {businessInfoId: string, tradeId: string, list: any[], set: number},
  ) { }

  ngOnInit(): void {
    this.checkFistQuestionsAnswer()
  }

  radioAnswerOnChange(currrentAnswer: any, previouseAnswersList: SavedAnswerModel[]) {
    this.currentIndex = this.currentQuestionList?.findIndex(q => q.question.id === currrentAnswer?.questionId)
    if (previouseAnswersList?.length) {
      this.updateAnswer(currrentAnswer, previouseAnswersList[0])
    } else {
      this.createAnswer(currrentAnswer)
    }
  }

  checkBoxAnswerOnChange(isChecked: boolean, currrentAnswer: any, previouseAnswersList: SavedAnswerModel[]) {
    this.currentIndex = this.currentQuestionList?.findIndex(q => q.question.id === currrentAnswer?.questionId)
    if (isChecked) {
      this.createAnswer(currrentAnswer)
    } else {
      if (this.currentQuestionList[this.currentIndex]?.answers?.length > 1) {
        let deleteAnsId = previouseAnswersList?.find(e => e.answerId == currrentAnswer.id)?.id!
        this.removeAditionalPreferenceAnswer(deleteAnsId)
      } else {
        this.alertService.error('There should be at least one answer')
      }
    }
  }

  onTextBoxValueChange(answer: any) {
    this.currentIndex = this.currentQuestionList?.findIndex(q => q.question.id === answer?.questionId)
    if  (answer.id) {
      this.updateAnswerModel = new UpdateAnswerModel()
      this.updateAnswerModel.id = answer.id
      this.updateAnswerModel.userId = this.preferrencesData.tradeId
      this.updateAnswerModel.businessInfoId = this.preferrencesData.businessInfoId
      this.updateAnswerModel.questionId = answer.questionId
      this.updateAnswerModel.value = answer.value
      this.updateAditionalPreferenceAnswer(this.updateAnswerModel)
    } else {
      this.createAnswer(answer)
    }
  }

  // --- Add Answer --- //
  createAnswer(answer: any) {
    this.createAnswerModel = new CreateAnswerModel()
    let currentAns = {
      userId: this.preferrencesData.tradeId,
      businessInfoId: this.preferrencesData.businessInfoId,
      questionId: answer.questionId,
      answerId: answer.id,
      value: answer.value
    }
    this.createAnswerModel.additionalPreferences.push(currentAns)
    this.createAditionalPreferenceAnswer(this.createAnswerModel)
  }
  
  private createAditionalPreferenceAnswer(data: CreateAnswerModel) {
    this.isLoading = true
    this.tradieService.createAditionalPreference(data).subscribe({
      next: res => {
        if (res.success) {
          this.setAnswer(res.data[0])
          this.alertService.success('You have answered successfully')
        } else {
          this.alertService.error(res.error.message)
        }
        this.isLoading = false
      },
      error: err => {
        this.isLoading = false
        this.alertService.error('Operation failed')
      }
    })
  }

  private removeAditionalPreferenceAnswer(answerId: string) {
    this.tradieService.deleteAditionalPreference(answerId).subscribe({
      next: res => {
        if (res.success) {
          this.removeAnswer(answerId)
          this.alertService.success('Your answer updated successfully')
        } else {
          this.alertService.error(res.error.message)
        }
        this.isLoading = false
      }, error: err => {
        this.isLoading = false
        this.alertService.error('Operation failed')
      }
    })
  }

  updateAnswer(answerData: any, recentAnswer: any) {
    this.updateAnswerModel = new UpdateAnswerModel()
    this.updateAnswerModel.id = recentAnswer.id
    this.updateAnswerModel.userId = this.preferrencesData.tradeId
    this.updateAnswerModel.businessInfoId = this.preferrencesData.businessInfoId
    this.updateAnswerModel.questionId = recentAnswer.questionId
    this.updateAnswerModel.answerId = answerData.id
    this.updateAditionalPreferenceAnswer(this.updateAnswerModel)
  }

  private updateAditionalPreferenceAnswer(data: UpdateAnswerModel) {
    this.isLoading = true
    this.tradieService.updateAditionalPreference(data).subscribe({
      next: res => {
        if (res.success) {
          this.setAnswer(res.data)
          this.alertService.success('Your answer updated successfully')
        } else {
          this.alertService.error(res.error.message)
        }
        this.isLoading = false
      },
      error: err => {
        this.isLoading = false
        this.alertService.error('Operation failed')
      }
    })
  }

  // Set updated ot newly added answer
  setAnswer(answer: any) {
    const currentQuestionType = this.currentQuestionList[this.currentIndex]?.question?.quectionType
    if (currentQuestionType == this.questionType.TextBox) {
      this.currentQuestionList[this.currentIndex].answers[0].value = answer.value
    } else if (currentQuestionType == this.questionType.RadioButton) {
      this.currentQuestionList[this.currentIndex].answers = []
      this.currentQuestionList[this.currentIndex]?.answers.push(answer)
    } else {
      this.currentQuestionList[this.currentIndex]?.answers.push(answer)
    }
    this.checkFistQuestionsAnswer()
  }

  
  // Remove selected answer of question (checkbox type)
  removeAnswer(answerId: string) {
    let latestAnswersList = []
    latestAnswersList = this.currentQuestionList[this.currentIndex]?.answers
    const index = latestAnswersList.findIndex( (x: { id: any; }) => x.id == answerId );
    if (index > -1) {
      latestAnswersList.splice(index, 1); // 2nd parameter means remove one item only
    }
    this.currentQuestionList[this.currentIndex].answers = latestAnswersList
    this.createCompletedArray()
  }

  
  checkFistQuestionsAnswer() {
    // If answer of first question is 'Yes' display second question
    const questionOne = this.preferrencesData.list.find(e => e.order == 1)
    const quiestionOneAnswer = questionOne?.question?.answers?.find( (ans: any) => ans.id == questionOne?.answers[0]?.answerId)?.answer 
    this.isFirstAnswerYes = (quiestionOneAnswer == 'Yes') 
    this.currentQuestionList = this.preferrencesData.list
    this.createCompletedArray() 
  }

  createCompletedArray() {
    this.completedAnswers = []
    this.currentQuestionList.forEach( e => {
      // first questions answer is "No", exclude 2nd questions answer
      if( e.order != '2' ) {
        this.completedAnswers.push(e.answer)
      } else if ( (e.order == '2') && this.isFirstAnswerYes ) {
        this.completedAnswers.push(e.answer)
      }
    })
    this.currentQuestionList.sort((n1,n2) => Number(n1.order) -  Number(n2.order))
    this.checkIsAllQuestionsHaveAnswers()
  }

  checkIsAllQuestionsHaveAnswers() {
    const hasUndifinedAns = this.completedAnswers.includes(undefined)
    this.isCompleted = !hasUndifinedAns
  }

  closePopup() {
    this.dialogRef.close(true)
  }
}
