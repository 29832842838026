import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatMobileNumber'
})
export class FormatMobileNumberPipe implements PipeTransform {
  transform(mobileNo: string): string {
    let newStr = '';
    let i = 0;

    if (mobileNo) {
      for (; i < Math.floor(mobileNo.length / 3) - 1; i++) {
        newStr = newStr + mobileNo.substr(i * 3, 3) + ' ';
      }
      return newStr + mobileNo.substr(i * 3);
    } else {
      return 'Empty';
    }
  }
}