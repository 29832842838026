import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DocumentCategory, DocumentCategoryType, DocumentGroup, DocumentStatus } from 'src/app/core/enums/document.enum';
import { CustomDocumentModel, DocumentModel, MainDocumentTypes } from '../../../models/document.model';
import { AlertService } from '../../../services/alert/alert.service';
import { DocumentService } from '../../../services/documents/document.service';
import { UploadService } from '../../../services/upload/upload.service';
import { SharedConfirmationPopupComponent } from '../../popups/shared-confirmation-popup/shared-confirmation-popup.component';
import { SharedTeamDocumentUploadPopupComponent } from '../../popups/shared-team-document-upload-popup/shared-team-document-upload-popup.component';

@Component({
  selector: 'app-shared-team-user-document-widget',
  templateUrl: './shared-team-user-document-widget.component.html',
  styleUrls: ['./shared-team-user-document-widget.component.scss']
})
export class SharedTeamUserDocumentWidgetComponent implements OnInit {

  @Input() tradeId!: string
  @Input() isLoading: boolean = false;
  @Input() hideStatus: boolean = false;
  @Input() hideExpireDate: boolean = false;
  @Input() customDocument!: DocumentModel;
  @Input() mainDocumentType!: MainDocumentTypes;
  @Input() currentDocumentCategory!: number;

  @Output() documentChangeEvent = new EventEmitter<DocumentModel>();
  @Output() otherDocumentChangeEvent = new EventEmitter<CustomDocumentModel>();
  
  customDocumentData = new CustomDocumentModel()

  docTypes = MainDocumentTypes
  
  documentGroup = DocumentGroup;
  documentType = DocumentCategoryType;
  documentCategory = DocumentCategory;
  documentStatus = DocumentStatus

  constructor(
    public dialog: MatDialog,
    private documentService: DocumentService,
    private alertService: AlertService,
    private uploadService: UploadService,
  ) { }

  ngOnInit(): void {
  }

  createUpdateDocumentPopup() {
    this.setCreateDocumentData();
    const dialogRef = this.dialog.open(SharedTeamDocumentUploadPopupComponent, {
      maxWidth: '500px',
      width: '90%',
      position: {top: '60px'},
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      closeOnNavigation: true,
      disableClose: true,
      data: {isUpdate: false, documentData: this.customDocumentData, hideExpireDate: this.hideExpireDate}, //data: this.customDocumentData,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        if(this.customDocumentData.isOtherDoc) {
          this.customDocumentData = result
          this.otherDocumentChangeEvent.emit(this.customDocumentData)
        } else {
          this.customDocumentData.document = result
          this.documentChangeEvent.emit(this.customDocumentData.document)
        }
      }
    });
  }

  updateDocumentPopup() {
    this.setCreateDocumentData() 
    const dialogRef = this.dialog.open( SharedTeamDocumentUploadPopupComponent, {
      maxWidth: '640px',
      width: '50%',
      position : {top: '60px'},
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      closeOnNavigation: true,
      disableClose: true,
      data: {isUpdate: true, documentData: this.customDocumentData, hideExpireDate: this.hideExpireDate},
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        if(this.customDocumentData.isOtherDoc) {
          this.customDocumentData = result
          this.otherDocumentChangeEvent.emit(this.customDocumentData)
        } else {
          this.customDocumentData.document = result
          this.documentChangeEvent.emit(this.customDocumentData.document)
        }
      }
    });
  }

  deleteDocumentConfirmation() {
    this.setCreateDocumentData()
    const dialogRef = this.dialog.open(SharedConfirmationPopupComponent, {
      maxWidth: '500px',
      width: '90%',
      position: {
        top: '60px',
      },
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      closeOnNavigation: true,
      disableClose: true,
      data: {
        title: 'Please Confirm',
        message: 'Are you sure you want to delete this document?',
        confirmTxt: 'Yes, Delete',
        cancelTxt: 'Cancel',
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      const isArchived = true
      if (result) {
        this.deleteUserDocument(this.customDocumentData.document.id ,isArchived)
      }
    });
  }
  
  deleteUserDocument(documentId: string, isArchived: boolean) {
    this.isLoading = true
    this.documentService.deleteDocument(documentId, isArchived).subscribe({
      next: (res) => {
        if (res.success) {
          this.alertService.success('Document deleted successfully')
          this.customDocumentData.document = null!
          this.documentChangeEvent.emit()
        } else {
          this.alertService.error(res.error.message)
        }
        this.isLoading = false
      }, error: (err) => {
        this.isLoading = false
        this.alertService.error('Operation failed')
      },
    });
  }

  
  // Download Document
  downloadDocument(key: string, name: string) {
    const documentUrl = this.uploadService.getUrlByS3KEY(key);
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', documentUrl);
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  setCreateDocumentData() {
    if (this.customDocument) {
      this.customDocumentData.document = this.customDocument
    }
    this.customDocumentData.documentCategory = this.currentDocumentCategory
    this.customDocumentData.documentName = this.getNameByCategory(this.currentDocumentCategory)
    this.customDocumentData.documentGroup = this.documentGroup.Mandatory
    this.customDocumentData.hasExpiry = true
    this.customDocumentData.isBusinessDocument = false
    this.customDocumentData.isBusinessServiceDocument = false
    this.customDocumentData.currentUserId = this.tradeId //this.updateProfileImageData.userId
    this.customDocumentData.fileLocation = `User/${this.tradeId}/Documents/`
  }

  getNameByCategory(documentCategory: number) {
    let name = ''
    switch (documentCategory) {
      case this.documentCategory.WorkCoverCertificate:
        name = 'Work Cover Certificate'
        break;
      case this.documentCategory.PublicLiabilityCertificate:
        name = 'Public Liability Certificate'
        break;
      case this.documentCategory.WhiteCard:
        name = 'Construction Industry White Card'
        break;
      case this.documentCategory.CovidVaccinationCertificate:
        name = 'Covid Vaccination Certificate'
        break;
      case this.documentCategory.Other:
        name = 'Other Document'
        break;
    }
    return name
  }

}
