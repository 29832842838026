import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GeneralComplianceStatusType, GeneralComplianceType, UserType } from 'src/app/core/enums/main.enum';
import { ComplianceStatusList } from '../../../models/tradie/tradie.model';
import { TradieService } from '../../../services/tradie/tradie.service';

@Component({
  selector: 'app-compliance-status-widget',
  templateUrl: './compliance-status-widget.component.html',
  styleUrls: ['./compliance-status-widget.component.scss']
})
export class ComplianceStatusWidgetComponent implements OnInit {

  @Input() businessInfoId!: string;
  @Input() generalComplianceType!: number;
  @Input() labelOnly: boolean = false
  @Output() getStatusCommentEvent = new EventEmitter<any>()
  isLoading = false

  curuntStatus: any = '--';

  complianceStatus = GeneralComplianceStatusType
  gComplianceType = GeneralComplianceType
  complianceData: any

  currentComplianceStatusData!: ComplianceStatusList
  complianceStatusList: ComplianceStatusList[] = []
  userType = UserType

  constructor(
    private tradeService: TradieService, 
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.getComplianceStatus(this.businessInfoId, this.generalComplianceType)
  }

  private getComplianceStatus(businessInfoId: string, generalComplianceType: number) {
    this.isLoading = true
    this.tradeService.getComplianceStatus(businessInfoId, generalComplianceType).subscribe({
      next: response => {
        if (response.success) {
          this.complianceStatusList = response.data
          this.currentComplianceStatusData = this.complianceStatusList[0]
          this.getStatusCommentEvent.emit(this.currentComplianceStatusData.comment)
          this.getComplienceStatus(this.currentComplianceStatusData.status)
        } else {
          this.complianceStatusList = []
        }
        this.isLoading = false
      }, error: error => {
        this.isLoading = false
      } 
    })
  }

  getComplienceStatus(status: any) {
    switch (status) {
      case this.complianceStatus.PendingApproval:
        this.curuntStatus = 'Pending Approval'
        break;
      case this.complianceStatus.ActionRequired:
        this.curuntStatus = 'Action Required'
        break;
      case this.complianceStatus.Alert:
        this.curuntStatus = 'Alert'
        break;
      case this.complianceStatus.Approved:
        this.curuntStatus = 'Approved'
        break;
      case this.complianceStatus.Restricted:
        this.curuntStatus = 'Restricted'
        break;
      case this.complianceStatus.Expired:
        this.curuntStatus = 'Expired'
        break;
      case this.complianceStatus.Exempt:
        this.curuntStatus = 'Exempt'
        break;
      default:
        this.curuntStatus = '--'
        break;
    }
  }
}
