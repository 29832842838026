import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class OnboardGuard implements CanActivate {

  constructor(private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    const isOnboarded = JSON.parse(localStorage.getItem('FIX_IS_TRADE_ONBOARD') || '{}')
    const tradeRole = JSON.parse(localStorage.getItem('FIX_TRADE_ROLE') || '{}')
    const redirectUrl = (tradeRole == 2) ? '/member-onboarding/member-onboarding-in-progress' : '/onboarding/onboarding-in-progress'

    if (isOnboarded) {
      return true;
    } else {
      this.router.navigate([redirectUrl]);
      return false;
    }
  }

}
