import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UploadWidgetStyleType } from 'src/app/core/enums/custom.enum';
import { DocumentStatus } from 'src/app/core/enums/document.enum';
import { MainDocumentTypes, SwmsDocument } from '../../../models/document.model';
import { AlertService } from '../../../services/alert/alert.service';
import { SWMSQuestionService } from 'src/app/modules/shared/services/SWMSQuestion/swmsquestion.service';
import { SwmsFilesService } from '../../file-upload/swms-files/swms-files.service';

@Component({
  selector: 'app-shared-swms-docs-widget',
  templateUrl: './shared-swms-docs-widget.component.html',
  styleUrls: ['./shared-swms-docs-widget.component.scss']
})
export class SharedSwmsDocsWidgetComponent implements OnInit {

  @Input() businessInfoId!: string;
  @Input() tradeId!: string;
  @Output() swmsDocsChangeEvent = new EventEmitter<any>()

  isLoading = false
  questionList: any[] = []

  mainDocumentType = MainDocumentTypes;
  SWMSURL = ''
  currentUserId = ''
  SWMSData = new SwmsDocument()

  documentStatus = DocumentStatus
  uploadWidgetStyleType = UploadWidgetStyleType
  documentfileLocation = 'User/Documents/'
  documentNamePrefix = 'swms_document'
  swmsURL = ''

  createdSWMSListNew: any[] = []
  mergedAnsQuestionListNew: any[] = []

  constructor (
    private authService: AuthService,
    private SWMSQuestionService: SWMSQuestionService,
    private alertService: AlertService,
    private sWMSDocumentServiceNew: SwmsFilesService,
  ) { }

  ngOnInit(): void {
    this.currentUserId = this.authService.currentUserValue.userId
    this.documentfileLocation = `User/${this.currentUserId}/Documents/`
    this.getSWMSQuestions()
  }

  private getSWMSQuestions() {
    const isArchived = false
    this.SWMSQuestionService.getSWMSQuestionsList(isArchived).subscribe({
      next: (res) => {
        if (res.success) {
          this.questionList = res.data
          this.getSWMSDataByTradeNew(this.businessInfoId)
        }
      }, error: (errr) => {this.alertService.error('Operation failed')},
    });
  }

  private getSWMSDataByTradeNew(businessInfoId: string) {
    this.sWMSDocumentServiceNew.getSWMSDocument(businessInfoId).subscribe({
      next: (res) => {
        if (res.success) {
          this.createdSWMSListNew = res.data.data
          this.mergeArrayNew(this.questionList, this.createdSWMSListNew)
        }
      }, error: (errr) => {this.alertService.error('Operation failed')},
    });
  }

  mergeArrayNew(questionArr: any[], answerArr: any[]) {
    this.mergedAnsQuestionListNew = [];
    questionArr.forEach(questionItem => {
      let answer = answerArr.find((answerItem) => answerItem.questionId === questionItem.id)
      let isChecked = false
      if(answer !== undefined) {
        isChecked = true
      }
      let mergedItem = {
        order: questionItem.order,
        question: questionItem,
        answer: answer,
        isChecked: isChecked
      }
      this.mergedAnsQuestionListNew.push(mergedItem)
    });
    this.orderSWMSListNew()
    this.checkSWMScompletedNew()
  }

  orderSWMSListNew() {
    this.mergedAnsQuestionListNew = this.mergedAnsQuestionListNew.sort((a,b) => a.order-b.order);
  }

  removeDocumentEventNew(documentId: string, questionId: string) {
    this.getSWMSQuestions()
  }

  updateDocumentEventNew(documentId: string, questionId: string) {
    this.getSWMSQuestions()
  }

  SWMSChanageEventNew(questionData: any, isChecked: any) {
    const index = this.mergedAnsQuestionListNew.findIndex((obj => obj.question.id == questionData.question.id));
    if(!isChecked && this.mergedAnsQuestionListNew[index].answer) {
      this.deleteAllSWMSDocs(questionData, index)
    }
    this.mergedAnsQuestionListNew[index].isChecked = isChecked
  }

  private deleteAllSWMSDocs(sWMSDocument: any, index: any) {
    this.isLoading = true;
    if(sWMSDocument.answer !== undefined) {
      this.sWMSDocumentServiceNew.deleteSWMSDocumentAll(sWMSDocument.answer.id).subscribe({
        next: (result: any) => {
          if (result.success) {
            this.alertService.success('Document deleted successfully!');
            this.checkSWMScompletedNew()
            this.removeDocsFromQuestion(index)
          } else {
            this.alertService.error(result.error.message);
          }
          this.isLoading = false;
        }, error: err => {
          this.alertService.error('Operation failed!');
          this.isLoading = false;
        }
      });
    }
  }

  removeDocsFromQuestion(index: any) {
    this.mergedAnsQuestionListNew[index].answer = undefined
  }

  checkSWMScompletedNew() {
    let hasQuestion = false
    const tempArr: any[] = []
    
    this.mergedAnsQuestionListNew.forEach( question => {
      if (question?.isChecked) {
        hasQuestion = true
        tempArr.push(question?.answer?.swmsDetailModel)
      }
    })
    
    let hasNull = tempArr.some(el => el === null);
    let isCheckedQuestion = this.mergedAnsQuestionListNew.some(el => el.isChecked === true);  
    const swmsCompleteddata = {
      hasDoc: !hasNull,
      hasQuestion: isCheckedQuestion, // hasQuestion,
    }

    this.swmsDocsChangeEvent.emit(swmsCompleteddata)
  }
}
