import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DocumentCategory, DocumentCategoryType, DocumentGroup } from 'src/app/core/enums/document.enum';
import { CustomDocumentModel, DocumentCreateModel, DocumentEditModel } from '../../../models/document.model';
import { AlertService } from '../../../services/alert/alert.service';
import { DocumentService } from '../../../services/documents/document.service';
import { environment } from 'src/environments/environment'; 
import * as AWS from 'aws-sdk';
import { AuthService } from 'src/app/core/auth/auth.service';

@Component({
  selector: 'app-shared-upload-user-ducument-popup',
  templateUrl: './shared-upload-user-ducument-popup.component.html',
  styleUrls: ['./shared-upload-user-ducument-popup.component.scss'],
  providers: [DatePipe],
})
export class SharedUploadUserDucumentPopupComponent implements OnInit {

  formUserDocument!: FormGroup;
  submitted = false;
  isLoading = false;
  isOtherDoc = false;

  formData!: FormData;
  reader!: FileReader;
  fileData!: File;
  fileName!: string;
  currentURL!: string;
  imgURL: any;
  public imagePath!: string;
  minExpireData = new Date();

  userDocumentCreateData!: DocumentCreateModel
  userDocumentUpdateData!: DocumentEditModel
  
  documentGroup = DocumentGroup;
  documentType = DocumentCategoryType;
  documentCategory = DocumentCategory;
  documentCategoryList = [
    { viewValue :`Passport`, value: this.documentCategory.Passport, isDisabled: false},
    { viewValue :`Driver's License`, value: this.documentCategory.DrivingLicense, isDisabled: false},
    { viewValue :`Government Photo ID`, value: this.documentCategory.GovernmentPhotoId, isDisabled: false},
    { viewValue :`Other`, value: this.documentCategory.OtherProofOfIdentity, isDisabled: false}
  ]

  currentUserId = ''

  constructor(
    private datePipe: DatePipe,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private documentService: DocumentService,
    private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) public data: {documentData: CustomDocumentModel, types: number[]},
    public dialogRef: MatDialogRef<SharedUploadUserDucumentPopupComponent>
  ) { }

  ngOnInit(): void {
    this.currentUserId = this.authService.currentUserValue.userId

    this.setDisabledType()
    
    this.formUserDocument = this.formBuilder.group({
      name: [ '', Validators.required],
      link: ['', Validators.required],
      userId: [ this.currentUserId , Validators.required],
      expireDate: [''],
      // expireDate: ['', Validators.required],
      documentCategory: ['', Validators.required],
      documentType: ['', Validators.required],
      group: ['', Validators.required],
    });

    if (this.data.documentData.document) {
      this.setDocumentUpdateFormData(this.data.documentData)
    } else {
      this.setDocumentCreateFormData(this.data.documentData)
    }
  }

  get f() {
    return this.formUserDocument.controls;
  }

  onSubmit() {
    this.submitted = true
    if (this.formUserDocument.invalid) {
      return;
    }
    this.isLoading = true
    if (this.data.documentData.document?.link === this.formUserDocument.value.link) {
      this.saveForm(); // Updating & not updating the file
    } else {
      this.uploadDocToS3(); // updating the file
    }
  }

  setDocumentUpdateFormData(documentData: CustomDocumentModel) {
    this.formUserDocument.addControl('id', new FormControl(documentData?.document?.id) );
    this.formUserDocument.controls['name'].setValue(documentData?.document?.name);
    this.formUserDocument.controls['link'].setValue(documentData?.document.link);
    this.formUserDocument.controls['documentCategory'].setValue(documentData?.document.documentCategory);
    this.formUserDocument.controls['group'].setValue(documentData?.document.group);
    const expireDate = documentData?.document?.expireDate ? new Date(documentData?.document?.expireDate) : null
    this.formUserDocument.controls['expireDate'].setValue(expireDate);
    this.formUserDocument.controls['documentType'].setValue(documentData?.document?.documentType);

    if (documentData?.document.documentCategory === this.documentCategory.OtherProofOfIdentity) {
      this.isOtherDoc = true
    }
  }

  setDocumentCreateFormData(documentData: CustomDocumentModel) {
    this.formUserDocument.controls['name'].setValue(documentData?.documentName);
    this.formUserDocument.controls['group'].setValue(documentData?.documentGroup);
  }

  uploadDocToS3() {
    this.formData = new FormData();
    this.formData.append('file', this.fileData);

    const fileName = this.getFileNameByCategory(
      this.formUserDocument.value.name
    );
    const contentType = this.fileData.type;
    const fileExtension = this.fileData.name.split('.').pop();

    const bucket = new AWS.S3({
      accessKeyId: environment.config.keyId,
      secretAccessKey: environment.config.key,
      region: environment.config.region,
    });

    const params = {
      Bucket: environment.config.location,
      Key: this.data.documentData.fileLocation + fileName + '.' + fileExtension,
      Body: this.fileData,
      ACL: 'private',
      ContentType: contentType,
    };

    bucket.upload(params, (error: any, response: any) => {
      if (error) {
        this.alertService.error('Operation failed!');
        return false;
      }

      this.formUserDocument.patchValue({
        link: response.Key,
      });
      if(this.data.documentData.isOtherDoc) {
        this.data.documentData.otherDocURL = response.Key
        this.data.documentData.otherDocExpiry =  this.formUserDocument.value.expireDate
        this.dialogRef.close(this.data.documentData);
      } else {
        this.saveForm();
      }
      return true;
    });
  }

  saveForm() {
    if (this.data.documentData?.document?.id) {
      this.updateUserDocument(this.formUserDocument.getRawValue());
    } else {
      this.createUserDocument(this.formUserDocument.getRawValue());
    }
  }

  // Create user document
  private createUserDocument(data: DocumentCreateModel) {
    this.userDocumentCreateData = data;
    this.userDocumentCreateData.expireDate = this.convertDate(data.expireDate)
    this.isLoading = true;
    this.documentService
      .createDocumentById(this.userDocumentCreateData)
      .subscribe({
        next: (response: any) => {
          if (response.success) {
            this.alertService.success('Document created successfully');
            this.dialogRef.close(response.data);
          } else {
            this.alertService.error(response.error.message);
          }
          this.isLoading = false;
        },
        error: (error: any) => {
          this.isLoading = false;
          this.alertService.error('Operation failed!');
        },
      });
  }

  // Edit user document
  private updateUserDocument(data: DocumentEditModel) {
    this.userDocumentUpdateData = data;
    this.userDocumentUpdateData.expireDate = this.convertDate(data.expireDate)
    this.isLoading = true;
    this.documentService
      .updateDocument(this.userDocumentUpdateData)
      .subscribe({
        next: (response: any) => {
          if (response.success) {
            this.alertService.success('Document updated successfully');
            this.dialogRef.close(response.data);
          } else {
            this.alertService.error(response.error.message);
          }
          this.isLoading = false;
        },
        error: (error: any) => {
          this.isLoading = false;
          this.alertService.error('Operation failed!');
        },
      });
  }

  onDocumentCategoryChange(event: any) {
    const selectedCategory = event.value
    this.isOtherDoc = false
    switch (selectedCategory) {
      case this.documentCategory.Passport:
        this.formUserDocument.controls['name'].setValue('Passport');
        break;
      case this.documentCategory.DrivingLicense:
        this.formUserDocument.controls['name'].setValue('Drivers License');
        break;
      case this.documentCategory.GovernmentPhotoId:
        this.formUserDocument.controls['name'].setValue('Government Photo Id');
        break;
      case this.documentCategory.OtherProofOfIdentity:
        this.formUserDocument.controls['name'].setValue('');
        this.isOtherDoc = true
        break;
    }
  }

  // Generate File Name
  getFileNameByCategory(name: string) {
    const timePrefix = this.datePipe.transform(new Date(), 'yyyyMMddhhmmss');
    return name ? name.split(' ').join('_').toLocaleLowerCase() + '_' + timePrefix : '';
  }

  // Get documentType form name
  getDocumentType(link: string) {
    const currentLink = link.toLowerCase()
    const extention = currentLink.split('.').pop()
    let currentDocumentType
    switch (extention) {
      case 'pdf':
        currentDocumentType = this.documentType.PDF
        break;
      case 'doc':
        currentDocumentType = this.documentType.DOC
        break;
      case 'docx':
        currentDocumentType = this.documentType.DOC
        break;
      case 'png':
        currentDocumentType = this.documentType.PNG
        break;
      case 'jpeg':
        currentDocumentType = this.documentType.JPEG
        break;
      case 'jpg':
        currentDocumentType = this.documentType.JPEG
        break;
    }
    return currentDocumentType
  }

  // Browse file/document and preview.
  fileProgress(event: any) {
    this.reader = new FileReader();

    this.fileName = '';
    this.fileData = event.target.files[0];
    this.fileName = event.target.files[0].name;

    this.formUserDocument.patchValue({
      link: this.fileName,
    });

    // set file extention / doc type
    const currrentDocType  = this.getDocumentType(this.fileData.name)
    this.formUserDocument.controls['documentType'].setValue(currrentDocType);

    // For iamge preview
    this.imagePath = event.target.files;
    this.reader.readAsDataURL(event.target.files[0]);
    this.reader.onload = (_event) => {
      this.imgURL = this.reader.result;
    };
  }

  setDisabledType() {
    this.documentCategoryList.forEach( category => {
      category.isDisabled = ( this.data?.types?.includes(category.value) === true )
    })
  }

  convertDate(date: string) {
    return date ? this.datePipe.transform(date, 'yyyy-MM-dd') : null;
  }
}
