<div class="confirm-popup-wr d-flex flex-column justify-content-center align-items-center p-4">

  <h2 *ngIf="data?.title" class="text-center mb-0 text-uppercase font-bold">{{data.title}}</h2>
  <p *ngIf="data?.message" class="d-block m-0 py-3 text-center text-lg">{{data.message}}</p>

  <div class="d-flex flex-row justify-content-center mt-2">
      <button mat-flat-button mat-dialog-close class="btn primary me-2" (click)="closePopUp()">
        {{data.confirmTxt ? data.confirmTxt : 'OK'}}
      </button>
  </div>
</div>