import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class MemberGuard implements CanActivate {

  constructor(private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    const tradeRole = JSON.parse(localStorage.getItem('FIX_TRADE_ROLE') || '{}')

    if (tradeRole == 2) {
      this.router.navigate(['/member-onboarding/member-onboarding-in-progress']);
      return false
    } else {
      return true
    }

  }

}
