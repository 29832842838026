import { Component, Input, OnInit } from '@angular/core';
import { UserType } from 'src/app/core/enums/main.enum';
import { Address } from '../../../models/my-jobs/my-jobs.model';

@Component({
  selector: 'app-shared-user-info-widget',
  templateUrl: './shared-user-info-widget.component.html',
  styleUrls: ['./shared-user-info-widget.component.scss']
})
export class SharedUserInfoWidgetComponent implements OnInit {

  @Input() user: any; //User
  @Input() address!: Address;
  @Input() isManager!: boolean;
  @Input() hideTitle!: boolean;

  userType = UserType
  
  constructor() { }

  ngOnInit(): void {
  }

}
