<section class="header-wr m-0 ps-4 pe-2 py-3 d-flex align-items-center justify-content-between">
  <h3 class="popup-header mb-0">Edit Specialities</h3>
  <button class="close" mat-icon-button><mat-icon (click)="close()">cancel</mat-icon></button>
</section>
<mat-divider></mat-divider>
<main class="px-4 mt-2">
  <div class="col-12">
    <mat-form-field class="w-100" appearance="standard">
        <mat-chip-list #chipList aria-label="specialty selection">
            <mat-chip *ngFor="let specialty of specialtyList" [removable]="removable" (removed)="removeSpecialty(specialty)" class="chip-text">
                {{specialty}}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
            <input #chipInput [matChipInputFor]="chipList" placeholder="New Specialty..."
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="addOnBlur"
                (matChipInputTokenEnd)="addSpecialtyEvent($event)">
                <button *ngIf="chipInput.value" type="button" class="chip-add" matSuffix mat-mini-fab aria-label="add" (click)="addSpecialty(chipInput.value)">
                    <mat-icon>add</mat-icon>
                </button>
        </mat-chip-list>
    </mat-form-field>
  </div>

  <section class="action-btns d-flex align-items-center justify-content-end mt-4 mb-3">
    <button 
      class="btn primary shadow-none px-5 ms-2" 
      mat-raised-button
      (click)="onSubmit()">
      Save
    </button>
  </section>
</main>









