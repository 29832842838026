<div class="d-flex justify-content-between align-items-center m-0 ps-4 pe-2 py-3">
  <p class="popup-header mb-0 mb-0">Update Name</p>
  <button mat-icon-button><mat-icon matDialogClose>cancel</mat-icon></button>
</div>

<mat-divider></mat-divider>

<div class="popup-wr p-4 d-flex justify-content-center">
  <form class="row mt-0" [formGroup]="formUpdateName" (ngSubmit)="onSubmit()">

      <div class="col-12 mb-3">
          <mat-form-field class="w-100" appearance="legacy">
              <mat-label>First name</mat-label>
              <input formControlName="firstName" matInput placeholder="Please type">
              <mat-error *ngIf="f['firstName'].errors?.['required']">
                Please enter first name.
              </mat-error>
              <mat-error *ngIf="!f['firstName'].errors?.['required'] && f['firstName'].errors?.['pattern']">
                Please enter valid name.
              </mat-error>
          </mat-form-field>
      </div>

      <div class="col-12">
          <mat-form-field class="w-100" appearance="legacy">
              <mat-label>Last Name</mat-label>
              <input formControlName="lastName" matInput placeholder="Please type">
              <mat-error *ngIf="f['lastName'].errors?.['required']">
                Please enter last name.
              </mat-error>
              <mat-error *ngIf="!f['lastName'].errors?.['required'] && f['lastName'].errors?.['pattern']">
                Please enter valid name.
              </mat-error>
          </mat-form-field>
      </div>

      <div class="col-12 action-btns text-right w-100 mt-3">
          <div class="d-flex align-items-center justify-content-end">
              <button class="btn primary shadow-none" mat-raised-button [disabled]="isLoading">Update</button>
              <mat-spinner class="ms-2" diameter="25" *ngIf="isLoading"></mat-spinner>
          </div>
      </div>
  </form>
</div>









