import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IndemnityFilesService {

  private endpoint = environment.endpointURL + '/api/Tradie/ProfessionalIdemnityCompliancesDocument';

  constructor(private http: HttpClient) { }

  public createIndemnityDocument(data: any): Observable<any> {
    const url = `${this.endpoint}`;
    return this.http.post<any>(url, data);
  }

  public updateIndemnityDocument(data: any): Observable<any> {
    const url = `${this.endpoint}`;
    return this.http.put<any>(url, data);
  }

  public getIndemnityDocument(businessInfoId: string): Observable<any> {
    const url = `${this.endpoint}?businessInfoId=${businessInfoId}`;
    return this.http.get<any>(url);
  }

  public deleteIndemnityDocument(id: string ): Observable<any> {
    const url = `${this.endpoint}?id=${id}`;
    return this.http.delete<any>(url);
  }

}
