<div class="d-flex justify-content-between align-items-center m-0 ps-4 pe-2 py-3">
  <p *ngIf="!signatureData?.document?.id" class="popup-header mb-0 mt-1">Add Digital Signature</p>
  <p *ngIf="signatureData?.document?.id" class="popup-header mb-0 mt-1">Update Digital Signature</p>
  <button mat-icon-button mat-dialog-close><mat-icon>cancel</mat-icon></button>
</div>
<mat-divider></mat-divider>

<div class="popup-wr p-4">

  <div class="digital-signature-wr mb-3">
    <signature-pad class="pad" [options]="signaturePadOptions" (onBeginEvent)="drawStart()" (onEndEvent)="drawComplete()"></signature-pad>
  </div>

  <div class="action-wr d-flex align-items-center justify-content-end">
    <button class="me-2 text-uppercase text-color primary" mat-button (click)="clearSignature()" [disabled]="!hasSignature">[ Clear signature ]</button>
    <button class="btn primary w-mid" mat-flat-button (click)="savePad()" [disabled]="!hasSignature">Save signature</button>
    <mat-spinner class="ms-2" diameter="25" *ngIf="isLoading"></mat-spinner>
  </div>
</div>