import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/modules/shared/services/loader/loader.service';

@Component({
  selector: 'app-common-loader',
  templateUrl: './common-loader.component.html',
  styleUrls: ['./common-loader.component.scss']
})
export class CommonLoaderComponent implements OnInit {

  isloading = false;

  constructor(private loaderService: LoaderService) {
    this.loaderService._loading.subscribe((loading: boolean) => {
      setTimeout(() => {
        this.isloading = loading;
      }, 0)
    });
  }

  ngOnInit(): void {
  }

}
