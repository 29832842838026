import { Component, Input, OnInit } from '@angular/core';
import { DocumentStatus } from 'src/app/core/enums/document.enum';

@Component({
  selector: 'app-shared-document-status-widget',
  templateUrl: './shared-document-status-widget.component.html',
  styleUrls: ['./shared-document-status-widget.component.scss']
})
export class SharedDocumentStatusWidgetComponent implements OnInit {

  @Input() currentStatus!: any
  documentStatus = DocumentStatus
  
  constructor() { }

  ngOnInit(): void {
  }

}
