import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DocumentCategoryType, DocumentGroup, HealthSafetyDocumentType } from 'src/app/core/enums/document.enum';
import { AlertService } from 'src/app/modules/shared/services/alert/alert.service';
import { HealthAndSafetyFilesCreateModel, HealthAndSafetyFilesCustomDocumentModel, HealthAndSafetyFilesModel, HealthAndSafetyFilesUpdateModel } from '../health-and-safety-files.model';
import { HealthAndSafetyFilesService } from '../health-and-safety-files.service';
import * as AWS from 'aws-sdk';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-health-and-safety-file-upload-popup',
  templateUrl: './health-and-safety-file-upload-popup.component.html',
  styleUrls: ['./health-and-safety-file-upload-popup.component.scss'],
  providers: [DatePipe]
})
export class HealthAndSafetyFileUploadPopupComponent implements OnInit {

  formNewdoc!: FormGroup;
  submitted = false;
  isLoading = false;
  hasExpiry = false;
  isOtherDoc = false;

  formData!: FormData;
  reader!: FileReader;
  fileData!: File;
  fileName!: string;
  currentURL!: string;
  imgURL: any;
  public imagePath!: string;
  minExpireData = new Date();

  healthAndSafetysDocumentUpdateData!: HealthAndSafetyFilesUpdateModel;
  healthAndSafetysDocumentCreateData!: HealthAndSafetyFilesCreateModel;

  currentDocumentName = ''
  documentGroup = DocumentGroup;
  documentType = DocumentCategoryType;
  documentCategory = HealthSafetyDocumentType;
  healthSafetyDocumentType = HealthSafetyDocumentType

  constructor(
    private healthAndSafetyDocumentServiceNew: HealthAndSafetyFilesService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: {isUpdate: boolean, documentData: HealthAndSafetyFilesCustomDocumentModel, userId: string},
    public dialogRef: MatDialogRef<HealthAndSafetyFileUploadPopupComponent>
  ) { }

  ngOnInit(): void {
    this.formNewdoc = this.formBuilder.group({
      businessInfoId: [ this.data.documentData.businessInfoId],
      userId: [this.data.userId],
      documentType: ['', Validators.required],
      group: [ this.documentGroup.Mandatory],
      healthSafetyDocumentType: [this.data.documentData.documentCategory],
      manageHealthSafety: [true],
      name: [ '', Validators.required],
      link: ['', Validators.required],
      expiryDate: [''],
    });

    this.hasExpiry = this.data.documentData.hasExpiry

    this.currentDocumentName = this.getNameByCategory(this.data.documentData.documentCategory)
    if (this.data.isUpdate) this.setDocumentUpdateFormData(this.data.documentData.document);
    else this.setDocumentCreateFormData(this.data.documentData)
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.formNewdoc.controls;
  }

  // Upload selected image
  onSubmit() {
    this.isLoading = true;
    this.submitted = true;
    // If invalid
    if (this.formNewdoc.invalid) {
      this.isLoading = false;
      return;
    }

    if (this.data.documentData.document?.link === this.formNewdoc.value.link) { 
      this.saveForm(); // Updating & not updating the file
    } else {
      this.uploadDocToS3(); // updating the file
    }
  }
  
  setDocumentCreateFormData(document: HealthAndSafetyFilesCustomDocumentModel) {
    this.formNewdoc.controls['name'].setValue(this.getNameByCategory(document.documentCategory));
    this.formNewdoc.controls['healthSafetyDocumentType'].setValue(document?.documentCategory);
    this.formNewdoc.controls['userId'].setValue(document?.currentUserId);
    this.formNewdoc.controls['manageHealthSafety'].setValue(true);
    this.formNewdoc.controls['businessInfoId'].setValue(document.businessInfoId);
  }

  setDocumentUpdateFormData(document: HealthAndSafetyFilesModel) {
    this.formNewdoc.addControl('id', new FormControl(document.id) );
    this.formNewdoc.controls['name'].setValue(document?.name);

    const expDate = document?.expiryDate ? new Date(document?.expiryDate) : null
    this.formNewdoc.controls['expiryDate'].setValue(expDate);

    this.formNewdoc.controls['healthSafetyDocumentType'].setValue(document.healthSafetyDocumentType);
    this.formNewdoc.controls['userId'].setValue(this.data.userId);
    this.formNewdoc.controls['link'].setValue(this.data.documentData?.document.link);
    const currrentDocType  = this.getDocumentType(this.data.documentData.document.link)
    this.formNewdoc.controls['documentType'].setValue(currrentDocType);
    this.formNewdoc.controls['manageHealthSafety'].setValue(true);
    this.formNewdoc.controls['businessInfoId'].setValue(this.data.documentData.businessInfoId);
  }
  
  uploadDocToS3() {
    this.formData = new FormData();
    this.formData.append('file', this.fileData);

    const fileName = this.getFileNameByCategory(
      this.formNewdoc.value.name
    );
    const contentType = this.fileData.type;
    const fileExtension = this.fileData.name.split('.').pop();

    const bucket = new AWS.S3({
      accessKeyId: environment.config.keyId,
      secretAccessKey: environment.config.key,
      region: environment.config.region,
    });

    const fileLocation = `Trade/${this.data.documentData.businessInfoId}/Documents/Health_Safety/`+fileName+'.'+ fileExtension;

    const params = {
      Bucket: environment.config.location,
      Key: fileLocation,
      Body: this.fileData,
      ACL: 'private',
      ContentType: contentType,
    };

    bucket.upload(params, (error: any, response: any) => {
      if (error) {
        this.alertService.error('Operation failed!');
        return false;
      }

      this.formNewdoc.patchValue({
        link: response.Key,
      });
      if(this.data.documentData.isOtherDoc) {
        this.data.documentData.otherDocURL = response.Key
        this.data.documentData.otherDocExpiry =  this.formNewdoc.value.expiryDate
        this.dialogRef.close(this.data.documentData);
      } else {
        this.saveForm();
      }
      return true;
    });
  }

  saveForm() {
    if (this.data.documentData?.currentUserId) {
      if (this.data.isUpdate) {
        this.updateBusinessDocument(this.formNewdoc.getRawValue());
      } else {
        this.createBusinessDocument(this.formNewdoc.getRawValue());
      }
    } else {
      this.dialogRef.close(false);
    }
  }

  // Browse file/document and preview.
  fileProgress(event: any) {
    this.reader = new FileReader();

    this.fileName = '';
    this.fileData = event.target.files[0];
    this.fileName = event.target.files[0].name;

    this.formNewdoc.patchValue({
      link: this.fileName,
    });

    // set file extention / doc type
    const currrentDocType  = this.getDocumentType(this.fileData.name)
    this.formNewdoc.controls['documentType'].setValue(currrentDocType);

    // For iamge preview
    this.imagePath = event.target.files;
    this.reader.readAsDataURL(event.target.files[0]);
    this.reader.onload = (_event) => {
      this.imgURL = this.reader.result;
    };
  }

  // Create business document
  private createBusinessDocument(data: HealthAndSafetyFilesCreateModel) {
    this.healthAndSafetysDocumentCreateData = data;
    this.healthAndSafetysDocumentCreateData.expiryDate = this.convertDate(data.expiryDate)
    this.isLoading = true;
    this.healthAndSafetyDocumentServiceNew
      .createHealthSafetyCompliance(this.healthAndSafetysDocumentCreateData)
      .subscribe({
        next: (response: any) => {
          if (response.success) {
            this.alertService.success('Document created successfully');
            this.dialogRef.close(response.data);
          } else {
            this.alertService.error(response.error.message);
          }
          this.isLoading = false;
        },
        error: (error: any) => {
          this.alertService.error('Operation failed!');
        },
      });
  }

  // Edit business document
  private updateBusinessDocument(data: HealthAndSafetyFilesUpdateModel) {
    this.healthAndSafetysDocumentUpdateData = data;
    this.healthAndSafetysDocumentUpdateData.expiryDate = this.convertDate(data.expiryDate)
    this.isLoading = true;
    this.healthAndSafetyDocumentServiceNew
      .updateHealthSafetyCompliance(this.healthAndSafetysDocumentUpdateData).subscribe({
        next: (response: any) => {
          if (response.success) {
            this.alertService.success('Document updated successfully');
            this.dialogRef.close(response.data);
          } else {
            this.alertService.error(response.error.message);
          }
          this.isLoading = false;
        },
        error: (error: any) => {
          this.alertService.error('Operation failed!');
        },
      });
  }

  // Generate File Name
  getFileNameByCategory(name: string) {
    const timePrefix = this.datePipe.transform(new Date(), 'yyyyMMddhhmmss');
    return name ? name.split(' ').join('_').toLocaleLowerCase() + '_' + timePrefix : '';
  }

  // Convert Date
  convertDate(date: string) {
    return date ? this.datePipe.transform(date, 'yyyy-MM-dd') : null
  }

  // Get documentType form name
  getDocumentType(link: string) {
    const currentLink = link.toLowerCase()
    const extention = currentLink.split('.').pop()
    let currentDocumentType
    switch (extention) {
      case 'pdf':
        currentDocumentType = this.documentType.PDF
        break;
      case 'doc':
        currentDocumentType = this.documentType.DOC
        break;
      case 'docx':
        currentDocumentType = this.documentType.DOC
        break;
      case 'png':
        currentDocumentType = this.documentType.PNG
        break;
      case 'jpeg':
        currentDocumentType = this.documentType.JPEG
        break;
      case 'jpg':
        currentDocumentType = this.documentType.JPEG
        break;
    }
    return currentDocumentType
  }

  getNameByCategory(documentCategory: number) {
    let name = ''
    switch (documentCategory) {
      case this.documentCategory.InductionProcessDocument:
        name = 'Induction Process'
        break;
      case this.documentCategory.JSAOrSWMSDocument:
        name = 'Generic JSA or SWMS for your trade'
        break;
      case this.documentCategory.OHSManagementSystemDocument:
        name = 'Company OHS Management System'
        break;
      case this.documentCategory.RelevantAccreditationDocument:
        name = 'Any relevant H&S licenses or accreditation'
        break;
      case this.documentCategory.RiskAssessmentDocument:
        name = 'Business Risk Assessment Risk Register'
        break;
      case this.documentCategory.StandardOperatingProcedureDocument:
        name = 'Standard Operating Procedure'
        break;
    }
    return name
  }

  clearDate() {
    this.formNewdoc.controls['expiryDate'].setValue(null);
  }
}
