import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'centsToDoller'
})
export class CentsToDollerPipe implements PipeTransform {

  transform(input: number, args?: any): any {

    if (Number.isNaN(input)) {
      return null;
    }

    if (input == 0) {
      return input;
    } else {
      let toDoll = input/100;

      if (toDoll > 1000) {
        toDoll = Math.round(( (toDoll/1000) + Number.EPSILON) * 100) / 100;
        let dollar = toDoll
        return '$ ' + dollar + 'K';
      } else {
        return '$ ' + toDoll;
      }
    }
  }
}
