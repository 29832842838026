import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ServiceCategory } from 'src/app/modules/shared/models/service-category/service-category.model';
import { BusinessServiceAddModel, BusinessServiceModel, BusinessServiceUpdateModel } from 'src/app/modules/shared/models/tradie/tradie.model';
import { AlertService } from 'src/app/modules/shared/services/alert/alert.service';
import { TradieService } from 'src/app/modules/shared/services/tradie/tradie.service';

@Component({
  selector: 'app-shared-category-creation-widget',
  templateUrl: './shared-category-creation-widget.component.html',
  styleUrls: ['./shared-category-creation-widget.component.scss']
})
export class SharedCategoryCreationWidgetComponent implements OnInit {

  formBusinessService!: FormGroup
  submitted = false
  isLoading = false
  hasService = false
  categoryCreated = false
  quotationOnly = false

  serviceCategoriesList: ServiceCategory[] = []
  selectedCategory!: string
  businessServiceAddData = new BusinessServiceAddModel();
  businessServiceUpdateData = new BusinessServiceUpdateModel();

  @Input() businessInfoId!: string;
  @Input() serviceCategories!: ServiceCategory[];
  @Output() addServiceCategoryEvent = new EventEmitter<boolean>();
  
  constructor(
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private tradieService: TradieService
  ) {}

  ngOnInit(): void {
    this.serviceCategoriesList = this.serviceCategories.filter( cat => cat.isArchived == false )

    this.formBusinessService = this.formBuilder.group({
      businessInfoId: this.businessInfoId,
      serviceCategoryId: [''],
      businessHourCallOutFee: ['', [Validators.pattern('^\-?[0-9]+(?:\.[0-9]{1,2})?$'), Validators.max(21474836.00)]],
      businessHourHourlyRate: ['', [Validators.pattern('^\-?[0-9]+(?:\.[0-9]{1,2})?$'), Validators.max(21474836.00)]],
      afterHourCallOutFee: ['', [Validators.pattern('^\-?[0-9]+(?:\.[0-9]{1,2})?$'), Validators.max(21474836.00)]],
      afterHourHourlyRate: ['', [Validators.pattern('^\-?[0-9]+(?:\.[0-9]{1,2})?$'), Validators.max(21474836.00)]],
      quotationOnly: [ this.quotationOnly ],
      documents: null,
    });

    this.disabledControls()
  }

  get f() {
    return this.formBusinessService.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.formBusinessService.invalid) {
      return;
    }

    this.setRequestAddData(this.formBusinessService.value)
    this.addServiceCategory(this.businessServiceAddData)
  }

  private addServiceCategory(data: BusinessServiceAddModel) {
    this.isLoading = true;
    this.tradieService.addBusinessService(data).subscribe({
      next: response => {
        if (response.success) {
          this.alertService.success('Service category successfully added');
          const category = response.data
          this.addServiceCategoryEvent.emit(category);
          this.categoryCreated = true
          this.disabledControls()
        } else {
          this.alertService.error(response.error.message);
        }
        this.isLoading = false;
      }, error: err => {
        this.isLoading = false;
        this.alertService.error('Operation failed');
      }
    });
  }

  private updateBusinessService(data: BusinessServiceUpdateModel) {
    this.isLoading = true;
    this.tradieService.updateBusinessService(data).subscribe({
        next: (response: any) => {
          if (response.success) {
            this.alertService.success('Service category details successfully updated');
            // TODO - Update Functionality
          } else {
            this.alertService.error(response.error.message);
          }
          this.isLoading = false;
        },
        error: (error: any) => {
          this.isLoading = false;
          this.alertService.error('Operation failed');
        }
      });
  }

  public serviceCategoryOnSelect(selectedId: string) {
    const serviceCategoryId = selectedId;
    if (serviceCategoryId) {
      this.hasService = true
      this.formBusinessService.controls['serviceCategoryId'].setValue(serviceCategoryId);
      this.enableControls()
    }
  }

  setFormData(data: BusinessServiceModel) {
    this.formBusinessService.controls['businessHourCallOutFee'].setValue( data.businessHourCallOutFee? (data.businessHourCallOutFee / 100) : '' )
    this.formBusinessService.controls['businessHourHourlyRate'].setValue( data.businessHourHourlyRate? (data.businessHourHourlyRate / 100) : '')
    this.formBusinessService.controls['afterHourCallOutFee'].setValue( data.afterHourCallOutFee ? (data.afterHourCallOutFee / 100) : '')
    this.formBusinessService.controls['afterHourHourlyRate'].setValue( data.afterHourHourlyRate ? (data.afterHourHourlyRate / 100) : '')
    this.formBusinessService.controls['quotationOnly'].setValue(data.quotationOnly)
  }

  disabledControls() {
    this.formBusinessService.controls['businessHourCallOutFee'].disable()
    this.formBusinessService.controls['businessHourHourlyRate'].disable()
    this.formBusinessService.controls['afterHourCallOutFee'].disable()
    this.formBusinessService.controls['afterHourHourlyRate'].disable()
    this.formBusinessService.controls['quotationOnly'].disable()
  }

  enableControls() {
    this.formBusinessService.controls['businessHourCallOutFee'].enable()
    this.formBusinessService.controls['businessHourHourlyRate'].enable()
    this.formBusinessService.controls['afterHourCallOutFee'].enable()
    this.formBusinessService.controls['afterHourHourlyRate'].enable()
    this.formBusinessService.controls['quotationOnly'].enable()
  }

  setRequestAddData(data: BusinessServiceAddModel) { // 1$ = 100 cents
    this.businessServiceAddData.businessInfoId = data.businessInfoId
    this.businessServiceAddData.serviceCategoryId = data.serviceCategoryId
    this.businessServiceAddData.businessHourCallOutFee = data.businessHourCallOutFee * 100
    this.businessServiceAddData.businessHourHourlyRate = data.businessHourHourlyRate * 100
    this.businessServiceAddData.afterHourCallOutFee = data.afterHourCallOutFee * 100
    this.businessServiceAddData.afterHourHourlyRate = data.afterHourHourlyRate * 100
  }

  setRequestUpdateData(data: BusinessServiceAddModel) : BusinessServiceUpdateModel { // 1$ = 100 cents
    this.businessServiceUpdateData.id = data.businessInfoId
    this.businessServiceUpdateData.businessHourCallOutFee = data.businessHourCallOutFee * 100
    this.businessServiceUpdateData.businessHourHourlyRate = data.businessHourHourlyRate * 100
    this.businessServiceUpdateData.afterHourCallOutFee = data.afterHourCallOutFee * 100
    this.businessServiceUpdateData.afterHourHourlyRate = data.afterHourHourlyRate * 100
    this.businessServiceUpdateData.quotationOnly = data.quotationOnly
    return this.businessServiceUpdateData
  }

}
