import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { MemberGuard } from './core/guards/member.guard';
import { OnboardGuard } from './core/guards/onboard.guard';
import { DashboardLayoutComponent } from './core/layouts/dashboard-layout/dashboard-layout.component';

const routes: Routes = [
  {
    path: 'account',
    loadChildren: () =>
      import('./modules/account/account.module').then((m) => m.AccountModule),
  },
  {
    path: 'onboarding',
    canActivate: [AuthGuard, MemberGuard],
    loadChildren: () =>
      import('./modules/onboarding/onboarding.module').then(
        (m) => m.OnboardingModule
      ),
  },
  {
    path: 'member-onboarding',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/member-onboarding/member-onboarding.module').then(
        (m) => m.MemberOnboardingModule
      ),
  },
  {
    path: '',
    canActivate: [AuthGuard, OnboardGuard],
    component: DashboardLayoutComponent,
    children: [
      { path: 'profile',loadChildren: () => import('./modules/profile/profile.module').then((m) => m.ProfileModule) },
      // { path: 'dashboard', loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule) },
      // { path: 'available-jobs', loadChildren: () => import('./modules/jobs/jobs.module').then(m => m.JobsModule) },
      // { path: 'my-jobs', loadChildren: () => import('./modules/my-jobs/my-jobs.module').then(m => m.MyJobsModule) },
      // { path: 'team', loadChildren: () => import('./modules/team/team.module').then(m => m.TeamModule) },
      // { path: 'inbox', loadChildren: () => import('./modules/inbox/inbox.module').then(m => m.InboxModule) },
      // { path: 'sub-contractors', loadChildren: () => import('./modules/sub-contractors/sub-contractors.module').then(m => m.SubContractorsModule) },
    ]
  },
  {
    path: '',
    redirectTo: 'account/login',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
