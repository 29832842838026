<div class="d-flex justify-content-between align-items-center m-0 ps-4 pe-2 py-3">
  <p class="popup-header font-bold mb-0">Update Mobile Number</p>
  <button mat-icon-button><mat-icon matDialogClose>cancel</mat-icon></button>
</div>

<mat-divider></mat-divider>

<div class="popup-wr p-4">
  <form class="row mt-0" [formGroup]="formUpdatePhone" (ngSubmit)="onSubmit()">

    <div class="col-12 mb-3">
      <mat-label>Mobile Number <span class="text-secondary">*</span></mat-label>
      <mat-form-field appearance="legacy" class="w-100">
        <input cdkFocusInitial matInput formControlName="mobileNumber" (keyup)="onMobileNumberKeyupEvent()" class="p-0" required>

        <span matPrefix class="country-code-wr d-flex align-items-center" [class.disabled]="isVerificationSent">
          <span class="country-code font-semi-bold">{{countryCode}}</span>
          <mat-icon>arrow_drop_down</mat-icon>
        </span>

        <span matSuffix
          *ngIf="!isVerificationSent" 
          class="verify-btn text-uppercase link primary font-bold pointer" 
          (click)="verify()">
          [ Verify Now ]
        </span>

        <mat-error class="text-md mt-1" *ngIf="f['mobileNumber'].errors?.['pattern'] && !f['mobileNumber'].errors?.['required']">
          Please enter a valid mobile number.
        </mat-error>
        <mat-error class="text-md mt-1" *ngIf="submitted && f['mobileNumber'].errors?.['required']">
          Please enter mobile number.
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-12">
      <section class="verify-section" [class.verification-not-sent]="!isVerificationSent">
        <p class="mb-2">Please enter verification code sent to: <span *ngIf="isVerificationSent" class="font-bold ms-1">{{currentMobileNumber}}</span></p>
        <div 
          class="resend-btn text-uppercase pointer link primary font-bold"
          (click)="verify()">[ Resend Code ]
        </div>
        <div class="verify-code-wr mt-3">
          <code-input 
            #codeInput
            [isCodeHidden]="false"
            [isCharsCode]="false"
            [codeLength]="4"
            (codeChanged)="onCodeChanged($event)"
            (codeCompleted)="onCodeCompleted($event)">
          </code-input>
          <mat-error class="text-md mt-1" *ngIf="isVerificationSent && isCodeInvalid">
            Please enter valid verification code.
          </mat-error>
        </div>
        <div class="verify-overlady w-100 h-100" *ngIf="!isVerificationSent"></div>
      </section>
    </div>

    <div class="col-12 action-btns text-right w-100 mt-3">
      <div class="d-flex align-items-center justify-content-end">
        <button class="btn primary w-mid shadow-none" mat-raised-button [disabled]="isLoading || isCodeInvalid || !isCodeCompleted">Save</button>
        <mat-spinner class="ms-2" diameter="25" *ngIf="isLoading || isUpdatingNumber"></mat-spinner>
      </div>
    </div>
  </form>
</div>