import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from 'src/app/modules/onboarding/shared/services/user.service';
import { AlertService } from 'src/app/modules/shared/services/alert/alert.service';

@Component({
  selector: 'app-shared-update-email-popup',
  templateUrl: './shared-update-email-popup.component.html',
  styleUrls: ['./shared-update-email-popup.component.scss']
})
export class SharedUpdateEmailPopupComponent implements OnInit {

  formUpdateEmailAddress!: FormGroup;
  submitted = false;
  isLoading = false;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<SharedUpdateEmailPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public  tradeInfo: any,
    private alertService: AlertService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.formUpdateEmailAddress = this.formBuilder.group({
      id: this.tradeInfo.id,
      email: [ this.tradeInfo.email, [Validators.required, Validators.pattern('[A-Z0-9a-z_.%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}')]]
    });
  }

    // convenience getter for easy access to form fields
    get f() { return this.formUpdateEmailAddress.controls; }

    onSubmit() {
      this.submitted = true;
      
      // If invalid
      if (this.formUpdateEmailAddress.invalid) {
        return;
      }
      
      this.updateEmail(this.tradeInfo.id, this.formUpdateEmailAddress.value.email)
    }

    private updateEmail(userId: string, email: string) {
      this.isLoading = true;
      this.userService.updateEmailByAdmin(userId, email).subscribe( response => {
        if (response.success) {
          this.alertService.success('Email address updated successfully');
          this.dialogRef.close(response.data);
        } else {
          this.alertService.error(response.error.message);
        }
        this.isLoading = false;
      }, error => {
        this.isLoading = false;
        this.alertService.error('Operation failed');
      });
    }
}
