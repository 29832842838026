import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AlertService } from 'src/app/modules/shared/services/alert/alert.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private authenticationService: AuthService,
    private alertService: AlertService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(catchError( errorResponse => {

      if (errorResponse.status === 401) {
        // auto logout if 401 response returned from api
        this.authenticationService.logout();
      }

      if (errorResponse.error.title) {
        this.alertService.error(errorResponse.error.title);
      } else {
        this.alertService.error('Network Connection error');
      }

      if (errorResponse.status === 0) {
        this.alertService.error('Network Connection error');
        // location.reload(true);
      }

      const error = errorResponse.error.message || errorResponse.statusText;
      return throwError(error);
    }))
  }
}
