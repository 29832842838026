import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as AWS from 'aws-sdk';
import { AuthService } from 'src/app/core/auth/auth.service';
import { DocumentCategoryType, DocumentGroup, DocumentStatus } from 'src/app/core/enums/document.enum';
import { AlertService } from 'src/app/modules/shared/services/alert/alert.service';
import { environment } from 'src/environments/environment';
import { IndemnityCreateUploadModel, IndemnityDocumentDetailModel, IndemnityUpdateUploadModel } from '../indemnity-files.model';
import { IndemnityFilesService } from '../indemnity-files.service';

@Component({
  selector: 'app-indemnity-file-upload-popup',
  templateUrl: './indemnity-file-upload-popup.component.html',
  styleUrls: ['./indemnity-file-upload-popup.component.scss'],
  providers: [DatePipe]
})
export class IndemnityFileUploadPopupComponent implements OnInit {

  formNewdoc!: FormGroup;
  documentGroup = DocumentGroup;
  documentType = DocumentCategoryType;
  documentStatus !: DocumentStatus;

  submitted = false;
  isLoading = false;
  hasExpiry = false;
  isOtherDoc = false;

  formData!: FormData;
  reader!: FileReader;
  fileData!: File;
  fileName!: string;
  currentURL!: string;
  imgURL: any;
  imagePath!: string;
  currentUserId!: string;
  minExpireData = new Date();

  indemnityCreateUploadModel!: IndemnityCreateUploadModel;
  indemnityUpdateUploadModel!: IndemnityUpdateUploadModel;
  indemnityDocumentDetailModel !: IndemnityDocumentDetailModel;

  constructor(
    private alertService: AlertService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private indemnityService: IndemnityFilesService,
    @Inject(MAT_DIALOG_DATA) public data: { isUpdate: boolean, documentData: any, userId: string, businessInfoId: string },
    public dialogRef: MatDialogRef<IndemnityFileUploadPopupComponent>
  ) { }

  ngOnInit(): void {
    this.currentUserId = this.authService.currentUserValue.userId;

    this.formNewdoc = this.formBuilder.group({
      businessInfoId: [this.data.businessInfoId],
      userId: [this.data.userId],
      name: ['', Validators.required],
      link: ['', Validators.required],
      documentType: ['', Validators.required],
      expiryDate: [''],
      professionalIndemnity: ['', [Validators.pattern('^\-?[0-9]+(?:\.[0-9]{1,2})?$'), Validators.max(21474836.00)]],
    });

    if (this.data.isUpdate) {
      this.setDocumentUpdateFormData(this.data.documentData);
    }
  }

  // Upload selected image
  onSubmit() {
    this.isLoading = true;
    this.submitted = true;
    // If invalid
    if (this.formNewdoc.invalid) {
      this.isLoading = false;
      return;
    }

    if (this.data.documentData?.link === this.formNewdoc.value.link) {
      this.saveForm(); // Updating & not updating the file
    } else {
      this.uploadDocToS3(); // updating the file
    }
  }

  get f() {
    return this.formNewdoc.controls;
  }

  saveForm() {
    if (this.currentUserId) {
      if (this.data.isUpdate) {
        this.updateBusinessDocument(this.formNewdoc.getRawValue());
      } else {
        this.createBusinessDocument(this.formNewdoc.getRawValue());
      }
    } else {
      this.dialogRef.close(false);
    }
  }

  // Create business document
  private createBusinessDocument(dataModel: any) {

    this.setCreateModuleData(dataModel);
    this.isLoading = true;
    this.indemnityService.createIndemnityDocument(this.indemnityCreateUploadModel)
      .subscribe({
        next: (response: any) => {
          if (response.success) {
            this.alertService.success('Document created successfully');
            this.dialogRef.close(response);

          } else {
            this.alertService.error(response.error.message);
          }
          this.isLoading = false;
        },
        error: (error: any) => {
          this.alertService.error('Operation failed!');
        },
      });
  }

  setCreateModuleData(dataModel: any) {

    this.indemnityDocumentDetailModel = new IndemnityDocumentDetailModel();
    this.indemnityCreateUploadModel = new IndemnityCreateUploadModel();

    this.indemnityDocumentDetailModel.documentName = dataModel.name;
    this.indemnityDocumentDetailModel.link = dataModel.link;
    this.indemnityDocumentDetailModel.expireDate = dataModel.expiryDate;
    this.indemnityDocumentDetailModel.documentType = dataModel.documentType;
    this.indemnityDocumentDetailModel.documentStatus = DocumentStatus.Processing;
    this.indemnityDocumentDetailModel.professionalIdemnityDocument = true;
    this.indemnityDocumentDetailModel.isArchived = true;
    this.indemnityDocumentDetailModel.professionalIndemnity = dataModel.professionalIndemnity ? dataModel.professionalIndemnity * 100 : 0;
    this.indemnityCreateUploadModel.addIndemnityDocumentModel(this.indemnityDocumentDetailModel);
    this.indemnityCreateUploadModel.businessInfoId = this.data.businessInfoId;
    this.indemnityCreateUploadModel.isArchived = true;
    this.indemnityCreateUploadModel.renewProfessionalIdemnityInsurance = false;
  }

  // Edit business document
  private updateBusinessDocument(dataModel: any) {
    this.setUpdateModuleData(dataModel)
    this.indemnityService
      .updateIndemnityDocument(this.indemnityUpdateUploadModel).subscribe({
        next: (response: any) => {
          if (response.success) {
            this.alertService.success('Document updated successfully');
            this.dialogRef.close(response);
          } else {
            this.alertService.error(response.error.message);
          }
          this.isLoading = false;
        },
        error: (error: any) => {
          this.alertService.error('Operation failed!');
        },
      });
  }

  setUpdateModuleData(dataModel: any) {
    this.indemnityDocumentDetailModel = new IndemnityDocumentDetailModel();
    this.indemnityUpdateUploadModel = new IndemnityUpdateUploadModel();

    this.indemnityDocumentDetailModel.id = dataModel.id;
    this.indemnityDocumentDetailModel.documentName = dataModel.name;
    this.indemnityDocumentDetailModel.documentStatus = dataModel.documentStatus;
    this.indemnityDocumentDetailModel.link = dataModel.link;
    this.indemnityDocumentDetailModel.expireDate = dataModel.expiryDate;
    this.indemnityDocumentDetailModel.documentType = dataModel.documentType;
    this.indemnityDocumentDetailModel.documentStatus = DocumentStatus.Processing;
    this.indemnityDocumentDetailModel.professionalIdemnityCompliancesHeaderId = this.data.documentData.professionalIdemnityCompliancesHeaderId;
    this.indemnityDocumentDetailModel.professionalIdemnityDocument = true;
    this.indemnityDocumentDetailModel.professionalIndemnity = dataModel.professionalIndemnity ? dataModel.professionalIndemnity * 100 : 0;
    this.indemnityUpdateUploadModel.addIndemnityDocumentModel(this.indemnityDocumentDetailModel);
    this.indemnityUpdateUploadModel.businessInfoId = this.data.businessInfoId;
    this.indemnityUpdateUploadModel.id = this.data.documentData.professionalIdemnityCompliancesHeaderId;
    this.indemnityUpdateUploadModel.isArchived = true;
    this.indemnityUpdateUploadModel.renewProfessionalIdemnityInsurance = true;
  }

  setDocumentUpdateFormData(document: any) {
    this.formNewdoc.addControl('id', new FormControl(document.id));
    this.formNewdoc.controls['name'].setValue(document?.documentName);
    const expDate = document?.expireDate ? new Date(document?.expireDate) : null
    this.formNewdoc.controls['expiryDate'].setValue(expDate);
    this.formNewdoc.controls['userId'].setValue(document.userId);
    this.formNewdoc.controls['documentType'].setValue(document.documentType);
    this.formNewdoc.controls['link'].setValue(document.link);
    this.formNewdoc.controls['professionalIndemnity'].setValue(document.professionalIndemnity ? document.professionalIndemnity / 100 : 0);
  }

  uploadDocToS3() {
    this.formData = new FormData();
    this.formData.append('file', this.fileData);

    const fileName = this.getFileNameByCategory(
      this.formNewdoc.value.name
    );
    const contentType = this.fileData.type;
    const fileExtension = this.fileData.name.split('.').pop();

    const bucket = new AWS.S3({
      accessKeyId: environment.config.keyId,
      secretAccessKey: environment.config.key,
      region: environment.config.region,
    });

    const fileLocation = `Trade/${this.data.businessInfoId}/Documents/Professional_Indemnity/` + fileName + '.' + fileExtension

    const params = {
      Bucket: environment.config.location,
      Key: fileLocation,
      Body: this.fileData,
      ACL: 'private',
      ContentType: contentType,
    };

    bucket.upload(params, (error: any, response: any) => {
      if (error) {
        this.alertService.error('Operation failed!');
        return false;
      }

      this.formNewdoc.patchValue({
        link: response.Key,
      });

      this.saveForm();
      return true;
    });
  }

  // Browse file/document and preview.
  fileProgress(event: any) {
    this.reader = new FileReader();

    this.fileName = '';
    this.fileData = event.target.files[0];
    this.fileName = event.target.files[0].name;

    this.formNewdoc.patchValue({
      link: this.fileName,
    });

    // set file extention / doc type
    const currrentDocType = this.getDocumentType(this.fileData.name)
    this.formNewdoc.controls['documentType'].setValue(currrentDocType);

    // For iamge preview
    this.imagePath = event.target.files;
    this.reader.readAsDataURL(event.target.files[0]);
    this.reader.onload = (_event) => {
      this.imgURL = this.reader.result;
    };
  }

  // Generate File Name
  getFileNameByCategory(name: string) {
    const timePrefix = this.datePipe.transform(new Date(), 'yyyyMMddhhmmss');
    return name ? name.split(' ').join('_').toLocaleLowerCase() + '_' + timePrefix : '';
  }

  // Convert Date
  convertDate(date: string) {
    return date ? this.datePipe.transform(date, 'yyyy-MM-dd') : null
  }

  // Get documentType form name
  getDocumentType(link: string) {
    const currentLink = link.toLowerCase()
    const extention = currentLink.split('.').pop()
    let currentDocumentType
    switch (extention) {
      case 'pdf':
        currentDocumentType = this.documentType.PDF
        break;
      case 'doc':
        currentDocumentType = this.documentType.DOC
        break;
      case 'docx':
        currentDocumentType = this.documentType.DOC
        break;
      case 'png':
        currentDocumentType = this.documentType.PNG
        break;
      case 'jpeg':
        currentDocumentType = this.documentType.JPEG
        break;
      case 'jpg':
        currentDocumentType = this.documentType.JPEG
        break;
    }
    return currentDocumentType
  }

  clearDate() {
    this.formNewdoc.controls['expiryDate'].setValue(null);
  }
}