import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DocumentCategory, DocumentGroup, DocumentStatus } from 'src/app/core/enums/document.enum';
import { AlertService } from 'src/app/modules/shared/services/alert/alert.service';
import { UploadService } from 'src/app/modules/shared/services/upload/upload.service';
import { SharedConfirmationPopupComponent } from '../../../popups/shared-confirmation-popup/shared-confirmation-popup.component';
import { LiabilityFileUploadPopupComponent } from '../liability-file-upload-popup/liability-file-upload-popup.component';
import { LiabilityDocumentListModel } from '../liability-files.model';
import { LiabilityFilesService } from '../liability-files.service';


@Component({
  selector: 'app-liability-file-list-widget',
  templateUrl: './liability-file-list-widget.component.html',
  styleUrls: ['./liability-file-list-widget.component.scss']
})
export class LiabilityFileListWidgetComponent implements OnInit {

  @Input() businessInfoId!: string
  @Input() hideStatus: boolean = false 
  @Input() isEditable: boolean = true 
  @Output() getDocumentListEvent = new EventEmitter<LiabilityDocumentListModel[]>();

  isLoading = false
  documentGroup = DocumentGroup
  documentStatus = DocumentStatus
  documentCategory = DocumentCategory

  liabilityDocuments: LiabilityDocumentListModel[] = []

  constructor(
    private alertService: AlertService,
    private uploadService: UploadService,
    public dialog: MatDialog,
    private liabilityDocumentService: LiabilityFilesService
  ) { }

  ngOnInit(): void {
    this.getLiabilityDocuments(this.businessInfoId)
  }

  uploadLiabilityDocument() {
    const dialogRef = this.dialog.open( LiabilityFileUploadPopupComponent, {
      maxWidth: '640px',
      width: '50%',
      position : {top: '60px'},
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      closeOnNavigation: true,
      disableClose: true,
      data: {
        isUpdate: false, 
        documentData: null, 
        businessInfoId: this.businessInfoId 
      },
    });

    dialogRef.afterClosed().subscribe( (data) => {
      if (data) {
        this.liabilityDocuments.unshift(data)
        this.getDocumentListEvent.emit(this.liabilityDocuments)
      }
    });
  }

  updateLiabilityDocument(liabilityDocument: LiabilityDocumentListModel) {
    const dialogRef = this.dialog.open( LiabilityFileUploadPopupComponent, {
      maxWidth: '640px',
      width: '50%',
      position : {top: '60px'},
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      closeOnNavigation: true,
      disableClose: true,
      data: {
        isUpdate: true, 
        documentData: liabilityDocument, 
        businessInfoId: this.businessInfoId 
      },
    });

    dialogRef.afterClosed().subscribe( (data) => {
      if (data) {
        this.updateListItem(data)
      }
    });
  }

  removeDocumentConfirm(liabilityDocument: LiabilityDocumentListModel) {
    const dialogRef = this.dialog.open( SharedConfirmationPopupComponent, {
      maxWidth: '500px',
      width: '90%',
      position: {
        top: '60px',
      },
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      closeOnNavigation: true,
      disableClose: true,
      data: {
        title: 'Please Confirm',
        message: 'Are you sure you want to delete this document?',
        confirmTxt: 'Yes, Delete',
        cancelTxt: 'Cancel',
      },
    });

    dialogRef.afterClosed().subscribe( success => {
      if (success) {
        const isArchived = true
        this.deleteLiabilityDocument(liabilityDocument, isArchived)
      }
    });
  }

  // Download Document
  downloadDocument(key: string, name: string) {
    const documentUrl = this.uploadService.getUrlByS3KEY(key);
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', documentUrl);
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  updateListItem(data: LiabilityDocumentListModel) {
    const index = this.liabilityDocuments.findIndex(x => x.id === data.id);
    this.liabilityDocuments[index] = data
  }

  removeFromList(id: string) {
    const index = this.liabilityDocuments.findIndex(x => x.id === id);
    if (index > -1) {
      this.liabilityDocuments.splice(index, 1);
    }
    this.getDocumentListEvent.emit(this.liabilityDocuments)
  }

  private deleteLiabilityDocument(liabilityDocument: LiabilityDocumentListModel, isArchived: boolean) {
    this.isLoading = true;
    this.liabilityDocumentService.deletePublicLiability(liabilityDocument.id, isArchived).subscribe({
      next: (result: any) => {
        if (result.success) {
          this.alertService.success('Document deleted successfully!');
          this.removeFromList(liabilityDocument.id)
        } else {
          this.alertService.error(result.error.message);
        }
        this.isLoading = false;
      }, error: err => {
        this.alertService.error('Operation failed!');
        this.isLoading = false;
      }
    });
  }

  private getLiabilityDocuments(businessInfoId: string) {
    this.isLoading = true;
    this.liabilityDocumentService.getPublicLiabilityListById(businessInfoId).subscribe({
      next: (result: any) => {
        if (result.success) {
          this.liabilityDocuments = result.data
          this.getDocumentListEvent.emit(this.liabilityDocuments)
        }
        this.isLoading = false;
      }, error: err => {
        this.alertService.error('Operation failed!');
        this.isLoading = false;
      }
    });
  }
}
