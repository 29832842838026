import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ServiceCategory } from 'src/app/modules/onboarding/shared/models/service-category/service-category.model';

@Component({
  selector: 'app-shared-select-category-widget',
  templateUrl: './shared-select-category-widget.component.html',
  styleUrls: ['./shared-select-category-widget.component.scss']
})
export class SharedSelectCategoryWidgetComponent implements OnInit {

  @ViewChild('categorySelect') categorySelectInput!: ElementRef;
  @ViewChild('catSelecter') catSelecter!: ElementRef;
  @Input() serviceCategoriesList!: ServiceCategory[];
  @Input() currentCategory?: string;
  @Input() isViewOnly?: boolean;
  @Output() categoryEvent = new EventEmitter<string>();
  selectedCategory!: ServiceCategory;

  categoryCtrl = new FormControl();
  filteredCategories: Observable<ServiceCategory[]>;
  isSearchVisible = false;
  noResult = false;

  constructor() {
    this.filteredCategories = this.categoryCtrl.valueChanges.pipe(
      startWith(''),
      map((cat) =>
        cat ? this._filterStates(cat) : this.serviceCategoriesList.slice()
      )
    );
  }

  ngOnInit(): void {
    if (this.currentCategory) {
      this.selectedCategory = this.findCategoryById(
        this.currentCategory,
        this.serviceCategoriesList
      );
      this.categoryCtrl.setValue(this.selectedCategory);
    }
  }

  showSerchBox() {
    this.isSearchVisible = true;
    setTimeout(() => {
      // this will make the execution after the above boolean has changed
      this.categorySelectInput.nativeElement.focus();
    }, 0);
  }

  // Close category selection on click of out side of widget (component)
  @HostListener('document:click', ['$event'])
  clickOut(event: any) {
    if (!this.catSelecter?.nativeElement.contains(event.target)) {
      this.isSearchVisible = false;
    }
  }

  onServiceCategorySelect(event: any) {
    this.selectedCategory = event.option.value;
    this.isSearchVisible = false;
    this.categoryEvent.emit(this.selectedCategory.id);
    // Reset the value of the input
    this.categoryCtrl.setValue('');
  }

  clearAutocomplete() {
    // Reset the value of the input
    this.categoryCtrl.setValue('');
    // setTimeout(() => {
    //   // this will make the execution after the above boolean has changed
    //   this.categorySelectInput.nativeElement.focus();
    // }, 0);
  }

  private _filterStates(value: string): ServiceCategory[] {
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();
      const filtered = this.serviceCategoriesList.filter(
        (cat) => cat.name.toLowerCase().indexOf(filterValue) === 0
      );
      if (filtered.length == 0 && filterValue.length > 0) {
        this.noResult = true;
      } else {
        this.noResult = false;
      }
      return filtered;
    } else {
      return []
    }
  }

  // Set display text of selected search result
  displayFn(category: ServiceCategory): string {
    return category?.name ? category.name : '';
  }

  findCategoryById(id: string, catList: ServiceCategory[]): ServiceCategory {
    return catList.find((e) => e.id === id)!;
  }
}
