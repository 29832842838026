export class User {
  id!: string;
  createdDate!: string;
  email!: string;
  firstName!: string;
  lastName!: string;
  isActive!: boolean;
  isArchived!: boolean;
  lastUpdatedBy!: string;
  lastUpdatedDate!: string;
  mobileNumber!: string;
  profession!: string;
  profileImageUrl!: string;
  userAddresses!: UserAddress[];
  userType!: number;
}

export class UserUpdatePassword {
  userId!: string;
  oldPassword!: string;
  newPassword!: string;
}

export class UserUpdateName {
  userId!: string;
  firstName!: string;
  lastName!: string;
}

export class UserAddress {
  id!: string;
  userId!: string;
  unitNumber!: string;
  streetName!: string;
  city!: string;
  state!: string;
  zipCode!: string;
  isArchived!: boolean;
  createdDateTime!: string;
  lastUpdatedDate!: string;
}

export interface UpdateUserAddress {
  id: string;
  userId: string;
  type: number;
  streetName: string;
  unitNumber: string;
  city: string;
  state: string;
  zipCode: string;
}

export interface ImageUpdateModel {
  title: string;
  description: string;
  currentImageUrl: string;
  s3Location: string;
  fileName: string;
}

export interface DocumentUpdateModel {
  hasUserId: boolean;
  isBusinessDocument?: boolean;
  isUpdate?: boolean;
  document?: Document;
  userId?: string;
  businessInfoId?: string;
  
  title: string;
  s3Location: string;
  fileName: string;
}

export class UpdateProfileImage {
  userId!: string;
  profileUrl!: string;
}
