import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shared-terms-conditions',
  templateUrl: './shared-terms-conditions.component.html',
  styleUrls: ['./shared-terms-conditions.component.scss']
})
export class SharedTermsConditionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
