<div class="d-flex justify-content-between align-items-center m-0 px-4 py-3">
  <span class="title font-bold mb-0">Update Address</span>
  <button mat-icon-button matDialogClose><mat-icon >cancel</mat-icon></button>
</div>

<mat-divider></mat-divider>

<div class="popup-wr p-4 d-flex justify-content-center">
  <form class="row mt-0" [formGroup]="formUpdateAddress" (ngSubmit)="onSubmit()">
      <div class="col-6 mb-3">
        <mat-form-field class="w-100" appearance="legacy">
            <mat-label>Unit Number</mat-label>
            <input formControlName="unitNumber" matInput placeholder="Please type" required>
              <mat-error *ngIf="f['unitNumber'].errors?.['required']">
                Please enter unit number.
            </mat-error>
        </mat-form-field>
      </div>

      <div class="col-6 mb-3">
          <mat-form-field class="w-100" appearance="legacy">
              <mat-label>Street Name</mat-label>
              <input formControlName="streetName" matInput placeholder="Please type" required>
              <mat-error *ngIf="f['streetName'].errors?.['required']">
                Please enter street name.
              </mat-error>
          </mat-form-field>
      </div>


      <div class="col-6 mb-3">
          <mat-form-field class="w-100" appearance="legacy">
              <mat-label>City</mat-label>
              <input formControlName="city" matInput placeholder="Please type" required>
              <mat-error *ngIf="f['city'].errors?.['required']">
                Please enter city.
              </mat-error>
          </mat-form-field>
      </div>

      <div class="col-6 mb-3">
          <mat-form-field class="w-100" appearance="legacy">
              <mat-label>State</mat-label>
              <input formControlName="state" matInput placeholder="Please type" required>
              <mat-error *ngIf="f['state'].errors?.['required']">
                Please enter state.
              </mat-error>
          </mat-form-field>
      </div>

      <div class="col-6 mb-3">
          <mat-form-field class="w-100" appearance="legacy">
              <mat-label>ZIP Code</mat-label>
              <input formControlName="zipCode" matInput placeholder="Please type" required>
              <mat-error *ngIf="f['zipCode'].errors?.['required']">
                Please enter zip Code.
              </mat-error>
          </mat-form-field>
      </div>

      <div class="col-12 action-btns text-right w-100 mt-3">
          <div class="d-flex align-items-center justify-content-end">
              <button class="btn primary shadow-none" mat-raised-button [disabled]="isLoading">Update</button>
              <mat-spinner class="ms-2" diameter="25" *ngIf="isLoading"></mat-spinner>
          </div>
      </div>
  </form>
</div>









