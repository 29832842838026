<div class="d-flex justify-content-between align-items-center m-0">
  <span *ngIf="!bankData.isUpdate" class="example-spacer py-3 ps-4">Add Bank Account</span>
  <span *ngIf="bankData.isUpdate" class="example-spacer py-3 ps-4">Update Bank Account</span>
  <button class="close-icon me-2" mat-icon-button mat-dialog-close><mat-icon>cancel</mat-icon></button>
</div>
<mat-divider></mat-divider>
<div class="popup-wr px-4 py-3">
  <form class="mt-0 row" [formGroup]="formBankAccount" (ngSubmit)="onSubmit()">
      <div class="col-lg-6 mb-3">
          <mat-form-field class="w-100" appearance="legacy">
              <mat-label>Bank name</mat-label>
              <input formControlName="bank" matInput placeholder="Please type">
              <mat-error *ngIf="f['bank'].errors?.['required']">
                  Please enter bank name.
              </mat-error>
          </mat-form-field>
      </div>
      <div class="col-lg-6 mb-3">
          <mat-form-field class="w-100" appearance="legacy">
              <mat-label>Account holder's name</mat-label>
              <input formControlName="accountName" matInput placeholder="Please type">
              <mat-error *ngIf="f['accountName'].errors?.['required']">
                  Please enter account holder's name.
              </mat-error>
          </mat-form-field>
      </div>
      <div class="col-lg-6 mb-4">
          <mat-form-field class="w-100" appearance="legacy">
              <mat-label>Account number</mat-label>
              <input formControlName="accountNumber" matInput placeholder="Please type">
              <mat-error *ngIf="f['accountNumber'].errors?.['required']">
                  Please enter account number.
              </mat-error>
              <mat-error *ngIf="f['accountNumber'].errors?.['pattern'] && !f['accountNumber'].errors?.['required']">
                  Please enter valid account number (6 - 9 digits).
              </mat-error>
          </mat-form-field>
      </div>
      <div class="col-lg-6 mb-3">
          <mat-form-field class="w-100" appearance="legacy">
              <mat-label>BSB</mat-label>
              <input formControlName="bsbNumber" matInput placeholder="Please type">
              <mat-error *ngIf="f['bsbNumber'].errors?.['required']">
                  Please enter bsb.
              </mat-error>
              <mat-error *ngIf="f['bsbNumber'].errors?.['pattern'] && !f['bsbNumber'].errors?.['required']">
                  Please enter valid bsb (6 digits).
              </mat-error>
          </mat-form-field>
      </div>
      <div class="action-btns text-right w-100 mt-1 mr-2 mb-3 d-flex align-items-center justify-content-end">
          <button class="btn add-btn primary shadow-none w-25" mat-raised-button [disabled]="isLoading">Done</button>
          <mat-spinner class="ms-3" diameter="25" *ngIf="isLoading"></mat-spinner>
      </div>
  </form>
</div>
