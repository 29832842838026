<div class="d-flex justify-content-between align-items-center m-0">
  <span class="title font-bold py-3 ps-4">{{ data.title ? data.title : 'Update Answer'}}</span>
  <button class="close-icon me-2" mat-icon-button (click)="close()"><mat-icon>cancel</mat-icon></button>
</div>
<mat-divider></mat-divider>
<div class="popup-wr px-4 py-3">
  <p>{{data.question}}</p>

  <div>
    <mat-radio-group>
      <mat-radio-button 
        [value]="1"
        [checked]="data.isTrue"
        (change)="answerOnChange($event)"
        class="text-md me-3">
        <span>Yes</span>
      </mat-radio-button>
      <mat-radio-button 
        [value]="0"
        [checked]="!data.isTrue"
        (change)="answerOnChange($event)"
        class="text-md me-3">
        <span>No</span>
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <div class="actions text-end mt-3">
    <button class="btn add-btn primary shadow-none w-25" [disabled]="!isUpdated" (click)="save()" mat-raised-button>Save</button>
  </div>  
</div>

