<article *ngIf="widgetStyleType === uploadWidgetStyleType.browseType">
  <div *ngIf="!isDisabled" class="browse-wr pointer" (click)="imageCropperPopup()">
  <!-- <div *ngIf="!isDisabled" class="browse-wr pointer" (click)="singleFileInput.click()"> -->
    <div class="border-bottom d-flex justify-content-between pb-2">
      <span class="label font-semi-bold">{{singleFileName ? singleFileName : 'Please upload'}}</span>
      <span *ngIf="!isLoading" class="font-bold text-color primary text-uppercase text-md">[ Browse ]</span>
      <mat-spinner class="ms-2" diameter="25" *ngIf="isLoading"></mat-spinner>
    </div>
  </div>
  <div *ngIf="isDisabled" class="browse-wr">
    <div class="border-bottom d-flex justify-content-between pb-2">
      <span class="text-md text-color disabled">{{currentFile ? '' : 'Please upload'}}</span>
      <span *ngIf="!isLoading" class="font-bold text-color disabled text-uppercase text-md">[ Browse ]</span>
      <mat-spinner class="ms-2" diameter="25" *ngIf="isLoading"></mat-spinner>
    </div>
  </div>
</article>


<article class="document-inner-wr" *ngIf="widgetStyleType === uploadWidgetStyleType.generalType">
  <section 
      *ngIf="!currentFile" 
      class="file-upload-wr d-flex align-items-center justify-content-center py-3 custom-border dashed" 
      (click)="singleFileInput.click()">
      <img class="upload-icon" src="/assets/icons/common/cloud-upload.svg">
      <span class="text-color primary font-semi-bold ms-2">Please upload</span>
  </section>

  <section 
    *ngIf="currentFile" 
    class="document d-flex align-items-center py-2 px-3 border">
    <img class="icon" src="/assets/icons/common/document.svg">
    <div class="ms-2">
        <div class="title mb-1 d-flex align-items-center">
            <span class="font-bold lh-1 me-2">{{title}} Document</span>

            <span *ngIf="status">
              <app-shared-document-status-widget [currentStatus]="status"></app-shared-document-status-widget>
          </span>
        </div>
    </div>
    <div class="actions d-flex align-items-center ms-auto">
        <span class="action-btn me-2">
            <button mat-icon-button (click)="downloadDocument(currentFile, title)" class="ketab-btn download d-flex justify-content-center align-items-center">
                <mat-icon>file_download</mat-icon>
            </button>
        </span>
        
        <span *ngIf="status !== 6 && status !== 2" >
        <span class="action-btn">
            <button mat-icon-button [matMenuTriggerFor]="menu" class="ketab-btn d-flex justify-content-center align-items-center">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="updateDocument()"><span>Update</span></button>
                <button mat-menu-item (click)="removeDocumentConfirm()"><span>Remove</span></button>
            </mat-menu>
        </span>
        </span>
    </div>
  </section>

  <section *ngIf="isLoading" class="inner-preloader d-flex align-items-start justify-content-center">
      <!-- <mat-spinner diameter="35"></mat-spinner> -->
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </section>
</article>


<input
  hidden
  type="file"
  [accept]="acceptableFiles"
  name="singleFile"
  #singleFileInput
  (change)="onFileProgress($event)"
/>
