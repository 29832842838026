<div class="d-flex justify-content-between align-items-center m-0 ps-4 pe-2 py-3">
    <p class="popup-header mb-0 mb-0">Add New Member</p>
    <button mat-icon-button><mat-icon matDialogClose>cancel</mat-icon></button>
</div>
  
<mat-divider></mat-divider>
<div class="">
    <h3 class="area-title text-uppercase font-bold mb-2 ps-4 pe-2 py-3">General Details</h3>
</div>
  
  <div class="popup-wr p-4 d-flex justify-content-center">
    <form class="row mt-0" [formGroup]="formUpdateTeamMember" (ngSubmit)="onSubmit()">
  
        <div class="col-4 mb-3">
            <mat-form-field class="w-100" appearance="legacy">
                <mat-label>First name</mat-label>
                <input formControlName="firstName" matInput placeholder="Please type">
                <mat-error *ngIf="f['firstName'].errors?.['required']">
                  Please enter first name.
                </mat-error>
                <mat-error *ngIf="!f['firstName'].errors?.['required'] && f['firstName'].errors?.['pattern']">
                  Please enter valid name.
                </mat-error>
            </mat-form-field>
        </div>
  
        <div class="col-4">
            <mat-form-field class="w-100" appearance="legacy">
                <mat-label>Last Name</mat-label>
                <input formControlName="lastName" matInput placeholder="Please type">
                <mat-error *ngIf="f['lastName'].errors?.['required']">
                  Please enter last name.
                </mat-error>
                <mat-error *ngIf="!f['lastName'].errors?.['required'] && f['lastName'].errors?.['pattern']">
                  Please enter valid name.
                </mat-error>
            </mat-form-field>
        </div>

        <div class="col-4">
            <mat-form-field class="w-100" appearance="legacy">
                <mat-label>Email Address</mat-label>
                <input matInput placeholder="Email address" formControlName="email">
                <mat-error *ngIf="f['email'].errors?.['pattern'] && !f['email'].errors?.['required']">
                    Please enter a valid email address.
                </mat-error>
                <mat-error *ngIf="f['email'].errors?.['required']">
                    Please enter email address. 
                </mat-error>
            </mat-form-field>
        </div>

        <div  class="col-6">
            <mat-form-field class="w-100" appearance="legacy">
                <mat-label>Mobile No</mat-label>
                <input cdkFocusInitial matInput formControlName="mobileNumber"  class="p-0" required>
                
                <mat-error *ngIf="f['mobileNumber'].errors?.['pattern'] && !f['mobileNumber'].errors?.['required']">
                Please enter a valid mobile number.
                </mat-error>
                <mat-error *ngIf="f['mobileNumber'].errors?.['required']">
                Please enter mobile number.
                </mat-error>
            </mat-form-field>
        </div>

        <div class="col-6">
            <mat-form-field class="w-100 pointer" appearance="legacy" (click)="picker.open()">
              <mat-label>Date of Birth</mat-label>
              <input class="pointer p-0" matInput [matDatepicker]="picker" formControlName="dateOfBirth" required readonly>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <!-- <mat-datepicker #picker touchUi="true"></mat-datepicker> -->
              <mat-error *ngIf="f['dateOfBirth'].errors?.['required']">
                Please select date.
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-6">
            <mat-form-field class="w-100" appearance="legacy">
                <mat-label>User Role</mat-label>
                <mat-select formControlName="tradieRole" required class="p-0" (selectionChange)="tradieRoleChange($event)">
                    <mat-option *ngFor="let type of tradieRoleList" [value]="type.value">
                    {{type.viewValue}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="f['tradieRole'].errors?.['required']">
                    Please select business role.
                </mat-error>
            </mat-form-field>
          </div>

          <div class="col-6" *ngIf="isShowPost">
            <mat-form-field class="w-100" appearance="legacy">
                <mat-label>Business Role</mat-label>
                <mat-select formControlName="post" class="p-0">
                    <mat-option *ngFor="let type of postList" [value]="type.value">
                    {{type.viewValue}}
                    </mat-option>
                </mat-select>
                <!-- <mat-error *ngIf="f['post'].errors?.['required']">
                    Please select post.
                </mat-error> -->
            </mat-form-field>
          </div>
  
        <div class="col-12 action-btns text-right w-100 mt-3">
            <div>
                <h3 class="area-title text-uppercase font-bold mb-2 ps-0 pe-0 py-3">Account Details</h3>
            </div>
            <div class="d-flex align-items-center">
                <p style="max-width: 639px;" class="mb-4 ps-0 pe-0 py-0">Account will be created with above email address. Password will be auto generated & send along with the invitation via email. Please upload required documents once the user added.</p>
                <div class="d-flex align-items-center justify-content-end">
                    <button class="btn primary shadow-none" mat-raised-button [disabled]="isLoading">Submit</button>
                    <mat-spinner class="ms-2" diameter="25" *ngIf="isLoading"></mat-spinner>
                </div>
                
            </div>
        </div>
    </form>
  </div>
  
  
  
  
  
  
  
  
  
  