import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { JobMediaType, MediaPostTime } from 'src/app/core/enums/main.enum';
import { FileUpload } from '../../../models/file-upload.model';
import * as AWS from 'aws-sdk';
import { AlertService } from '../../../services/alert/alert.service';
import { UploadWidgetStyleType } from 'src/app/core/enums/custom.enum';
import { UploadService } from '../../../services/upload/upload.service';
import { SharedConfirmationPopupComponent } from '../../popups/shared-confirmation-popup/shared-confirmation-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { DocumentStatus } from 'src/app/core/enums/document.enum';
import { SharedImageCropperPopupComponent } from '../../popups/shared-image-cropper-popup/shared-image-cropper-popup.component';

@Component({
  selector: 'app-shared-file-upload-widget',
  templateUrl: './shared-file-upload-widget.component.html',
  styleUrls: ['./shared-file-upload-widget.component.scss'],
  providers: [DatePipe],
})
export class SharedFileUploadWidgetComponent implements OnInit, OnChanges {

  uploadWidgetStyleType = UploadWidgetStyleType
  
  @Input() title!: string;
  @Input() updatedAt!: string;
  @Input() status?: number;
  @Input() fileLocation: string = 'User/Documents/';
  @Input() fileNamePrefix: string = 'Test';
  @Input() isFullUrl!: boolean;
  @Input() isPrivate!: boolean;
  @Input() isImageOnly?: boolean;
  @Input() currentFile?: string;
  @Input() isDisabled!: boolean;
  @Input() widgetStyleType: number = this.uploadWidgetStyleType.browseType;

  @Output() getFileURLEvent = new EventEmitter<FileUpload>();
  @Output() deleteFileEvent = new EventEmitter<string>();
  @Output() setFileEvent = new EventEmitter<boolean>();

  @ViewChild('singleFileInput') fileInputRef!: ElementRef;

  submitted = false;
  isLoading = false;
  hasMedia = false;
  isReadonly = true;
  hasFile = false;

  // For upload images
  singleFileData!: any;
  singleFileName!: any;

  mediaObject = new FileUpload();
  mediaType = JobMediaType;
  mediaPostTime = MediaPostTime;

  formData!: FormData;
  reader!: FileReader;
  fileData!: File;
  fileName!: string;
  files: File[] = [];
  selectedFile!: FileUpload;
  imagePath!: string;

  DocumentStatus!: DocumentStatus;

  acceptableFiles = "image/png, image/jpeg, .pdf, .doc, .docx"

  constructor(
    private datePipe: DatePipe,
    private alertService: AlertService,
    private uploadService: UploadService,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    if (this.isImageOnly) {
      this.acceptableFiles ="image/png, image/jpeg"
    }
  }

  ngOnChanges(): void { }

  // Get selected file
  onFileProgress(event: any) {
    this.singleFileData = event.target.files[0];
    this.singleFileName = event.target.files[0].name;
    if (this.validateFileType(this.singleFileName)) {
      this.hasFile = true;
      this.setFileEvent.emit(this.hasFile);
      this.uploadMediaObject(this.singleFileData);
    } else {
      this.alertService.error('Please select an image')
      this.resetInput();
    }
  }

  // Upload selected media
  private uploadMediaObject(file: File) {
    this.isLoading = true;

    this.formData = new FormData();
    this.fileName = '';
    this.formData.append('file', file);

    const contentType = file.type;
    const fileName = this.getFileNameByCategory(this.fileNamePrefix);

    let fileExtension = file?.name?.split('.')?.pop();
    fileExtension = fileExtension ? fileExtension : 'png'

    const bucket = new AWS.S3({
      accessKeyId: this.convertKey(environment.config.keyId),
      secretAccessKey: this.convertKey(environment.config.key),
      region: environment.config.region,
    });

    const ACL = this.isPrivate ? 'private' : 'public-read';

    const params = {
      Bucket: environment.config.location,
      Key: this.fileLocation + fileName + '.' + fileExtension,
      Body: file,
      ACL: ACL,
      ContentType: contentType,
    };

    bucket.upload(params, (error: any, response: any) => {
      if (error) {
        return false;
      }
      
      const filelink = this.isFullUrl ? response.Location : response.Key;

      this.hasMedia = true;
      this.mediaObject.link = filelink;
      this.mediaObject.postTime = this.mediaPostTime.Initial;
      
      const imgObj = {
        link: filelink,
        mediaType: this.mediaObject.mediaType,
        postTime: this.mediaPostTime.Initial,
      };
      
      this.passFileUrlToParent(imgObj);
      this.isLoading = false;
      this.files = [];

      return true;
    });
  }

  validateFileType(fileName: string) {
    let ext = this.getExtension(fileName).toLowerCase();

    if (this.isImageOnly) {
      if (ext === 'png' || ext === 'jpg' || ext === 'jpeg') {
        this.mediaObject.mediaType = this.mediaType.Image;
        return true;
      }
    } else {
      if (ext === 'pdf') {
        this.mediaObject.mediaType = this.mediaType.PDF;
        return true;
      }
  
      else if (ext === 'doc' || ext === 'docx') {
        this.mediaObject.mediaType = this.mediaType.Word;
        return true;
      }
    } 

    return false;
  }

  resetInput() {
    this.singleFileData = null;
    this.singleFileName = null;
    this.fileInputRef.nativeElement.value = '';
  }

  getExtension(filename: string) {
    var parts = filename.split('.');
    return parts[parts.length - 1];
  }

  // Communicate with parent component
  passFileUrlToParent(mediaObject: any) {
    this.selectedFile = mediaObject;
    this.getFileURLEvent.emit(this.selectedFile);
  }

  getFileNameByCategory(name: string) {
    const timePrefix = this.datePipe.transform(new Date(), 'yyyyMMddhhmmss');
    return name.split(' ').join('_').toLocaleLowerCase() + '_' + timePrefix;
  }

  private convertKey(key: string) {
    const newkey = atob(key);
    return key;
    // return newkey;
  }

  // Download Document
  downloadDocument(key: string, name: string) {
    const documentUrl = this.uploadService.getUrlByS3KEY(key);
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', documentUrl);
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  removeDocumentConfirm() {
    const dialogRef = this.dialog.open( SharedConfirmationPopupComponent, {
      maxWidth: '500px',
      width: '90%',
      position: {
        top: '60px',
      },
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      closeOnNavigation: true,
      disableClose: true,
      data: {
        title: 'Please Confirm',
        message: 'Are you sure you want to delete this document?',
        confirmTxt: 'Yes, Delete',
        cancelTxt: 'Cancel',
      },
    });

    dialogRef.afterClosed().subscribe( success => {
      if (success) {
        const isDelete = true
        this.deleteFileEvent.emit(this.currentFile)
      }
    });
  }

  updateDocument() {
    this.fileInputRef.nativeElement.click()
  }

  imageCropperPopup() {
    const dialogRef = this.dialog.open(SharedImageCropperPopupComponent, {
      maxWidth: '980px',
      width: '90%',
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      closeOnNavigation: true,
      disableClose: true,
      data: null,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.uploadMediaObject(result)
      }
    });
  }
}
