import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { addTeamMemberModel, TeamListResponse, TradieUserResponse } from '../../models/team/team.model';

@Injectable({
  providedIn: 'root'
})
export class TeamService {

  private teamMember = environment.endpointURL + '/api/TeamMember';
  private tradie = environment.endpointURL + '/api/Tradie';

  constructor(private http: HttpClient) { }

  // Get Team Members By user Id
  public getTeamMembersByUserId(data: any): Observable<TeamListResponse> {
    const url = `${this.teamMember}/GetTeamMembers?userId=${data.userId}&Skip=${data.skip}&Take=${data.take}`;
    return this.http.get<TeamListResponse>(url);
  }

  // add Team Member
  public addTeamMember(data: addTeamMemberModel): Observable<any> {
    const url = `${this.teamMember}/AddMember`;
    return this.http.post<any>(url, data);
  }

  // Get Tradie By user Id
  public getTradieByUserId(userId: string): Observable<TradieUserResponse> {
    const url = `${this.tradie}?id=${userId}`;
    return this.http.get<TradieUserResponse>(url);
  }
}
