import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  private currentS3FileUploadSubject: BehaviorSubject<any>;
  public currentFile: Observable<any>;

  constructor() {
    this.currentS3FileUploadSubject = new BehaviorSubject<any>('default');
    this.currentFile = this.currentS3FileUploadSubject.asObservable();
  }

  public get currentFileResponse(): any {
    return this.currentS3FileUploadSubject.value;
  }

  // Get URl by s3 key
  public getUrlByS3KEY(s3Key: string) {

    AWS.config.update({
      accessKeyId: environment.config.keyId,
      secretAccessKey: environment.config.key
    });

    let s3 = new AWS.S3();

    const signedUrlExpireSeconds = 60 * environment.config.expireTimeInMinutes; // your expiry time in seconds.

    const url = s3.getSignedUrl('getObject', {
      Bucket: environment.config.location,
      Key: s3Key,
      Expires: signedUrlExpireSeconds
    })

    this.currentS3FileUploadSubject.next(url);
    return url;
  }

  // Delete image by s3 key
  public deleteFileByS3KEY(s3Key: string) {

    AWS.config.update({
      accessKeyId: environment.config.keyId,
      secretAccessKey: environment.config.key,
      region: environment.config.region,
    });

    let s3 = new AWS.S3();

    const data = s3.deleteObject({
      Bucket: environment.config.location,
      Key: s3Key
    },function (error, data) {
      if (error) {
        return false;
      }
      return true;
    })
    
    return data;
  }

  // Upload file to s3
  public uploadFile(fileData: File, fileLocation: string, fileName: string, ACL: string) : any {

    const contentType = fileData.type;
    const fileExtension = fileData.name.split('.').pop();

    const bucket = new AWS.S3({
      accessKeyId: environment.config.keyId,
      secretAccessKey: environment.config.key,
      region: environment.config.region,
    });

    const params = {
      Bucket: environment.config.location,
      Key: fileLocation + fileName + '.' + fileExtension,
      Body: fileData,
      ACL: ACL,
      ContentType: contentType,
    };

    bucket.upload( params, (error: any, response: any) => {
      if (error) {
        return { success: false, data: null, error: error};
      }
      return { success: true, data: response, error: null};
    });
  }
}