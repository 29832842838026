<div class="d-flex justify-content-between align-items-center m-0">
  <span *ngIf="preferrencesData.set === 1" class="popup-title font-bold py-3 ps-4">Edit Additional Preferences</span>
  <span *ngIf="preferrencesData.set === 2" class="popup-title font-bold py-3 ps-4">Edit Huski Details</span>
  <button class="close-icon me-2" mat-icon-button [disabled]="isLoading" (click)="closePopup()"><mat-icon>cancel</mat-icon></button>
</div>

<mat-divider></mat-divider>

<div class="popup-wr px-4 py-3">

    <div *ngFor="let questionItem of currentQuestionList" class="question-section mb-4"  [class.d-none]="(questionItem.order == '2') && !isFirstAnswerYes">
      <p class="mb-2">{{questionItem.question.question}}</p> 
      
      <!-- Radio Selection -->
      <section *ngIf="questionItem.question.quectionType == questionType.RadioButton">
        <mat-radio-group>
          <mat-radio-button 
            *ngFor="let answerItem of questionItem?.question?.answers | arrayOrder: 'order'" 
            [value]="answerItem"
            [checked]="answerItem?.id === questionItem?.answers[0]?.answerId"
            (change)="radioAnswerOnChange($event.value, questionItem.answers)"
            class="text-md me-3">
            {{answerItem.answer}}
          </mat-radio-button>
        </mat-radio-group>
      </section>

      <!-- Checkbox Selection -->
      <section *ngIf="questionItem.question.quectionType == questionType.CheckBox">
        <mat-checkbox 
          *ngFor="let answerItem of questionItem?.question?.answers | arrayOrder: 'order'; let i = index" 
          [value]="answerItem" 
          [disabled]="(questionItem.answers.length == 1) && (answerItem.id | setChecked : questionItem.answers)"
          [checked]="(answerItem.id | setChecked : questionItem.answers)"
          (change)="checkBoxAnswerOnChange($event.checked, $event.source.value, questionItem.answers)"
          class="me-3">
          {{answerItem.answer}}
        </mat-checkbox>
      </section>

      <!-- Texbox Input -->
      <section *ngIf="questionItem.question.quectionType == questionType.TextBox">
        <app-shared-preferences-text-input-widget 
          [questionItem]="questionItem" 
          (updateAnswerEvent)="onTextBoxValueChange($event)">
        </app-shared-preferences-text-input-widget>
      </section>
  </div>

  <!-- <div class="col-12 mb-4 text-start d-flex align-items-center justify-content-end">
    <button 
      class="btn primary w-mid"
      mat-flat-button 
      [disabled]="isLoading || !isCompleted" 
      (click)="closePopup()">Done
    </button>
    <mat-spinner *ngIf="isLoading" class="ms-2" diameter="30"></mat-spinner>
  </div> -->

</div>
