import { Pipe, PipeTransform } from '@angular/core';
import { ServiceCategory } from '../../onboarding/shared/models/service-category/service-category.model';

@Pipe({
  name: 'getCategoryName'
})
export class GetCategoryNamePipe implements PipeTransform {

  transform(catId: string, catList: ServiceCategory[] ): string {
    const serviceCategory = catList?.find(cat => cat.id === catId);
    return serviceCategory ? serviceCategory.name : '';
  }

}
