import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/core/auth/auth.service';
import { DocumentCategory, DocumentGroup, DocumentStatus } from 'src/app/core/enums/document.enum';
import { CustomDocumentModel, DocumentModel } from '../../../models/document.model';
import { AlertService } from '../../../services/alert/alert.service';
import { BusinessDocumentService } from '../../../services/documents/business-document.service';
import { UploadService } from '../../../services/upload/upload.service';
import { SharedBusinessDocumentUploadPopupComponent } from '../../popups/shared-business-document-upload-popup/shared-business-document-upload-popup.component';
import { SharedConfirmationPopupComponent } from '../../popups/shared-confirmation-popup/shared-confirmation-popup.component';

@Component({
  selector: 'app-shared-business-document-widget',
  templateUrl: './shared-business-document-widget.component.html',
  styleUrls: ['./shared-business-document-widget.component.scss']
})
export class SharedBusinessDocumentWidgetComponent implements OnInit {

  @Input() businessInfoId!: string
  @Input() hideStatus: boolean = false
  @Input() isOptional: boolean = false
  @Input() hasExpiry: boolean = true
  @Input() businessDocument!: DocumentModel
  @Input() currentDocumentCategory!: number
  @Output() removeDocumentEvent = new EventEmitter<any>();
  @Output() updateDocumentEvent = new EventEmitter<any>();
  @Output() updateDocumentStatusEvent = new EventEmitter<any>();
  
  businessDocumentData = new CustomDocumentModel()
  documentCategory = DocumentCategory
  documentGroup = DocumentGroup
  documentStatus = DocumentStatus
  isLoading = false
  currentUserId!: string

  constructor(
    private alertService: AlertService,
    private authService: AuthService,
    private uploadService: UploadService,
    public dialog: MatDialog,
    private businessDocumentService: BusinessDocumentService,
  ) { }

  ngOnInit(): void {
    this.currentUserId = this.authService.currentUserValue.userId
  }

  
  uploadBusinessDocument() {
    this.setCreateDocumentData() 
    const dialogRef = this.dialog.open( SharedBusinessDocumentUploadPopupComponent, {
      maxWidth: '640px',
      width: '50%',
      position : {top: '60px'},
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      closeOnNavigation: true,
      disableClose: true,
      data: {isUpdate: false, documentData: this.businessDocumentData },
    });

    dialogRef.afterClosed().subscribe( (data) => {
      if (data) {
        this.businessDocument = data
        this.updateDocumentEvent.emit(this.businessDocument)
      }
    });
  }

  updateBusinessDocument() {
    this.setCreateDocumentData() 
    const dialogRef = this.dialog.open( SharedBusinessDocumentUploadPopupComponent, {
      maxWidth: '640px',
      width: '50%',
      position : {top: '60px'},
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      closeOnNavigation: true,
      disableClose: true,
      data: {isUpdate: true, documentData: this.businessDocumentData},
    });

    dialogRef.afterClosed().subscribe( (data) => {
      if (data) {
        this.businessDocument = data
        this.updateDocumentEvent.emit(this.businessDocument)
      }
    });
  }

  removeDocumentConfirm() {
    const dialogRef = this.dialog.open( SharedConfirmationPopupComponent, {
      maxWidth: '500px',
      width: '90%',
      position: {
        top: '60px',
      },
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      closeOnNavigation: true,
      disableClose: true,
      data: {
        title: 'Please Confirm',
        message: 'Are you sure you want to delete this document?',
        confirmTxt: 'Yes, Delete',
        cancelTxt: 'Cancel',
      },
    });

    dialogRef.afterClosed().subscribe( success => {
      if (success) {
        const isArchived = true
        this.deleteBusinessDocument(this.businessDocument.id, isArchived)
      }
    });
  }

  private deleteBusinessDocument(documentId: string, isArchived: boolean) {
    this.isLoading = true;
    this.businessDocumentService.deleteBusinessDocument(documentId, isArchived).subscribe({
      next: (result: any) => {
        if (result.success) {
          this.alertService.success('Document deleted successfully!');
          this.removeDocumentEvent.emit(documentId)
          this.businessDocument = null!
        } else {
          this.alertService.error(result.error.message);
        }
        this.isLoading = false;
      }, error: err => {
        this.alertService.error('Operation failed!');
        this.isLoading = false;
      }
    });
  }

  // Download Document
  downloadDocument(key: string, name: string) {
    const documentUrl = this.uploadService.getUrlByS3KEY(key);
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', documentUrl);
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  setCreateDocumentData() {
    this.businessDocumentData.document = this.businessDocument
    this.businessDocumentData.documentName = null!
    this.businessDocumentData.documentCategory = this.currentDocumentCategory
    this.businessDocumentData.documentGroup = this.isOptional ? this.documentGroup.Additional : this.documentGroup.Mandatory
    this.businessDocumentData.hasExpiry = this.hasExpiry
    this.businessDocumentData.isBusinessDocument = true
    this.businessDocumentData.businessInfoId = this.businessInfoId
    this.businessDocumentData.isBusinessServiceDocument = false
    this.businessDocumentData.currentUserId = this.currentUserId
    this.businessDocumentData.fileLocation = `User/${this.currentUserId}/Documents/`
  }
}
