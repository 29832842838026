export enum StripeCard {
  Visa = 'visa',
  Master = 'mastercard',
  Other = 'other'
}

export enum CardTypes{
  Visa = 'Visa',
  Master = 'Master',
  Other = 'Other'
}