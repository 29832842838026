import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BusinessInfoModel } from 'src/app/modules/onboarding/shared/models/tradie.model';
import { TradieService } from 'src/app/modules/onboarding/shared/services/tradie.service';

@Component({
  selector: 'app-shared-abn-info-popup',
  templateUrl: './shared-abn-info-popup.component.html',
  styleUrls: ['./shared-abn-info-popup.component.scss']
})
export class SharedAbnInfoPopupComponent implements OnInit {

  hasData = false
  isLoading = false
  isAbnExists = false
  businessData!: BusinessInfoModel

  constructor(
    private tradieService: TradieService,
    public dialogRef: MatDialogRef<SharedAbnInfoPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public abn: string,
  ) { }

  ngOnInit(): void {
    this.checkIsExistsABN(this.abn)
  }

  checkIsExistsABN(abnNumber: string) {
    this.isLoading = true
    this.tradieService.checkIsExistsABN(abnNumber).subscribe({
      next: response => {
        if (response.success ) { // 
          if (response.data) {
            this.isAbnExists = true
            this.isLoading = false
          } else {
            this.getABNdetails(abnNumber)
          }
        } else { this.isLoading = false }
      },
      error: error => {
        this.isLoading = false
      }
    })
  }

  getABNdetails(abnNumber: string) {
    // this.isLoading = true
    this.tradieService.getAbnInfo(abnNumber).subscribe({
      next: response => {
        if (response.success) {
          this.hasData = true
          this.businessData = response.data
        } 
        this.isLoading = false
      },
      error: error => {
        this.isLoading = false
      }
    })
  }

  confirmABN() {
    this.dialogRef.close(this.businessData);
  }

}
