import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CodeInputComponent } from 'angular-code-input';
import { AlertService } from '../../../services/alert/alert.service';
import { VerificationService } from '../../../services/verification/verification.service';

@Component({
  selector: 'app-shared-verify-mobile-number-popup',
  templateUrl: './shared-verify-mobile-number-popup.component.html',
  styleUrls: ['./shared-verify-mobile-number-popup.component.scss']
})
export class SharedVerifyMobileNumberPopupComponent implements OnInit {

  currentCode!: any;
  isCodeInvalid = false;
  isCodeCompleted = false;
  isLoading = false;
  isVerificationSent = false;

  // adding to the page props
  @ViewChild('codeInput') codeInput !: CodeInputComponent;

  constructor(
    private alertService: AlertService,
    private verificationService: VerificationService,
    public dialogRef: MatDialogRef<SharedVerifyMobileNumberPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public selectedMobileNumber: string,
  ) { }

  ngOnInit(): void {}

  onCodeChanged(code: any) {}
  onCodeCompleted(code: any) {
    this.currentCode = code
    this.isCodeCompleted = true
    this.isCodeInvalid = false
  }

  resendCode(mobile: string) {
    this.resetCode()
    const mobileData = {
      mobileNumber: mobile,
    };
    this.sendVerificationCode(mobileData)
  }

  private sendVerificationCode(data: any) {
    this.isLoading = true
    this.verificationService.sendVerificationCode(data).subscribe({
      next: (res) => {
        if(res.success) {
          this.isVerificationSent = res.data
          this.alertService.success('Verification code sent successfully')
        } else {
          this.alertService.error(res.error.message);
        }
        this.isLoading = false
      }, error: (err) => {
        this.isLoading = false
        this.alertService.error('Operation failed')
      },
    });
  }

  onSubmit() {
    const verifyData = {
      mobileNumber: this.selectedMobileNumber,
      code: this.currentCode
    };
    this.verifyCode(verifyData)
  }

  private verifyCode(data: any) {
    this.isLoading = true
    this.verificationService.phoneVerification(data).subscribe({
      next: (res) => {
        if(res.success) {
          this.isCodeInvalid = false
          this.alertService.success('Mobile number verified successfully')
          this.dialogRef.close(true)
        } else {
          this.alertService.error('Please try again')
          this.resetCode()
        }
        this.isLoading = false
      },
      error: (err) => {
        this.alertService.error('Operation failed')
        this.isLoading = false
      },
    });
  }

  resetCode() {
    this.isCodeInvalid = true
    this.isCodeCompleted = false
    this.codeInput.reset();
  }

  cancel() {
    this.dialogRef.close(false)
  }
}
