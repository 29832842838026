import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VerificationService {

  private endpoint = environment.endpointURL + '/api/PhoneVerification';

  constructor( private http: HttpClient ) { }

  public sendVerificationCode(data: any): Observable<any> {
    const url = `${this.endpoint}/SendVerificationCode`;
    return this.http.post<any>(url, data);
  }
  
  public phoneVerification(data: any): Observable<any> {
    const url = `${this.endpoint}/VerifyCode`;
    return this.http.post<any>(url, data);
  }
}