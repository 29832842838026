<div class="popup p-5">
  <button class="close-icon" mat-icon-button>
    <mat-icon matDialogClose>cancel</mat-icon>
  </button>
  <h3 class="popup-header mb-0 text-uppercase text-center text-lg mb-2">Confirm Your ABN</h3>
  <p class="abn-number text-color primary font-semi-bold text-center">{{abn}}</p>

  <div *ngIf="hasData && !isAbnExists" class="info-wr">
    <div class="d-flex align-items-start info mb-2">
      <span class="label">Entity</span>
      <span class="me-2">:</span>
      <span class="data">{{businessData.entity ? businessData.entity : '--'}}</span>
    </div>
    
    <div class="d-flex align-items-center info mb-2">
      <span class="label">Entity Type</span>
      <span class="me-2">:</span>
      <span class="data">{{businessData.entityType ? businessData.entityType : '--'}}</span>
    </div>
  
    <div class="d-flex align-items-center info mb-2">
      <span class="label">ABN Status</span>
      <span class="me-2">:</span>
      <span class="data">{{businessData.status ? businessData.status :'--'}}</span>
    </div>
  
    <div class="d-flex align-items-center info mb-2">
      <span class="label">GST</span>
      <span class="me-2">:</span>
      <span class="data">{{businessData.gst ? businessData.gst : '--'}}</span>
    </div>
  
    <div class="d-flex align-items-center info">
      <span class="label">Main Business Location</span>
      <span class="me-2">:</span>
      <span class="data">{{businessData.location ? businessData.location : '--'}}</span>
    </div>
    
    <div class="d-flex align-items-center justify-content-center mt-5">
      <button class="btn primary text-uppercase" mat-flat-button [disabled]="isLoading" (click)="confirmABN()">Confirm</button>
    </div>
  </div>

  <div *ngIf="isAbnExists && !isLoading">
    <p class="m-0 text-center">Trade already exists for this ABN</p>
  </div>

  <div *ngIf="(!hasData && !isAbnExists) || isLoading" class="text-secondary text-center">
    <p *ngIf="!isLoading" class="m-0">ABN information not available for this number</p>
    <p *ngIf="isLoading" class="m-0 text-color primary d-flex align-items-center justify-content-center">
      <mat-spinner class="me-2" diameter="25" *ngIf="isLoading"></mat-spinner> 
      <span>Loading..</span>
    </p>
  </div>

</div>