import { Component, Input, OnChanges } from '@angular/core';
import { TradieService } from '../../../services/tradie/tradie.service';

@Component({
  selector: 'app-service-category-status-note-widget',
  templateUrl: './service-category-status-note-widget.component.html',
  styleUrls: ['./service-category-status-note-widget.component.scss']
})
export class ServiceCategoryStatusNoteWidgetComponent implements OnChanges {

  @Input() businessServiceId!: string;
  isLoading = false 
  statusList: any[] =[]

  constructor(private tradeService: TradieService) { }

  ngOnChanges(): void {
    this.getBusinessServiceStatus(this.businessServiceId)
  }

  private getBusinessServiceStatus(businessServiceId: string) {
    this.isLoading = true
    this.tradeService.getBusinessServiceStatus(businessServiceId).subscribe({
      next: response => {
        if (response.success) {
          this.statusList = response.data.businessServiceStatuses
        }
        this.isLoading = false
      }, error: error => {
        this.isLoading = false
      } 
    })
  }
}
