<section class="document-inner-wr">
  <div *ngIf="businessDocument" class="document d-flex align-items-center py-2 px-3 border">
      <img class="icon" src="/assets/icons/common/document.svg">
      <div class="ms-2">
          <div class="title mb-1 d-flex align-items-center">
              <span class="font-bold lh-1 me-2">{{businessDocument.name}}</span>
              <span *ngIf="!hideStatus">
                  <app-shared-document-status-widget [currentStatus]="businessDocument.status"></app-shared-document-status-widget>
              </span>
          </div>
          <div class="d-flex align-items-center">
              <!-- <span *ngIf="businessDocument.group === documentGroup.Essential" class="lbl-txt lh-1 me-2 pe-2 border-end">Essential</span>
              <span *ngIf="businessDocument.group === documentGroup.NonEssential" class="lbl-txt lh-1 me-2 pe-2 border-end">Non Essential</span> -->
              <span class="lbl-txt lh-1 me-2">Updated on {{businessDocument.lastUpdatedDate | date:"dd-MM-yyyy"}}</span>
              <span *ngIf="businessDocument?.expireDate" class="lbl-txt lh-1 me-2 border-start ps-2">Expires on {{businessDocument.expireDate | date:"dd-MM-yyyy"}}</span>
          </div>
      </div>
      <div class="actions d-flex align-items-center ms-auto">
          <span class="action-btn me-2">
              <button mat-icon-button (click)="downloadDocument(businessDocument.link, businessDocument.name)" class="ketab-btn download d-flex justify-content-center align-items-center">
                  <mat-icon>file_download</mat-icon>
              </button>
          </span>
          
          <span *ngIf="businessDocument.status !== documentStatus.Exempt && businessDocument.status !== documentStatus.Verified" >
          <span class="action-btn">
              <button mat-icon-button [matMenuTriggerFor]="menu" class="ketab-btn d-flex justify-content-center align-items-center">
                  <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="updateBusinessDocument()"><span>Update</span></button>
                  <button mat-menu-item (click)="removeDocumentConfirm()"><span>Remove</span></button>
              </mat-menu>
          </span>
        </span>
      </div>
  </div>

  <section *ngIf="!businessDocument" class="file-upload-wr d-flex align-items-center justify-content-center py-3 custom-border dashed" 
    (click)="uploadBusinessDocument()">
    <img class="upload-icon" src="/assets/icons/common/cloud-upload.svg">
    <span class="text-color primary font-semi-bold ms-2">Please upload</span>
  </section>

  <div *ngIf="isLoading" class="inner-preloader d-flex align-items-center justify-content-center">
      <mat-spinner diameter="35"></mat-spinner>
  </div>
</section>