import { Component, Input, OnInit } from '@angular/core';
import { JobStatusType } from 'src/app/core/enums/main.enum';
import { JobStatus } from '../../../models/my-jobs/my-jobs.model';

@Component({
  selector: 'app-shared-job-progress-widget',
  templateUrl: './shared-job-progress-widget.component.html',
  styleUrls: ['./shared-job-progress-widget.component.scss']
})
export class SharedJobProgressWidgetComponent implements OnInit {

  @Input() jobStatusList!: JobStatus[]
  jobStatusType = JobStatusType;

  isPosted = true
  isAccepted = false
  isInProgress = false
  isCompleted = false
  isInvoiceRecived = false
  isInvoiceIssued = false

  constructor() { }

  ngOnInit(): void {
    this.checkStatus(this.jobStatusList)
  }

  ngOnChanges(): void {
    this.checkStatus(this.jobStatusList)
  }

  checkStatus(statusList: JobStatus[]) {
    // this.isPosted = statusList.some( status => status.status === this.jobStatusType.Assigned)
    this.isAccepted = statusList.some( status => status.status === this.jobStatusType.Accepted)
    this.isInProgress = statusList.some( status => status.status === this.jobStatusType.Started)
    this.isCompleted = statusList.some( status => status.status === this.jobStatusType.Completed)
    this.isInvoiceRecived = statusList.some( status => status.status === this.jobStatusType.InvoiceApproved)
    this.isInvoiceIssued = statusList.some( status => status.status === this.jobStatusType.InvoicePaid)
  }
}
