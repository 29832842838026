import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { first } from 'rxjs';
import { AuthService } from 'src/app/core/auth/auth.service';
import { CardTypes, StripeCard } from 'src/app/core/enums/stripe-card.enum';
import { CardModel, CreateBusinessCreditCard, StripeCardData } from 'src/app/modules/onboarding/shared/models/payment/payment.model';
import { TradeProfile } from 'src/app/modules/onboarding/shared/models/profile.model';
import { PaymentService } from 'src/app/modules/onboarding/shared/services/payment/payment.service';
import { environment } from 'src/environments/environment';
import { FormatCardNumberPipe } from '../../../pipes/format-card-number.pipe';
import { AlertService } from '../../../services/alert/alert.service';

declare var Stripe: any;

@Component({
  selector: 'app-shared-add-credit-card-popup',
  templateUrl: './shared-add-credit-card-popup.component.html',
  styleUrls: ['./shared-add-credit-card-popup.component.scss']
})
export class SharedAddCreditCardPopupComponent implements OnInit {

  // @Input() profileData!: TradeProfile;
  @Output() onboardingCompleteEvent = new EventEmitter<boolean>();
  
  isLoading = false
  isValid = false
  submitted = false;
  currentUserId!: string

  formPayment!: FormGroup;

  cardTypes = CardTypes;
  stripCardTypes = StripeCard;

  // Stripe
  currentCardData = new CardModel();
  createBusinessCardData =  new CreateBusinessCreditCard()

  isHide = true;
  isDefault = false;
  isCardComplete = false;
  stripeInentId!: string;
  currentPaymentMethodId!: string;

  cardError: string = '';
  checked: boolean = false;
  lastDigits!: string;

  datePipe = new DatePipe('en-US');
  creditCardNumberPipe = new FormatCardNumberPipe();

  constructor(
    private fb: FormBuilder,
    private paymentService: PaymentService,
    private alertService: AlertService,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public businessData: { isAdd: boolean, id: string, data: any, profileData: TradeProfile},
    public dialogRef: MatDialogRef<any>,
  ) { }

  ngOnInit(): void {
    this.currentUserId = this.authService.currentUserValue.userId;
    this.getIntent();
    this.getBusinessCreditCard()

    this.formPayment = this.fb.group({
      name: ['', [Validators.required]],
      number: ['', [Validators.required, Validators.pattern('^[0-9]{12,16}$') ]],
      expiryDate: ['', [Validators.required, Validators.pattern('(?:0[1-9]|1[0-2])/[0-9]{2}')]],
      cvv: ['', [Validators.required, Validators.pattern('^[0-9]{3,4}$') ]],
    });

    this.formPayment.controls['name'].statusChanges.subscribe( status => {
      this.isValid = (status === 'VALID')
    })
  }

  // convenience getter for easy access to form fields 
  get f() { return this.formPayment.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.formPayment.invalid) { // stop here if invalid
      return;
    }
  }

  private getIntent() {
    this.isLoading = true;
    this.paymentService.setupIntentByBusinessInfoId(this.businessData.id).pipe(first()).subscribe({
        next: (data: any) => {
          if (data.success) {
            this.stripeInentId = data.data;
            this.isHide = false;
            this.ceateStripeCard();
          } else {
            this.alertService.error(data.error.message);
          }
          this.isLoading = false;
        }, error : err => {
          this.isLoading = false;
        }
      });
  }

  private getBusinessCreditCard() {
    this.isLoading = true;
    this.paymentService.getByBusinessInfoId(this.currentUserId).subscribe({
        next: (data: any) => {
          if (data.success) {
            
          } else {
            this.alertService.error(data.error.message);
          }
          this.isLoading = false;
        }, error : err => {
          this.isLoading = false;
        }
      });
  }

  ceateStripeCard() {
    const stripe = Stripe(environment.stripe.key);
    const elements = stripe.elements();

    const card = elements.create('card', {
      hidePostalCode: true,
      hideIcon: false,
      style: {
        base: {
          iconColor: ' #00A6BC',
          color: 'rgba(0,0,0,.87)',
          lineHeight: '48px',
          fontWeight: 400,
          fontFamily:
            '"source-sans-pro-regular", "arial", "Helvetica", sans-serif',
          fontSize: '15px',

          '::placeholder': {
            color: 'rgba(0,0,0,.87)',
          },
        },
      },
    });

    card.mount('#card-element');
    card.addEventListener('change', (event: any) => {
      
      const displayError = document.getElementById('card-errors');
      if (event.error) {
        this.isCardComplete = false;
        this.cardError = event.error.message;
      } else {
        this.cardError = '';
        if (event.complete) {
          this.isCardComplete = true;
        } else {
          this.isCardComplete = false;
        }
      }
    });

    const paymentForm = document.getElementById('payment-form')!;
    paymentForm.addEventListener('submit', (event) => {
      
    if (this.isCardComplete) {
      this.isLoading = true;
    }

    event.preventDefault();
    stripe.confirmCardSetup( this.stripeInentId, {
        payment_method: {
          card: card,
          billing_details: {
            name: this.formPayment.value.name,
          },
        },
      })
      .then((result: any) => {
        if (result.setupIntent) {
          this.currentPaymentMethodId = result.setupIntent.payment_method;

          //TO Check
          stripe.createPaymentMethod({
              type: 'card',
              card: card,
              billing_details: {
                name: this.formPayment.value.name,
              },
            }).then((result: any) => {
              this.isLoading = false;
              this.setCurrentCardData(result.paymentMethod.card)
              this.createBusinessCard();
            });
        } else {
          this.isLoading = false;
          this.alertService.error(result.error.message)
        }
      });
    });
  }

  setCurrentCardData(stripeCardData: StripeCardData) {
    this.currentCardData.brand = this.getSelectedBrand(stripeCardData.brand)
    this.currentCardData.userId = this.currentUserId;
    this.currentCardData.cardHolderName = this.formPayment.value.name;
    this.currentCardData.expireDate = this.setSelectedExpireData(stripeCardData.exp_year, stripeCardData.exp_month)
    this.currentCardData.lastDigits = Number(stripeCardData.last4);
    this.currentCardData.isVerified = true; // TODO Need to get from Strip
    this.currentCardData.stripeCardId = this.currentPaymentMethodId;
  }

  getSelectedBrand(brand: string): string {
    let selectedCardType
    switch (brand) {
      case this.stripCardTypes.Visa:
        selectedCardType = this.cardTypes.Visa;
        break;

      case this.stripCardTypes.Master:
        selectedCardType = this.cardTypes.Visa;
        break;
    
      default:
        selectedCardType = this.cardTypes.Other;
        break;
    }
    return selectedCardType
  }

  setSelectedExpireData(year: number, month: number): string {
    let date = new Date(year, month + 1, 0);
    let lastDate = new Date(date.getFullYear(), date.getMonth() , 0);
    let lastDay = lastDate.getDate()
    let expireDate = year+'-'+month+'-'+lastDay
    return expireDate
  }

  private createBusinessCard() {
    this.setCardCreateData()
    this.paymentService.createBusinessCreaditCard(this.createBusinessCardData).subscribe({
        next: (data: any) => {
          if (data.success) {
            this.dialogRef.close(data.data)
            this.alertService.success('Your business credit card saved successfully');
          } else {
            this.alertService.error(data.error.message);
          }
          this.isLoading = false;
        },
        error: (error) => {
          this.isLoading = false;
        }
      });
  }

  setCardCreateData() {
    this.createBusinessCardData.userId = this.currentCardData.userId;
    this.createBusinessCardData.businessInfoId = this.businessData.profileData.team.businessInfoId;
    this.createBusinessCardData.cardHolderName = this.formPayment.value.name;
    this.createBusinessCardData.stripeCardId = this.currentCardData.stripeCardId; // Get By User Id
    // this.createBusinessCardData.expireDate = this.datePipe.transform(this.currentCardData.expireDate)!;
    this.createBusinessCardData.expireDate = this.currentCardData.expireDate;
    this.createBusinessCardData.lastDigits = this.currentCardData.lastDigits;
    this.createBusinessCardData.brand = this.currentCardData.brand;
    this.createBusinessCardData.isVerified = this.currentCardData.isVerified;
  }
}
